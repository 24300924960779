import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { DuelText, RoundAvatar } from "..";
import { Anchor } from "../elements";
import Axios from 'axios';
import { useNavigate } from "react-router-dom";
import APIClient from "../../APIClient";
import { ToastContainer, toast } from "react-toastify";

export default function ProfileDropdown({ name, username, image, dropdown }) {
    const navigate = useNavigate();
    const RoleTypes = {
        USER: "1",
        Councellor: "2",
        Manager: "3",
        VENDOR: "4",
    };

    const [redirectTo, setRedirectTo] = useState(null);
    const Logout = async () => {

        APIClient.get('sanctum/csrf-cookie').then(response => {
            APIClient.post('api/logout',{},{
                headers: {
                    "Authorization": "Bearer "+ localStorage.getItem('token') 
                }
            }).then(logoutresponse => {
                toast.success(logoutresponse.data.data);
                let newRoleTypes = localStorage.getItem('role_type');
                localStorage.clear();
                setTimeout(() => {
                    if (newRoleTypes == RoleTypes.USER) {
                        setRedirectTo('/login');
                    } 
                    
                    if (newRoleTypes == RoleTypes.VENDOR) {
                        setRedirectTo('/vendor/');
                    }

                    if (newRoleTypes == RoleTypes.Councellor) {
                        setRedirectTo('/counsellor/');
                    }
                    if (newRoleTypes == RoleTypes.Manager) {
                        setRedirectTo('/manager/');
                    }
                }, 2000);
            });
        });
    }

    useEffect(() => {
        if (redirectTo) {
            navigate(redirectTo);
        }
    }, [redirectTo, navigate]);

    return (
        <>
            <ToastContainer autoClose={1000} />
            <Dropdown className="mc-header-user">
                <Dropdown.Toggle className="mc-dropdown-toggle">
                    <RoundAvatar src={image} alt="avatar" size="xs" />
                    <DuelText title={name} descrip={username} size="xs" />
                </Dropdown.Toggle>
                <Dropdown.Menu align="end" className="mc-dropdown-paper">
                    {dropdown.map((item, index) => (
                        <Anchor
                            key={index}
                            icon={item.icon}
                            // href={item.path}
                            text={item.text}
                            className="mc-dropdown-menu"
                            onClick={item.text === "logout" ? Logout : null}
                        />
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}