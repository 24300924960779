import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Box } from "../../components/elements";
import { Link, useLocation, useParams } from "react-router-dom";
import CounsellorPageLayout from "../../layouts/CousellorPageLayout";
import APIClient from "../../APIClient";

function CounsellorNotification() {
  const { id } = useParams();
  const [profileleaddata, setProfileLeadData] = useState([]);
  const fetchNotification = async () => {
    try {
      await APIClient.get("sanctum/csrf-cookie");
      const response = await APIClient.post(
        `api/duplicate-notification`,
        { counseller_id: id },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response) {
        setProfileLeadData([...response?.data?.duplicate_list]);
        console.log("response", profileleaddata);
      }
      console.log("result", response.data);
    } catch (error) {
      console.error("Error fetching admin dashbord data", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchNotification();
    }
  }, [id]);
  return (
    <CounsellorPageLayout>
      <Row>
        <Col xl="12" className="text-center py-5">
          <h1>Notifications</h1>
        </Col>
      </Row>
      <Row>
        {profileleaddata?.map((item, index) => (
          <Col xl={12} className="mb-3" key={index + 1}>
            <Box className="mc-card">
              <div className="container-fluid">
                <div className="row d-flex align-items-center">
                  <div className="col-11">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">Sr. No</th>
                          <th scope="col"> Message</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">{index + 1}</th>
                          <td>{`Lead Id - ${item.lead_profile_id} , Profile Email - ${item.profile_email}, Profile Phone - ${item.profile_mobile} some one trying to add`}</td>
                          {/* <td>{item.profile_email}</td>
                            <td>{item.profile_mobile}</td>
                            <td>{item.profile_experience}</td>
                            <td>{item.profile_city}</td>
                            <td>{item.profile_state}</td>
                            <td>{item.profile_messge}</td> */}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-1">
                    <Link
                      type="button"
                      className="common-btn"
                      id="common-btn"
                      variant="primary"
                      to={`/counsellor/new-enquiryedit/${item.lead_profile_id}`}
                    >
                      Take Action
                    </Link>
                  </div>
                </div>
              </div>
            </Box>
          </Col>
        ))}
      </Row>
    </CounsellorPageLayout>
  );
}

export default CounsellorNotification;
