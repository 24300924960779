import React, { useContext, useEffect, useState } from "react";
import APIClient from "../../../APIClient";
import { useNavigate, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { Form } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { AddnewEnquirySchema } from "../../schemas/AddnewEnquirySchema";
import { LoaderContext } from "../../../context/Preloader";

function Profiletabcounsellor({
  activekey,
  coursedata,
  Specializationdata,
  initialValues,
  universityList,
}) {
  const { setLoading } = useContext(LoaderContext);
  const { Id } = useParams();
  const navigate = useNavigate();
  // const [coursedata, setCoursedata] = useState();
  // const [Specializationdata, setSpecializationdata] = useState([]);
  // const [calltypedata, setCalltypedata] = useState([]);
  // const [universityList, setUniversityList] = useState([]);

 

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      enableReinitialize: true,
      validationSchema: AddnewEnquirySchema,
      onSubmit: (values, { setSubmitting }) => {
        setLoading(true);
        APIClient.get("sanctum/csrf-cookie")
          .then((response) => {
            return APIClient.post("api/update-lead-profile", values, {
              headers: {
                "Content-Type": "multipart/form-data",
                Accept: "multipart/form-data",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            });
          })
          .then((updateleadprofile) => {
            activekey("Call Status");
            setTimeout(() => {
              toast.success(updateleadprofile.data.msg);
            }, 2000);
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            console.error("Error Update lead profile:", error);
          })
          .finally(() => {
            setSubmitting(false);
          });
      },
    });

  // useEffect(() => {
  //   if (Id) {
  //     updatesinglevalu();
  //   }
  // }, [Id]);
  return (
    <>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Form.Group as={Row} className="mb-3 mt-3">
          <Form.Label
            column
            sm="1"
            className="d-flex align-items-center justify-content-center"
          >
            Name
          </Form.Label>
          <Col sm="3">
            <Form.Control
              type="text"
              name="name"
              placeholder="Enter Name"
              onChange={handleChange}
              value={values?.name}
              onBlur={handleBlur}
            />

            {errors.name && touched.name ? (
              <p className="text-danger" style={{ fontSize: "13px" }}>
                {errors.name}
              </p>
            ) : null}
          </Col>
          <Form.Label
            column
            sm="1"
            className="d-flex align-items-center justify-content-center"
          >
            Age
          </Form.Label>
          <Col sm="3">
            <Form.Control
              type="number"
              placeholder="Enter Age"
              name="age"
              onChange={handleChange}
              value={values?.age}
              onBlur={handleBlur}
            />
            {errors.age && touched.age ? (
              <p className="text-danger" style={{ fontSize: "13px" }}>
                {errors.age}
              </p>
            ) : null}
          </Col>
          <Form.Label
            column
            sm="1"
            className="d-flex align-items-center justify-content-center"
          >
            DOB
          </Form.Label>
          <Col sm="3">
            <Form.Control
              type="date"
              placeholder="Select Date"
              name="dob"
              onChange={handleChange}
              value={values?.dob}
              onBlur={handleBlur}
            />
            {errors.dob && touched.dob ? (
              <p className="text-danger" style={{ fontSize: "13px" }}>
                {errors.dob}
              </p>
            ) : null}
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3 mt-3">
          <Form.Label
            column
            sm="1"
            className="d-flex align-items-center justify-content-center"
          >
            Country
          </Form.Label>
          <Col sm="3">
            <Form.Select
              aria-label="Default select example"
              name="country"
              onChange={handleChange}
              value={values?.country}
              onBlur={handleBlur}
            >
              <option>Select Country</option>
              <option value="India">India</option>
              <option value="US">US</option>
              <option value="UK">UK</option>
            </Form.Select>
            {errors.country && touched.country ? (
              <p className="text-danger" style={{ fontSize: "13px" }}>
                {errors.country}
              </p>
            ) : null}
          </Col>
          <Form.Label
            column
            sm="1"
            className="d-flex align-items-center justify-content-center"
          >
            City
          </Form.Label>
          <Col sm="3">
            <Form.Control
              type="text"
              placeholder="Enter City"
              name="city"
              onChange={handleChange}
              value={values?.city}
              onBlur={handleBlur}
            />
            {errors.city && touched.city ? (
              <p className="text-danger" style={{ fontSize: "13px" }}>
                {errors.city}
              </p>
            ) : null}
          </Col>
          <Form.Label
            column
            sm="1"
            className="d-flex align-items-center justify-content-center"
          >
            State
          </Form.Label>
          <Col sm="3">
            <Form.Control
              type="text"
              placeholder="Enter State"
              name="state"
              onChange={handleChange}
              value={values?.state}
              onBlur={handleBlur}
            />
            {errors.state && touched.state ? (
              <p className="text-danger" style={{ fontSize: "13px" }}>
                {errors.state}
              </p>
            ) : null}
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3 mt-3">
          <Form.Label
            column
            sm="1"
            className="d-flex align-items-center justify-content-center"
          >
            Course
          </Form.Label>
          <Col sm="3">
            <Form.Select
              aria-label="Default select example"
              name="course_id"
              onChange={handleChange}
              value={values?.course_id}
              onBlur={handleBlur}
            >
              <option>Select Course</option>
              {coursedata &&
                coursedata.data.course_list.map((item, index) => (
                  <option key={item.id} value={item.id}>
                    {item.course_name}
                  </option>
                ))}
            </Form.Select>
            {errors.course_id && touched.course_id ? (
              <p className="text-danger" style={{ fontSize: "13px" }}>
                {errors.course_id}
              </p>
            ) : null}
          </Col>
          <Form.Label
            column
            sm="1"
            className="d-flex align-items-center justify-content-center"
          >
            Specialization
          </Form.Label>
          <Col sm="3">
            <Form.Select
              aria-label="Default select example"
              name="specialization_id"
              onChange={handleChange}
              value={values?.specialization_id}
              onBlur={handleBlur}
            >
              <option>Select Specialization</option>
              {Specializationdata?.map((item, index) => (
                <option key={item.id} value={item.id}>
                  {item.specialization_name}
                </option>
              ))}
            </Form.Select>
            {errors.specialization_id && touched.specialization_id ? (
              <p className="text-danger" style={{ fontSize: "13px" }}>
                {errors.specialization_id}
              </p>
            ) : null}
          </Col>
          <Form.Label
            column
            sm="1"
            className="d-flex align-items-center justify-content-center"
          >
            University
          </Form.Label>
          <Col sm="3">
            <Form.Select
              aria-label="Default select example"
              name="univercity_name"
              onChange={handleChange}
              value={values?.univercity_name}
              onBlur={handleBlur}
            >
              <option>Select University</option>
              {universityList &&
                universityList.map((item, index) => (
                  <option key={item.id} value={item.id}>
                    {item.univercity_name}
                  </option>
                ))}
            </Form.Select>
            {errors.univercity_name && touched.univercity_name ? (
              <p className="text-danger" style={{ fontSize: "13px" }}>
                {errors.univercity_name}
              </p>
            ) : null}
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3 mt-3">
          {/* <Form.Label
            column
            sm="1"
            className="d-flex align-items-center justify-content-center"
          >
            Call Type
          </Form.Label>
          <Col sm="3">
            <Form.Select
              aria-label="Default select example"
              name="call_type_id"
              onChange={handleChange}
              value={values?.call_type_id}
              onBlur={handleBlur}
            >
              <option>Select Call Type</option>
              {calltypedata.map((item, index) => (
                <option key={item.id} value={item.id}>
                  {item.calltype_name}
                </option>
              ))}
            </Form.Select>
            {errors.call_type_id && touched.call_type_id ? (
              <p className="text-danger" style={{ fontSize: "13px" }}>
                {errors.call_type_id}
              </p>
            ) : null}
          </Col> */}
          <Form.Label
            column
            sm="1"
            className="d-flex align-items-center justify-content-center"
          >
            Company
          </Form.Label>
          <Col sm="3">
            <Form.Control
              type="text"
              placeholder="Enter Company"
              name="company"
              onChange={handleChange}
              value={values?.company}
              onBlur={handleBlur}
            />
            {errors.company && touched.company ? (
              <p className="text-danger" style={{ fontSize: "13px" }}>
                {errors.company}
              </p>
            ) : null}
          </Col>
          <Form.Label
            column
            sm="1"
            className="d-flex align-items-center justify-content-center"
          >
            Designation
          </Form.Label>
          <Col sm="3">
            <Form.Control
              type="text"
              placeholder="Enter Designation"
              name="designation"
              onChange={handleChange}
              value={values?.designation}
              onBlur={handleBlur}
            />
            {errors.designation && touched.designation ? (
              <p className="text-danger" style={{ fontSize: "13px" }}>
                {errors.designation}
              </p>
            ) : null}
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3 mt-3">
          <Form.Label
            column
            sm="1"
            className="d-flex align-items-center justify-content-center"
          >
            Experience
          </Form.Label>
          <Col sm="3">
            <Form.Control
              type="number"
              placeholder="Enter Your Experience"
              name="experience"
              onChange={handleChange}
              value={values?.experience}
              onBlur={handleBlur}
            />
            {errors.experience && touched.experience ? (
              <p className="text-danger" style={{ fontSize: "13px" }}>
                {errors.experience}
              </p>
            ) : null}
          </Col>
          <Form.Label
            column
            sm="1"
            className="d-flex align-items-center justify-content-center"
          >
            Nationality
          </Form.Label>
          <Col sm="3">
            <Form.Select
              aria-label="Default select example"
              name="nationality"
              onChange={handleChange}
              value={values?.nationality}
              onBlur={handleBlur}
            >
              <option>Select Your Nationality</option>
              <option value="India">India</option>
              <option value="US">US</option>
              <option value="UK">UK</option>
            </Form.Select>
            {errors.nationality && touched.nationality ? (
              <p className="text-danger" style={{ fontSize: "13px" }}>
                {errors.nationality}
              </p>
            ) : null}
          </Col>
          <Form.Label
            column
            sm="1"
            className="d-flex align-items-center justify-content-center"
          >
            Mobile
          </Form.Label>
          <Col sm="3">
            <Form.Control
              type="number"
              placeholder="Enter Mobile Number"
              name="mobile"
              onChange={handleChange}
              value={values?.mobile}
              onBlur={handleBlur}
            />
            {errors.mobile && touched.mobile ? (
              <p className="text-danger" style={{ fontSize: "13px" }}>
                {errors.mobile}
              </p>
            ) : null}
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3 mt-3">
          <Form.Label
            column
            sm="1"
            className="d-flex align-items-center justify-content-center"
          >
            ALternate Mobile
          </Form.Label>
          <Col sm="3">
            <Form.Control
              type="number"
              placeholder="Enter Alternative Number"
              name="alt_mobile"
              onChange={handleChange}
              value={values?.alt_mobile}
              onBlur={handleBlur}
            />
            {errors.alt_mobile && touched.alt_mobile ? (
              <p className="text-danger" style={{ fontSize: "13px" }}>
                {errors.alt_mobile}
              </p>
            ) : null}
          </Col>
          <Form.Label
            column
            sm="1"
            className="d-flex align-items-center justify-content-center"
          >
            Email
          </Form.Label>
          <Col sm="3">
            <Form.Control
              type="email"
              placeholder="example@gmail.com"
              name="email"
              onChange={handleChange}
              value={values?.email}
              onBlur={handleBlur}
            />
            {errors.email && touched.email ? (
              <p className="text-danger" style={{ fontSize: "13px" }}>
                {errors.email}
              </p>
            ) : null}
          </Col>
          <Form.Label
            column
            sm="1"
            className="d-flex align-items-center justify-content-center"
          >
            ALternate Email
          </Form.Label>
          <Col sm="3">
            <Form.Control
              type="email"
              placeholder="example@gmail.com"
              name="alt_email"
              onChange={handleChange}
              value={values?.alt_email}
              onBlur={handleBlur}
            />
            {errors.alt_email && touched.alt_email ? (
              <p className="text-danger" style={{ fontSize: "13px" }}>
                {errors.alt_email}
              </p>
            ) : null}
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3 mt-3">
          <Form.Label
            column
            sm="1"
            className="d-flex align-items-center justify-content-center"
          >
            Message
          </Form.Label>
          <Col sm="7">
            <Form.Control
              as="textarea"
              rows={2}
              onChange={handleChange}
              name="message"
              value={values?.message}
              onBlur={handleBlur}
            />
            {errors.message && touched.message ? (
              <p className="text-danger" style={{ fontSize: "13px" }}>
                {errors.message}
              </p>
            ) : null}
          </Col>
        </Form.Group>
        <Button className="common-btn" variant="primary" type="submit">
          Save
        </Button>
      </Form>
    </>
  );
}

export default Profiletabcounsellor;
