import React, { useContext, useEffect, useState } from "react";
import APIClient from "../../APIClient";
import { Row, Col } from "react-bootstrap";
import { Box } from "../../components/elements";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ManagerPageLayout from "../../layouts/ManagerPageLayout";
import { ToastContainer, toast } from "react-toastify";
import { Form } from "react-bootstrap";
import { LoaderContext } from "../../context/Preloader";

function LeadProfileList() {
  const token = localStorage.getItem("token");

  const navigate = useNavigate();
  const { setLoading } = useContext(LoaderContext);
  const [leadProfilelist, setLeadProfilelist] = useState();
  const memberId = localStorage.getItem("user_id");
  const [counsellordata, setCounsellordata] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [profileIds, setProfileIds] = useState([]);
  const [selectall, setSelectall] = useState(false);
  const [counsellorId, setCounsellorId] = useState("");
  const [vendordata, setVendordata] = useState();
  const [leadStatus, setLeadStatus] = useState([]);
  const [leadProtential, setLeadProtential] = useState([]);
  const [enquiryStatus, setEnquiryStatus] = useState([]);
  const [callinfodata, setCallinfodata] = useState([]);
  const [calltypedata, setCalltypedata] = useState([]);

  const [counsellor, setCounsellor] = useState("");
  const [vendor, setVendor] = useState("");
  const [leadStatusId, setLeadStatusId] = useState("");
  const [followUp, setFollowup] = useState("");
  const [leadPotentialName, setleadPotentialName] = useState("");
  const [enquiryStatusName, setenquiryStatusName] = useState("");
  const [callInfoName, setcallInfoName] = useState("");
  const [callTypeId, setCallTypeId] = useState("");

  console.log(profileIds);
  console.log(selectall);
  console.log(counsellorId);

  const handleSelectAll = (e) => {
    const checked = e.target.checked;
    setSelectall(checked);

    if (checked) {
      const allIds = leadProfilelist.map((item) => item.id);
      setProfileIds(allIds);
    } else {
      setProfileIds([]);
    }
  };

  const handleCheckboxChange = (e, itemId) => {
    const checked = e.target.checked;
    if (checked) {
      setProfileIds([...profileIds, itemId]);
    } else {
      setProfileIds(profileIds.filter((id) => id !== itemId));
    }
  };
  const fetchCallTypedata = async () => {
    APIClient.get("sanctum/csrf-cookie");
    APIClient.get("api/calltype-list", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        const data = res.data.calltype_list;
        setCalltypedata(data);
      })
      .catch((err) => console.log(err));
  };
  // fetch vendor List
  const fetchvendordata = async () => {
    APIClient.get("sanctum/csrf-cookie").then((response) => {
      APIClient.get("api/leadvendor-list", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((getvendorResponse) => {
        setVendordata(getvendorResponse);
      });
    });
  };

  const handelsubmit = async () => {
    setLoading(true);
    APIClient.get("sanctum/csrf-cookie").then((response) => {
      APIClient.post(
        "api/update-to-super-cold",
        {
          counsellor_id: counsellorId,
          comasep_profile_ids: profileIds.join(","),
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
        .then((getcouncellorResponse) => {
          handleClose();
          setLoading(false);
          setTimeout(() => {
            toast.success(getcouncellorResponse.data.msg);
          }, 2000);
        })
        .catch((error) => {
          setLoading(false);
          toast.error("Something Is Wrong !");
        });
    });
  };

  const fetchcounsellordata = async () => {
    APIClient.get("sanctum/csrf-cookie").then((response) => {
      APIClient.post(
        "api/manager-groupwise-counsellor-list",
        { manager_id: memberId },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      ).then((getcouncellorResponse) => {
        setCounsellordata(getcouncellorResponse.data?.counsellor_list);
      });
    });
  };

  const fetchleadprofilelist = () => {
    if (token) {
      APIClient.get("sanctum/csrf-cookie")
        .then(() => {
          return APIClient.get(
            `api/managerwise-list-lead-profile/${memberId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        })
        .then((res) => {
          const data = res.data;
          setLeadProfilelist(data.profile_list);
        });
    }
  };
  const filterProfileList = () => {
    APIClient.get("sanctum/csrf-cookie")
      .then(() => {
        return APIClient.post(
          `api/filter-manager-wise-profile`,
          {
            vendor_id: vendor,
            counsellor_id: counsellor,
            follow_up_date: followUp,
            lead_potential_name: leadPotentialName,
            call_type_id: callTypeId,
            call_information_name: callInfoName,
            enquiry_status_name: enquiryStatusName,
            lead_status_id: leadStatusId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      })
      .then((res) => {
        const data = res.data;
        setLeadProfilelist(data.profile_list);
      });
  };
  useEffect(() => {
    if (
      counsellor !== "" ||
      vendor !== "" ||
      leadStatusId !== "" ||
      followUp !== "" ||
      leadPotentialName !== "" ||
      enquiryStatusName !== "" ||
      callTypeId !== "" ||
      callInfoName !== ""
    ) {
      filterProfileList();
    }
  }, [
    counsellor,
    vendor,
    leadStatusId,
    followUp,
    leadPotentialName,
    enquiryStatusName,
    callInfoName,
    callTypeId,
  ]);

  const fetchLeadStatus = () => {
    const token = localStorage.getItem("token");
    if (token) {
      APIClient.get("sanctum/csrf-cookie")
        .then(() => {
          return APIClient.get("api/leadstatus-list", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        })
        .then((res) => {
          const data = res.data.leadstatus_list;
          setLeadStatus(data);
        })
        .catch((err) => {
          console.error(err);
          if (err.response && err.response.status === 401) {
            console.log("Token might be invalid or expired");
          }
        });
    } else {
      console.log("No token found");
    }
  };

  //   fetch Lead Protential
  const fetchLeadProtential = async () => {
    APIClient.get("sanctum/csrf-cookie");
    APIClient.get("api/leadpotential-list", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        const data = res.data.lead_potential_list;
        setLeadProtential(data);
      })
      .catch((err) => console.log(err));
  };

  // fetch enquiry status
  const fetchEnquiryStatus = async () => {
    APIClient.get("sanctum/csrf-cookie");
    APIClient.get("api/enquerytatus-list", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        const data = res.data.enquerystatus_list;
        setEnquiryStatus(data);
      })
      .catch((err) => console.log(err));
  };
  //   fetch call information
  const fetchCallinfodata = async () => {
    APIClient.get("sanctum/csrf-cookie");
    APIClient.get("api/callinformation-list", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        const data = res.data.callinformation_list;
        setCallinfodata(data);
        console.log(callinfodata);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    fetchcounsellordata();
    fetchvendordata();
    fetchLeadStatus();
    fetchCallTypedata();
    fetchCallinfodata();
    fetchEnquiryStatus();
    fetchLeadProtential();
  }, []);
  useEffect(() => {
    if (
      counsellor === "" &&
      vendor === "" &&
      leadStatusId === "" &&
      callTypeId === "" &&
      callInfoName === "" &&
      enquiryStatusName === "" &&
      leadPotentialName === "" &&
      followUp === ""
    ) {
      fetchleadprofilelist();
    }
  }, [
    counsellor,
    vendor,
    leadStatusId,
    followUp,
    leadPotentialName,
    enquiryStatusName,
    callInfoName,
    callTypeId,
  ]);
  return (
    <>
      <ToastContainer autoClose={1000} />
      <ManagerPageLayout>
        <Row>
          <Col xl="12" className="text-center py-5">
            <h1>Lead Profile List</h1>
          </Col>
        </Row>
        <Form onSubmit={filterProfileList}>
          <Form.Group as={Row}>
            <Form.Label column sm="1" className="d-flex align-items-center">
              Counsellor
            </Form.Label>
            <Col sm="2" style={{ width: "22%" }}>
              <Form.Select
                aria-label="Default select example"
                style={{ fontSize: "11px" }}
                value={counsellor}
                onChange={(e) => {
                  setCounsellor(e.target.value);
                  setCounsellorId(e.target.value);
                  console.log(counsellor);
                }}
              >
                <option value="">{"<-- Select Counsellor -->"}</option>
                {counsellordata &&
                  counsellordata.map((item, index) => (
                    <option key={item.id} value={item.id}>
                      {item.councellor_name}
                    </option>
                  ))}
              </Form.Select>
            </Col>

            <Form.Label column sm="1" className="d-flex align-items-center">
              Lead Vendor
            </Form.Label>
            <Col sm="2" style={{ width: "20%" }}>
              <Form.Select
                aria-label="Default select example"
                style={{ fontSize: "11px" }}
                value={vendor}
                onChange={(e) => {
                  setVendor(e.target.value);
                }}
              >
                <option value="">{"<-- Select Vendor -->"}</option>
                {vendordata &&
                  vendordata.data.leadvendor_list.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Form.Select>
            </Col>

            <Form.Label column sm="1" className="d-flex align-items-center">
              Lead Status
            </Form.Label>
            <Col sm="2" style={{ width: "20%" }}>
              <Form.Select
                aria-label="Default select example"
                style={{ fontSize: "11px" }}
                value={leadStatusId}
                onChange={(e) => {
                  setLeadStatusId(e.target.value);
                }}
              >
                <option value="">{"<-- Select Lead Status -->"}</option>
                {leadStatus &&
                  leadStatus.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.lead_status_name}
                    </option>
                  ))}
              </Form.Select>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mt-2">
            <Form.Label column sm="1" className="d-flex align-items-center">
              Follow Up
            </Form.Label>
            <Col
              style={{ width: "20%" }}
              md="12"
              className="m-0 p-2  text-end d-flex justify-align-center align-items-center"
            >
              <input
                className="form-control"
                name="followupdate"
                type="date"
                onChange={(e) => setFollowup(e.target.value)}
              />
            </Col>

            <Form.Label column sm="1" className="d-flex align-items-center">
              Lead Potential
            </Form.Label>
            <Col sm="2" style={{ width: "20%" }}>
              <Form.Select
                aria-label="Default select example"
                style={{ fontSize: "11px" }}
                value={leadPotentialName}
                onChange={(e) => {
                  setleadPotentialName(e.target.value);
                }}
              >
                <option value="">{"<-- Select Lead Potential -->"}</option>
                {leadProtential &&
                  leadProtential.map((item, index) => (
                    <option key={index} value={item.lead_potential_name}>
                      {item.lead_potential_name}
                    </option>
                  ))}
              </Form.Select>
            </Col>

            <Form.Label column sm="1" className="d-flex align-items-center">
              Call Type
            </Form.Label>
            <Col sm="2" style={{ width: "20%" }}>
              <Form.Select
                aria-label="Default select example"
                style={{ fontSize: "11px" }}
                value={callTypeId}
                onChange={(e) => {
                  setCallTypeId(e.target.value);
                }}
              >
                <option value="">{"<-- Select Call Type -->"}</option>
                {calltypedata &&
                  calltypedata.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.calltype_name}
                    </option>
                  ))}
              </Form.Select>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mt-2">
            <Form.Label column sm="1" className="d-flex align-items-center">
              Call Information
            </Form.Label>
            <Col sm="2" style={{ width: "20%" }}>
              <Form.Select
                aria-label="Default select example"
                style={{ fontSize: "11px" }}
                value={callInfoName}
                onChange={(e) => {
                  setcallInfoName(e.target.value);
                }}
              >
                <option value="">{"<-- Select Call Information -->"}</option>
                {callinfodata &&
                  callinfodata.map((item, index) => (
                    <option key={index} value={item.call_information_name}>
                      {item.call_information_name}
                    </option>
                  ))}
              </Form.Select>
            </Col>

            <Form.Label column sm="1" className="d-flex align-items-center">
              Enquiry Status
            </Form.Label>
            <Col sm="2" style={{ width: "20%" }}>
              <Form.Select
                aria-label="Default select example"
                style={{ fontSize: "11px" }}
                value={enquiryStatusName}
                onChange={(e) => {
                  setenquiryStatusName(e.target.value);
                }}
              >
                <option value="">{"<-- Select Enquiry Status -->"}</option>
                {enquiryStatus &&
                  enquiryStatus.map((item, index) => (
                    <option key={index} value={item.enquery_status_name}>
                      {item.enquery_status_name}
                    </option>
                  ))}
              </Form.Select>
            </Col>
          </Form.Group>
        </Form>
        <Row>
          <Row>
            <Col xl="6" id="defultselect">
              <div className="form-check ps-4">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="allloopvalue"
                  onChange={handleSelectAll}
                  checked={selectall}
                />
                <label className="form-check-label" htmlFor="flexCheckChecked">
                  Select All
                </label>
              </div>
            </Col>
            <Col xl="6" className="text-end">
              <button
                onClick={handleShow}
                type="button"
                className="common-btn p-4"
                id="common-btn"
                variant="primary"
              >
                Transfer
              </button>
            </Col>
          </Row>

          {leadProfilelist &&
            leadProfilelist.map((item, index) => (
              <Col xl={12} className="mb-3" key={item.profile_email}>
                <Box className="mc-card position-relative">
                  <div
                    className="form-check"
                    style={{ position: "absolute", left: "7px", top: "7px" }}
                  >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={selectall}
                      id="loopvalue"
                      onChange={(e) => handleCheckboxChange(e, item.id)}
                      checked={profileIds.includes(item.id)}
                    />
                  </div>
                  <div className="container-fluid">
                    <div className="row d-flex align-items-center">
                      <div className="col-12">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th scope="col">Sr. No</th>
                              <th scope="col"> Name</th>
                              <th scope="col"> Email</th>
                              <th scope="col"> Phone</th>
                              <th scope="col"> Experience</th>
                              <th scope="col"> City</th>
                              <th scope="col"> State</th>
                              <th scope="col"> Comment</th>
                              <th scope="col"> Transfer</th>
                              <th scope="col"> Counsellor</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr key={index}>
                              <th scope="row">{index + 1}</th>
                              <td>{item.profile_name}</td>
                              <td>{item.profile_email}</td>
                              <td>{item.profile_mobile}</td>
                              <td>{item.profile_experience}</td>
                              <td>{item.profile_city}</td>
                              <td>{item.profile_state}</td>
                              <td>{item.profile_messge}</td>
                              <td>
                                {String(item.is_transfer) === "1"
                                  ? "Yes"
                                  : "No"}
                              </td>
                              <td>{item.tranfared_counsellor_name}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </Box>
              </Col>
            ))}
        </Row>
      </ManagerPageLayout>

      <Modal show={show} centered onHide={handleClose}>
        <form>
          <Modal.Header closeButton className="px-3 pt-2 mb-0">
            <Modal.Title>Assign Counsellor</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm="12">
                <Form.Label column className="d-flex align-items-center">
                  Counsellor
                </Form.Label>
              </Col>
              <Col sm="12" className="m-0">
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => setCounsellorId(e.target.value)}
                >
                  <option>{"<-- Select Counsellor -->"}</option>
                  {counsellordata &&
                    counsellordata.map((item, index) => (
                      <option key={item.id} value={item.id}>
                        {item.councellor_name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="pb-3 mt-2">
            <Button
              onClick={handelsubmit}
              type="button"
              className="common-btn"
              variant="primary"
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default LeadProfileList;
