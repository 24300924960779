import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import PageLayout from '../../layouts/PageLayout';
import { Link, useNavigate } from "react-router-dom";
import { Breadcrumb, DivideTitle } from "../../components";
import { Box, Item, Anchor, Text, Button } from "../../components/elements";
import { CardHeader } from "../../components/cards";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { useFormik } from 'formik';
import { AddVendorSchema } from '../schemas/AddVendorSchema';
import APIClient from '../../APIClient';
import { ToastContainer, toast } from "react-toastify";

export default function AddVendor() {
  const initialValues = {
    name: "",
    email: "",
    phone: "",
    username: "",
    pass_word: ""
  }
  
  const navigate = useNavigate();

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: AddVendorSchema,
    onSubmit: (values, { setSubmitting }) => {
      APIClient.get('sanctum/csrf-cookie')
        .then(response => {
          return APIClient.post('api/add-leadvendor', values, {
            headers: {
              "Content-Type": "multipart/form-data",
              "Accept": "multipart/form-data",
              "Authorization": "Bearer " + localStorage.getItem('token')
            }
          });
        })
        .then(addvendorResponse => {
          toast.success(addvendorResponse.data.msg);
          setTimeout(() => {
            navigate('/vendor-list');
          }, 2000);
        })
        .catch(error => {
          console.error("Error adding Vendor:", error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  })
  useEffect(() => {
    if (!localStorage.getItem('token') || localStorage.getItem('token') === null) {
      navigate('/login')
    }
  }, [])

  return (
   <>
   <ToastContainer autoClose={1000} />
   <PageLayout>
      <Row>
        <Col xl={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Breadcrumb title={"Add Vendor"}></Breadcrumb>
            <Link to="/vendor-list">
              <Button className="btn btn-primary common-btn">View Vendors</Button>
            </Link>
          </div>
        </Col>
        <Col xl={7}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
            <Form onSubmit={handleSubmit}>
              <Col xl={12}>
                <div style={{ marginTop: "1rem" }}>
                  <CardHeader title="Name" />
                  <FormControl sx={{ width: "100%" }}>
                    <TextField
                      id="outlined-required"
                      placeholder="Enter Name"
                      name="name"
                      onChange={handleChange}
                      value={values.name}
                      onBlur={handleBlur}
                      type='text'
                      sx={{ width: "100%" }}
                    />
                     {errors.name && touched.name ?
                        <p className="text-danger" style={{ fontSize: "13px" }}>{errors.name}</p>
                        : null}
                  </FormControl>
                </div>
              </Col>
              <Col xl={12}>
                <div style={{ marginTop: "1rem" }}>
                  <CardHeader title="Email" />
                  <FormControl sx={{ width: "100%" }}>
                    <TextField
                      id="outlined-required"
                      placeholder="Enter Email"
                      name="email"
                      onChange={handleChange}
                      value={values.email}
                      onBlur={handleBlur}
                      type='email'
                      sx={{ width: "100%" }}
                    />
                     {errors.email && touched.email ?
                        <p className="text-danger" style={{ fontSize: "13px" }}>{errors.email}</p>
                        : null}
                  </FormControl>
                </div>
              </Col>
            
              <Col xl={12}>
                <div style={{ marginTop: "1rem" }}>
                  <CardHeader title="Phone" />
                  <FormControl sx={{ width: "100%" }}>
                    <TextField
                      id="outlined-required"
                      placeholder="Enter Phone Number"
                      name="phone"
                      onChange={handleChange}
                      value={values.phone}
                      onBlur={handleBlur}
                      type='number'
                      sx={{ width: "100%" }}
                    />
                     {errors.phone && touched.phone ?
                        <p className="text-danger" style={{ fontSize: "13px" }}>{errors.phone}</p>
                        : null}
                  </FormControl>
                </div>
              </Col>

              <Col xl={12}>
                <div style={{ marginTop: "1rem" }}>
                  <CardHeader title="Username" />
                  <FormControl sx={{ width: "100%" }}>
                    <TextField
                      id="outlined-required"
                      placeholder="Enter Username"
                      name="username"
                      onChange={handleChange}
                      value={values.username}
                      onBlur={handleBlur}
                      type='text'
                      sx={{ width: "100%" }}
                    />
                     {errors.username && touched.username ?
                        <p className="text-danger" style={{ fontSize: "13px" }}>{errors.username}</p>
                        : null}
                  </FormControl>
                </div>
              </Col>
              
              <Col xl={12}>
                <div style={{ marginTop: "1rem" }}>
                  <CardHeader title="Password" />
                  <FormControl sx={{ width: "100%" }}>
                    <TextField
                      id="outlined-required"
                      placeholder="Enter Valid Password"
                      name="pass_word"
                      onChange={handleChange}
                      value={values.pass_word}
                      onBlur={handleBlur}
                      type='password'
                      sx={{ width: "100%" }}
                    />
                     {errors.pass_word && touched.pass_word ?
                        <p className="text-danger" style={{ fontSize: "13px" }}>{errors.pass_word}</p>
                        : null}
                  </FormControl>
                </div>
              </Col>


              <Col xl={3}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "start",
                    alignItems: "center",
                    marginTop: "1.25rem",
                  }}
                >
                    <Button type="submit" className="btn btn-primary common-btn">Save</Button>
                </div>
              </Col>
              </Form>
            </Row>
          </Box>
        </Col>
      </Row>
    </PageLayout>
   </>
  )
}
