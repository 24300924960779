import * as Yup from "yup";

export const AddnewEnquirySchema = Yup.object({
  name: Yup.string().required("Please enter name"),
  // age: Yup.number().required("Please enter age"),
  // dob: Yup.date().required("Please enter date of birth"),
  // country: Yup.string().required("Please enter country"),
  // state: Yup.string().required("Please enter state"),
  // city: Yup.string().required("Please enter city"),
  // course_id: Yup.string().required("Please select Course "),
  // specialization_id: Yup.string().required("Please select Any specialization"),
  // univercity_name: Yup.string().required("Please select university name"),
  // call_type_id: Yup.string().required("Please select call type "),
  // company: Yup.string().required("Please enter company"),
  // designation: Yup.string().required("Please enter designation"),
  // experience: Yup.number().required("Please enter experience"),
  // nationality: Yup.string().required("Please enter nationality"),
  mobile: Yup.string().required("Please enter mobile number"),
  // alt_mobile: Yup.string().required("Please enter alternative mobile number"),
  email: Yup.string().email().required("Please enter email"),
  // alt_email: Yup.string().email().required("Please enter alternative email"),
  // message: Yup.string().required("Please enter message"),
});
