import { createContext, useState } from "react";

export const EnquiryContext = createContext();

export const EnquiryProvider = ({ children }) => {

    const [enquiryType, setEnquiryType] = useState(0);

    return (
        <EnquiryContext.Provider value={[enquiryType, setEnquiryType]}>
            { children }
        </EnquiryContext.Provider>
    )
}