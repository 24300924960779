import { ThemeProvider } from "./context/Themes";
import { LoaderProvider } from "./context/Preloader";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Error } from "./pages/supports";
import {
  Avatars,
  Alerts,
  Buttons,
  Charts,
  Tables,
  Fields,
  Colors,
} from "./pages/blocks";
import {
  Ecommerce,
  ForgotPassword,
  Login,
  UserList,
  UserProfile,
  MyAccount,
  BlankPage,
  Settings,
  Notification,
} from "./pages/master";
import "react-toastify/dist/ReactToastify.css";
import AddSchool from "./pages/master/AddSchool";
import AddCounselling from "./pages/master/AddCounselling";
import CounsellingList from "./pages/master/CounsellingList";
import SchoolsList from "./pages/master/SchoolsList";
import AddCounsellor from "./pages/master/AddCounsellor";
import Counsellorlist from "./pages/master/CounsellorList";
import AddCourse from "./pages/master/AddCourse";
import CourseList from "./pages/master/CourseList";
import AddVendor from "./pages/master/AddVendor";
import VendorList from "./pages/master/VendorList";
import AddCounsellorGroup from "./pages/master/AddCounsellorGroup";
import AddVendorGroup from "./pages/master/AddVendorGroup";
import VendorGroupList from "./pages/master/VendorGroupList";
import VendorLogin from "./pages/master/VendorLogin";
import VendorDashbord from "./pages/master/VendorDashbord";
import CounsellorLogin from "./pages/master/CounsellorLogin";
import ControlCounsellor from "./pages/master/ControlCounsellor";
import AddSpecialization from "./pages/master/AddSpecialization";
import AddCallType from "./pages/master/AddCallType";
import AddCallInformation from "./pages/master/AddCallInformation";
import AddLeadProtential from "./pages/master/AddLeadProtential";
import AddLeadStatus from "./pages/master/AddLeadStatus";
import AddEnquiryStatus from "./pages/master/AddEnquiryStatus";
import AddManager from "./pages/master/AddManager";
import ManagerList from "./pages/master/ManagerList";
import EditCounsellor from "./pages/master/EditCounsellor";
import Manageedit from "./pages/master/Manageedit";
import Editcourse from "./pages/master/Editcourse";
import EditSpecialization from "./pages/master/EditSpecialization";
import EditCallType from "./pages/master/EditCallType";
import EditCallInformation from "./pages/master/EditCallInformation";
import EditLeadPotential from "./pages/master/EditLeadPotential";
import EditLeadStatus from "./pages/master/EditLeadStatus";
import EditEnquiryStatus from "./pages/master/EditEnquiryStatus";
import EditVendor from "./pages/master/EditVendor";
import AddGroup from "./pages/master/AddGroup";
import GroupList from "./pages/master/GroupList";
import EditGroupItem from "./pages/master/EditGroupItem";
import Permission from "./pages/master/Permission";
import NewEnquiryedit from "./pages/master/fourEnq/NewEnquiryedit";
import NewEnquiry from "./pages/master/NewEnquiry";
import ManagerLogin from "./pages/master/ManagerLogin";
import ManagerDashbord from "./pages/master/ManagerDashbord";
import AddLead from "./pages/master/AddLead";
import LeadList from "./pages/master/LeadList";
import CounsellorGroupList from "./pages/master/CounsellorGroupList";
import EditvendorGroup from "./pages/master/EditvendorGroup";
import EditCounsellorGroupEdit from "./pages/master/EditCounsellorGroupEdit";
import AddUniversity from "./pages/master/AddUniversity";
import EditUniversity from "./pages/master/EditUniversity";
import FollowUpMissed from "./pages/master/FollowUpMissed";
import ManagerjourneyList from "./pages/master/fourEnq/ManagerjourneyList";
import ManagerAssign from "./pages/master/ManagerAssign";
import EditManagerAssign from "./pages/master/EditManagerAssign";
import LeadProfileList from "./pages/master/LeadProfileList";
import LeadProtensial from "./pages/master/fourEnq/LeadProtensial";
import ViewFollowup from "./pages/master/fourEnq/ViewFollowup";
import CallInformation from "./pages/master/fourEnq/CallInformation";
import Totalenquiry from "./pages/master/fourEnq/Totalenquiry";
import { TotalEnquiryProvider } from "./context/TotalEnquiryContext";
import CounsellorNotification from "./pages/master/CounsellorNotification";

export default function App() {
  return (
    <ThemeProvider>
      <LoaderProvider>
        <TotalEnquiryProvider>
          <BrowserRouter>
            <Routes>
              {/* master Pages */}
              <Route path="/ecommerce" element={<Ecommerce />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/user-list" element={<UserList />} />
              <Route path="/user-profile" element={<UserProfile />} />
              <Route path="/my-account" element={<MyAccount />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/blank-page" element={<BlankPage />} />
              <Route path="/lead-status/:id" element={<NewEnquiry />} />
              <Route path="/new-enquiryedit/:Id" element={<NewEnquiryedit />} />
              {/* <Route path="/dasbordlead-status/:lead_status_id" element={<Totalenquiry />} /> */}
              <Route path="/view-profiles" element={<Totalenquiry />} />
              <Route path="/view-folllow-up" element={<ViewFollowup />} />
              <Route
                path="/lead-Protential/:lead_potential_name"
                element={<LeadProtensial />}
              />
              <Route
                path="/call-information/:call_information_name"
                element={<CallInformation />}
              />

              <Route path="/add-counsellor" element={<AddCounsellor />} />
              <Route path="/Counsellor-list" element={<Counsellorlist />} />
              <Route path="/editCounsellor/:Id" element={<EditCounsellor />} />

              <Route path="/add-school" element={<AddSchool />} />
              <Route path="/schools-list" element={<SchoolsList />} />

              <Route path="/add-Course" element={<AddCourse />} />
              <Route path="/course-list" element={<CourseList />} />
              <Route path="/editCourse/:Id" element={<Editcourse />} />

              <Route
                path="/add-specialization"
                element={<AddSpecialization />}
              />
              <Route path="/add-university" element={<AddUniversity />} />
              <Route path="/edituniversity/:Id" element={<EditUniversity />} />

              <Route
                path="/editspecialization/:Id"
                element={<EditSpecialization />}
              />

              <Route path="/add-call-type" element={<AddCallType />} />
              <Route path="/editcalltype/:Id" element={<EditCallType />} />

              <Route
                path="/add-call-information"
                element={<AddCallInformation />}
              />
              <Route
                path="/edit-call-information/:Id"
                element={<EditCallInformation />}
              />

              <Route
                path="/add-lead-potential"
                element={<AddLeadProtential />}
              />
              <Route
                path="/edit-lead-potential/:Id"
                element={<EditLeadPotential />}
              />

              <Route path="/add-lead-status" element={<AddLeadStatus />} />
              <Route
                path="/edit-lead-status/:Id"
                element={<EditLeadStatus />}
              />

              <Route
                path="/add-enquiry-status"
                element={<AddEnquiryStatus />}
              />
              <Route
                path="/edit-enquiry-status/:Id"
                element={<EditEnquiryStatus />}
              />

              <Route path="/add-vendor" element={<AddVendor />} />
              <Route path="/vendor-list" element={<VendorList />} />
              <Route path="/edit-vendor/:Id" element={<EditVendor />} />

              <Route path="/add-manager" element={<AddManager />} />
              <Route path="/manager-list" element={<ManagerList />} />
              <Route path="/manager-edit/:Id" element={<Manageedit />} />

              <Route path="/add-group" element={<AddGroup />} />
              <Route path="/group-list" element={<GroupList />} />
              <Route path="/editgroupitem/:Id" element={<EditGroupItem />} />
              <Route path="/permission" element={<Permission />} />

              <Route
                path="/add-counsellor-group"
                element={<AddCounsellorGroup />}
              />
              <Route
                path="/Counsellor-group-list"
                element={<CounsellorGroupList />}
              />
              <Route
                path="/editcounsellorgroup/:Id"
                element={<EditCounsellorGroupEdit />}
              />

              <Route path="/add-vendor-group" element={<AddVendorGroup />} />
              <Route path="/vendor-group-list" element={<VendorGroupList />} />
              <Route
                path="/editvendorgroup/:Id"
                element={<EditvendorGroup />}
              />

              <Route path="/add-counselling" element={<AddCounselling />} />
              <Route path="/counselling-list" element={<CounsellingList />} />

              {/* vendor dashbord */}
              <Route path="/vendor" element={<VendorLogin />} />
              <Route path="/vendor/dashboard" element={<VendorDashbord />} />
              <Route
                path="/vendor/followupmissed"
                element={<FollowUpMissed />}
              />

              {/* manager  */}
              <Route path="/manager" element={<ManagerLogin />} />
              <Route path="/manager/dashboard" element={<ManagerDashbord />} />
              <Route path="/manager/add-lead" element={<AddLead />} />
              <Route path="/manager/lead-List" element={<LeadList />} />
              <Route
                path="/manager/lead-profile-list"
                element={<LeadProfileList />}
              />
              <Route
                path="/manager/journeyhistory/:Id"
                element={<ManagerjourneyList />}
              />
              <Route
                path="/counsellorgroupassign/:Id"
                element={<ManagerAssign />}
              />
              <Route
                path="/editcounsellorgroupassign/:Id"
                element={<EditManagerAssign />}
              />

              {/* Manager sidebar short links  */}
              <Route
                path="/manager/followupmissed"
                element={<FollowUpMissed />}
              />
              <Route path="/manager/lead-status/:id" element={<NewEnquiry />} />
              <Route
                path="/manager/new-enquiryedit/:Id"
                element={<NewEnquiryedit />}
              />

              {/* counsellor  */}
              <Route exact path="/counsellor" element={<CounsellorLogin />} />
              <Route
                path="/counsellor/dashboard"
                element={<ControlCounsellor />}
              />
              <Route
                path="/counsellor/notification/:id"
                element={<CounsellorNotification />}
              />

              {/* Counsellor sidebar short links  */}
              <Route
                path="/counsellor/followupmissed"
                element={<FollowUpMissed />}
              />
              <Route
                path="/counsellor/lead-status/:id"
                element={<NewEnquiry />}
              />
              <Route
                path="/counsellor/new-enquiryedit/:Id"
                element={<NewEnquiryedit />}
              />
              <Route
                path="/counsellor/view-profiles"
                element={<Totalenquiry />}
              />
              <Route path="/manager/view-profiles" element={<Totalenquiry />} />
              <Route path="/vendor/view-profiles" element={<Totalenquiry />} />

              {/* vendor Sidebar short Link  */}
              <Route path="/vendor/lead-status/:id" element={<NewEnquiry />} />
              {/* Blocks Pages */}

              <Route path="/buttons" element={<Buttons />} />
              <Route path="/avatars" element={<Avatars />} />
              <Route path="/colors" element={<Colors />} />
              <Route path="/charts" element={<Charts />} />
              <Route path="/tables" element={<Tables />} />
              <Route path="/fields" element={<Fields />} />
              <Route path="/alerts" element={<Alerts />} />

              {/* Supports Pages */}
              <Route path="*" element={<Error />} />

              <Route path="/" element={<Ecommerce />} />
            </Routes>
          </BrowserRouter>
        </TotalEnquiryProvider>
      </LoaderProvider>
    </ThemeProvider>
  );
}
