import React, { useState } from "react";
import Main from "./Mian";
import VendorHeader from "./VendorHeader";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";
import { DrawerProvider } from "../context/Drawer";
import VendorSidebar from "./VendorSidebar";
import VendorMainContent from "./VendorMainContent";
export default function VendorpageLayout({ children }) {
  const location = useLocation();

  return (
    <>
      <DrawerProvider>
        <VendorHeader />
          <VendorSidebar />
          <Main>
            <>
              {/* <VendorMainContent /> */}
              { children }
              {location.pathname !== "/message" ? <Footer /> : ""}
            </>
          </Main>
      </DrawerProvider>
    </>
  );
}
