import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";
import { Form } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import APIClient from "../../APIClient";
import { ToastContainer, toast } from "react-toastify";
import { useFormik } from "formik";
import { AddVendorGroupSchema } from "../schemas/AddVendorGroupSchema";

const EditCounsellorGroupEdit = () => {
  const navigate = useNavigate();
  const { Id } = useParams();
  const [options, setOptions] = useState([]);
  

  const [initialValues, setInitialValues] = useState({
    counsellor_group_ids: [],
    manager_id: Id,
  });

  useEffect(() => {
    APIClient.get("sanctum/csrf-cookie");
    APIClient.get("api/single-manager-group/" + Id, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        const counsellorassigngroup = res.data;
        setInitialValues({
          group_id: Id,
          counsellor_group_ids: counsellorassigngroup.counsellor_group_ids
            .split(",")
            .map((id) => parseInt(id, 10)),
        });
      })
      .catch((err) => console.log(err));
  }, [Id]);

  const fetchCounsellorGroupList = async () => {
    try {
      await APIClient.get("sanctum/csrf-cookie");
      const response = await APIClient.get("api/counsellorgroup-list", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      const formattedOptions = response.data.counsellor_group.map(
        (counsellorgroup) => ({
          value: counsellorgroup.id,
          label: counsellorgroup.group_name,
        })
      );
      setOptions(formattedOptions);
    } catch (error) {
      console.error("Error fetching counsellor Group list:", error);
    }
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    // validationSchema: AddVendorGroupSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const counsellorIdsString = values.counsellor_group_ids.join(",");
        const payload = {
          counsellor_group_ids: counsellorIdsString,
          manager_id: Id,
        };
        
        await APIClient.get("sanctum/csrf-cookie");
        const response = await APIClient.post(
          "api/update-manager-group",
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        console.log(payload);
        toast.success(response.data.msg);
        setTimeout(() => {
          navigate("/manager-list");
        }, 2000);
      } catch (error) {
        console.error("Error Assign group:", error);
        toast.error("Failed to Assign Counsellor group");
      } finally {
        setSubmitting(false);
      }
    },
  });
  
  useEffect(() => {
    fetchCounsellorGroupList();
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <>
      <ToastContainer />
      <PageLayout>
        <Row>
          <Col xl={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Breadcrumb title={"Update Assign Counsellor Group"} />
             
            </div>
          </Col>
          <Form onSubmit={handleSubmit}>
            <Col xl={12}>
              <Box className="mc-card">
                <Row xs={1} md={1} xl={1}>
                  <Col xl={6}>
                    <div style={{ marginTop: "0rem" }}>
                      <CardHeader title="Select Counsellor" />
                      <Select
                        options={options}
                        value={options.filter((option) =>
                          values.counsellor_group_ids.includes(option.value)
                        )}
                        name="counsellor_group_ids"
                        onChange={(selectedOptions) =>
                          setFieldValue(
                            "counsellor_group_ids",
                            selectedOptions.map((option) => option.value)
                          )
                        }
                        isMulti
                      />
                      {/* {errors.counsellor_group_ids && touched.counsellor_group_ids ? (
                        <p className="text-danger" style={{ fontSize: "13px" }}>
                          {errors.counsellor_group_ids}
                        </p>
                      ) : null} */}
                    </div>
                  </Col>

                  <Col xl={12}>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "start",
                        alignItems: "center",
                        marginTop: "1.25rem",
                      }}
                    >
                      <Button
                        type="submit"
                        className="btn btn-primary common-btn"
                      >
                        Save
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Box>
            </Col>
          </Form>
        </Row>
      </PageLayout>
    </>
  );
};

export default EditCounsellorGroupEdit;
