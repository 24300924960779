import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";
import { Link, useNavigate } from "react-router-dom";
import APIClient from "../../APIClient";
import { ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
import ListLoader from "../../components/SkeletonLoaderComponent/ListLoader";

export default function CounsellorGroupList() {
  const navigate = useNavigate();
  const [groupdata, setGroupdata] = useState([]);
  const [vendorgroupdatadelete, setCounsellorgroupdatadelete] = useState();
  const [loading, setLoading] = useState(false);

  const fetchgroupdata = async () => {
    setLoading(true);
    try {
      const csrfResponse = await APIClient.get("sanctum/csrf-cookie");
      if (csrfResponse.status === 204) {
        const getgroupResponse = await APIClient.get(
          "api/counsellorgroup-list",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        setGroupdata(getgroupResponse.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching group data:", error);
    }
  };

  const handeldelete = async (counsellorgroupId) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    Swal.fire({
      title: "Are you sure?",
      text: "You won't to Delete Counsellor Group !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((delitem) => {
      if (delitem.isConfirmed) {
        APIClient.get("sanctum/csrf-cookie").then((response) => {
          APIClient.get(`api/delete-counsellorgroup/${counsellorgroupId}`).then(
            (delcounsellorGroup) => {
              setCounsellorgroupdatadelete(delcounsellorGroup);
              fetchgroupdata();
            }
          );
        });
        console.log(counsellorgroupId);
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
      } else if (delitem.dismiss === Swal.DismissReason.cancel) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Delete Cancel :)",
          icon: "error",
        });
      }
    });
  };

  useEffect(() => {
    fetchgroupdata();
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <>
      <ToastContainer autoClose={1000} />
      <PageLayout>
        <Row>
          <Col xl={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Breadcrumb title="Counsellor Group List"></Breadcrumb>
              <Link to="/add-counsellor-group">
                <Button className="btn btn-primary common-btn">
                  Add Counsellor Group
                </Button>
              </Link>
            </div>
          </Col>
          <Col xl={12}>
            <Box className="mc-card p-0">
              <Row xs={1} md={1} xl={1}>
                <Col xl={12}>
                  <Box className="mc-card">
                    <CardHeader title="Counsellor Group List" />
                    <div className="container-fluid">
                      <div className="row">
                        <ListLoader load={loading} layout={3}>
                          {" "}
                          <div className="col-12">
                            <table
                              className="table table-bordered"
                              style={{
                                width: "100%",
                                overflowX: "auto",
                                whiteSpace: "nowrap",
                              }}
                            >
                              <thead>
                                <tr>
                                  <th scope="col">S.no</th>
                                  <th scope="col">Group Name</th>
                                  <th scope="col">Counsellors</th>
                                  <th scope="col">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {groupdata &&
                                  groupdata.counsellor_group &&
                                  groupdata.counsellor_group.map(
                                    (item, index) => (
                                      <tr key={index}>
                                        <th scope="row">{index + 1}</th>
                                        <td>{item.group_name}</td>
                                        <td>
                                          {item.CouncellorNameArr.join(", ")}
                                        </td>
                                        <td
                                          style={{
                                            display: "flex",
                                            justifyContent: "start",
                                          }}
                                        >
                                          <Link
                                            to={`/editcounsellorgroup/${item.id}`}
                                            style={{
                                              width: "2rem",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              marginRight: "1.6rem",
                                            }}
                                            className="btn btn-success"
                                          >
                                            <i className="fas fa-edit"></i>
                                          </Link>
                                          <button
                                            type="button"
                                            onClick={() =>
                                              handeldelete(item.id)
                                            }
                                            style={{
                                              width: "2rem",
                                              fontSize: "0.6rem",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                            className="btn btn-danger"
                                          >
                                            <i className="fas fa-trash"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    )
                                  )}
                              </tbody>
                            </table>
                          </div>
                        </ListLoader>
                      </div>
                    </div>
                  </Box>
                </Col>
              </Row>
            </Box>
          </Col>
        </Row>
      </PageLayout>
    </>
  );
}
