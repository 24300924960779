import React from "react";
import Main from "./Mian";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";
import { DrawerProvider } from "../context/Drawer";
import ManagerHeader from "./ManagerHeader";
import ManagerSidebar from "./ManagerSidebar";
export default function ManagerPageLayout({ children }) {
  const location = useLocation();

  return (
    <>
      <DrawerProvider>
        <ManagerHeader />
          <ManagerSidebar />
          <Main>
            <>
              {/* <VendorMainContent /> */}
              { children }
              {location.pathname !== "/message" ? <Footer /> : ""}
            </>
          </Main>
      </DrawerProvider>
    </>
  );
}