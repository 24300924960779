import React from "react";
import Skeleton from "react-loading-skeleton";

const ListLoader = ({ load, children, layout = 1 }) => {
  return (
    <>
      {load === true ? (
        layout === 1 ? (
          <div className="col-12 overflow-auto">
            <table className="table table-bordered ">
              <thead>
                <tr>
                  <th scope="col">
                    <Skeleton height={30} width={50} />
                  </th>
                  <th scope="col">
                    <Skeleton height={30} width={80} />
                  </th>
                  <th scope="col">
                    {" "}
                    <Skeleton height={30} width={80} />
                  </th>
                  <th scope="col">
                    {" "}
                    <Skeleton height={30} width={80} />
                  </th>
                  <th scope="col">
                    {" "}
                    <Skeleton height={30} width={80} />
                  </th>
                  <th scope="col">
                    {" "}
                    <Skeleton height={30} width={80} />
                  </th>
                  <th scope="col">
                    {" "}
                    <Skeleton height={30} width={80} />
                  </th>
                  <th scope="col">
                    {" "}
                    <Skeleton height={30} width={80} />
                  </th>
                  <th scope="col">
                    <Skeleton height={30} width={80} />
                  </th>
                </tr>
              </thead>
              <tbody>
                {Array(10)
                  .fill()
                  .map((index) => (
                    <tr>
                      <td scope="row">
                        <Skeleton height={30} width={50} />
                      </td>
                      <td>
                        <Skeleton height={30} width={50} />
                      </td>
                      <td>
                        <Skeleton height={30} width={120} />
                      </td>
                      <td>
                        <Skeleton height={30} width={135} />
                      </td>
                      <td>
                        <Skeleton height={30} width={100} />
                      </td>
                      <td>
                        <Skeleton height={80} width={70} />
                      </td>
                      <td>
                        <Skeleton height={30} width={80} count={3} />
                      </td>
                      <td>
                        <Skeleton height={30} width={80} />
                      </td>

                      <td
                        style={{
                          display: "flex",
                          justifyContent: "start",
                        }}
                      >
                        <Skeleton height={30} width={30} />

                        <Skeleton height={30} width={30} />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        ) : layout === 2 ? (
          <div className="col-12">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">
                    <Skeleton height={30} width={50} />
                  </th>
                  <th scope="col">
                    <Skeleton height={30} width={80} />
                  </th>
                  <th scope="col">
                    <Skeleton height={30} width={80} />
                  </th>
                </tr>
              </thead>
              <tbody>
                {Array(10)
                  .fill()
                  .map((index) => (
                    <tr>
                      <th scope="row">
                        <Skeleton height={30} width={50} />
                      </th>
                      <td>
                        <Skeleton height={30} width={80} />
                      </td>

                      <td
                        style={{
                          display: "flex",
                          justifyContent: "start",
                        }}
                      >
                        <Skeleton height={30} width={30} />
                        <Skeleton
                          height={30}
                          width={30}
                          style={{ marginLeft: "16px" }}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        ) : (
          layout === 3 && (
            <div className="col-12">
              <table
                className="table table-bordered"
                style={{
                  width: "100%",
                  overflowX: "auto",
                  whiteSpace: "nowrap",
                }}
              >
                <thead>
                  <tr>
                    <th scope="col">
                      <Skeleton height={30} width={50} />
                    </th>
                    <th scope="col">
                      <Skeleton height={30} width={80} />
                    </th>
                    <th scope="col">
                      <Skeleton height={30} width={80} />
                    </th>
                    <th scope="col">
                      <Skeleton height={30} width={80} />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Array(10)
                    .fill()
                    .map((index) => (
                      <tr key={index}>
                        <th scope="row">
                          <Skeleton height={30} width={50} />
                        </th>
                        <td>
                          <Skeleton height={30} width={80} />
                        </td>
                        <td>
                          <Skeleton height={30} width={80} />
                        </td>
                        <td
                          style={{
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          <Skeleton height={30} width={30} />
                          <Skeleton
                            height={30}
                            width={30}
                            style={{ marginLeft: "16px" }}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )
        )
      ) : (
        children
      )}
    </>
  );
};

export default ListLoader;
