import * as Yup from "yup";

export const AddCallStatusTabSchema = Yup.object({
    information: Yup.string().required("Please Select Lead  Information"),
    enquiry_status: Yup.string().required("Please Select Lead  Status"),
    lead_potential: Yup.string().required("Please Select Lead  potential"),
    lead_status: Yup.string().required("Please Select Lead Status"),
    call_type_id:Yup.string().required("Please Select Call Type"),
    // univercity: Yup.string().required("Please Enter univercity Name"),
    next_followup_date: Yup.date().required("Please select Following Date"),
    next_followup_time: Yup.string().required("Please select time"),
    added_date: Yup.date().required("Please select Date"),
});