import { Row, Col, Form } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import APIClient from "../../APIClient";
import { AddSpecializationSchema } from "../schemas/AddSpecializationSchema";
import { useEffect, useState } from "react";

export default function EditSpecialization() {
    const { Id } = useParams();
    const navigate = useNavigate();
    const [initialValues, setInitialValues] = useState({
      specialization_id: Id,
      specialization_name: ""   
    });

    useEffect(()=>{
        APIClient.get('sanctum/csrf-cookie')
        APIClient.get('api/single-specialization/'+ Id,{
            headers: {
                "Authorization": "Bearer "+ localStorage.getItem('token')
            }
        }).then(res =>{
            setInitialValues({...initialValues, 
                specialization_name:res.data.specialization_list[0].specialization_name
            })
        })
        .catch(err => console.log(err))
    },[])

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        enableReinitialize:true,
        validationSchema: AddSpecializationSchema,
        onSubmit: (values, { setSubmitting }) => {
            APIClient.get('sanctum/csrf-cookie')
                .then(response => {
                    return APIClient.post('api/update-specialization', values, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            "Accept": "multipart/form-data",
                            "Authorization": "Bearer " + localStorage.getItem('token')
                        }
                    });
                })
                .then(editspecializationResponse => {
                    toast.success(editspecializationResponse.data.msg);
                    setTimeout(() => {
                        navigate('/add-specialization');
                    }, 2000);
                })
                .catch(error => {
                    console.error("Error adding specialization:", error);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        },
    })

    
    
      useEffect(() => {
        if (!localStorage.getItem('token') || localStorage.getItem('token') === null) {
          navigate('/login')
        }
      }, [])

      return (
        <>
      <ToastContainer autoClose={1000} />
      <PageLayout>
        <Row>
          <Col xl={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Breadcrumb title={"Update Specialization"}></Breadcrumb>
            </div>
          </Col>
          <Col xl={7}>
            <Box className="mc-card">
              <Form onSubmit={handleSubmit}>
                <Row xs={1} md={1} xl={1}>
                  <Col xl={12}>
                    <div style={{ marginTop: "1rem" }}>
                      <CardHeader title="Specialization Name" />
                      <FormControl sx={{ width: "100%" }}>
                        <TextField
                          id="outlined-required"
                          placeholder="Enter Specialization Name"
                          name="specialization_name"
                          onChange={handleChange}
                          value={values.specialization_name}
                          onBlur={handleBlur}
                          sx={{ width: "100%" }}
                        />
                        {errors.specialization_name && touched.specialization_name ?
                          <p className="text-danger" style={{ fontSize: "13px" }}>{errors.specialization_name}</p>
                          : null}
                      </FormControl>
                    </div>
                  </Col>

                  <Col xl={5}>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "start",
                        alignItems: "center",
                        marginTop: "0.25rem",
                      }}
                    >
                      <Button type="submit" className="btn btn-primary common-btn">
                        Update Specialization
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Box>
          </Col>
        </Row>
        </PageLayout>
    </>
  );
}