import React, { useContext, useEffect, useState } from "react";
import CounsellorDashboard from "../../pages/master/CounsellorDashboard";
// import { Outlet } from "react-router-dom";

import CounsellorPageLayout from "../../layouts/CousellorPageLayout";
import CounsellorModal from "../../context/CounsellorModal";
import { Button, Col, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import APIClient from "../../APIClient";
import { ToastContainer, toast } from "react-toastify";
import { AddquickleadprofileSchema } from "../schemas/AddquickleadprofileSchema";
import { LoaderContext, LoaderProvider } from "../../context/Preloader";
import { TotalEnquiryContext } from "../../context/TotalEnquiryContext";

const ControlCounsellor = () => {
  const [openEnqModal, setOpenEnqModal] = useState(false);
  const [vendordata, setVendordata] = useState();
  const { setLoading } = useContext(LoaderContext);
  const enqModal = {
    height: "100vh",
    width: "100vw",
    backgroundColor: "#00000050",
    position: "absolute",
    top: "0px",
    right: "0px",
    zIndex: "99",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const enqModalBody = {
    width: "30%",
    height: "auto",
    backgroundColor: "#fff",
    borderRadius: "10px",
    padding: "20px",
  };
  if (openEnqModal === true) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }

  const userId = localStorage.getItem("user_id");
  // fetch vendor lsit
  const fetchvendordata = async () => {
    APIClient.get("sanctum/csrf-cookie").then((response) => {
      APIClient.get("api/leadvendor-list", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((getvendorResponse) => {
        setVendordata(getvendorResponse);
      });
    });
  };
  // add enquiry
  const initialValues = {
    counsellor_id: userId,
    vendor_id: "",
    name: "",
    email: "",
    phone: "",
    lead_status_id: 1,
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: AddquickleadprofileSchema,
      onSubmit: (values, { setSubmitting, resetForm }) => {
        APIClient.get("sanctum/csrf-cookie")
          .then((response) => {
            return APIClient.post("api/add-quick-enquery", values, {
              headers: {
                "Content-Type": "multipart/form-data",
                Accept: "multipart/form-data",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            });
          })
          .then((addquickleadprofile) => {
            toast.success(addquickleadprofile.data.msg);
            setTimeout(() => {
              resetForm();
              setOpenEnqModal(false);
              setLoading(true);
            }, 2000);
            setTimeout(() => {
              setLoading(false);
            }, 3000);
          })
          .catch((error) => {
            console.error("Error adding Quick Lead Profile", error);
            setLoading(false);
          })
          .finally(() => {
            setSubmitting(false);
          });
      },
    });

  useEffect(() => {
    fetchvendordata();
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    if (
      !localStorage.getItem("role_type") ||
      localStorage.getItem("role_type") != 2
    ) {
      navigate("/counsellor");
    }
  }, []);

  return (
    <>
      <ToastContainer />
      <CounsellorModal.Provider
        value={{
          openEnqModal,
          setOpenEnqModal,
        }}
      >
        <CounsellorPageLayout>
          <CounsellorDashboard />
        </CounsellorPageLayout>
        {openEnqModal ? (
          <div style={enqModal} className="EnquiryModal">
            <div style={enqModalBody} className="EnquiryModalBody">
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <Form.Group className="d-flex justify-content-center mb-2">
                  <Form.Label
                    column
                    sm="3"
                    className="d-flex align-items-center justify-content-center"
                  >
                    Name
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      type="text"
                      placeholder="Enter Name"
                      name="name"
                      onChange={handleChange}
                      value={values.name}
                      onBlur={handleBlur}
                    />
                    {errors.name && touched.name ? (
                      <p className="text-danger" style={{ fontSize: "13px" }}>
                        {errors.name}
                      </p>
                    ) : null}
                  </Col>
                </Form.Group>
                <Form.Group className="d-flex justify-content-center mb-2">
                  <Form.Label
                    column
                    sm="3"
                    className="d-flex align-items-center justify-content-center"
                  >
                    Email
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      type="email"
                      placeholder="Enter Email"
                      name="email"
                      onChange={handleChange}
                      value={values.email}
                      onBlur={handleBlur}
                    />
                    {errors.email && touched.email ? (
                      <p className="text-danger" style={{ fontSize: "13px" }}>
                        {errors.email}
                      </p>
                    ) : null}
                  </Col>
                </Form.Group>
                <Form.Group className="d-flex justify-content-center mb-2">
                  <Form.Label
                    column
                    sm="3"
                    className="d-flex align-items-center justify-content-center"
                  >
                    Mobile
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      type="number"
                      placeholder="Enter Number"
                      name="phone"
                      onChange={handleChange}
                      value={values.phone}
                      onBlur={handleBlur}
                    />
                    {errors.phone && touched.phone ? (
                      <p className="text-danger" style={{ fontSize: "13px" }}>
                        {errors.phone}
                      </p>
                    ) : null}
                  </Col>
                </Form.Group>
                <Form.Group className="d-flex justify-content-center mb-2">
                  <Form.Label
                    column
                    sm="3"
                    className="d-flex align-items-center justify-content-center"
                  >
                    Select Vendor
                  </Form.Label>
                  <Col sm="7">
                    <select
                      className="form-select"
                      name="vendor_id"
                      onChange={handleChange}
                      value={values.vendor_id}
                      onBlur={handleBlur}
                    >
                      <option>-- Select Vendor --</option>
                      {vendordata &&
                        vendordata.data.leadvendor_list.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                    {errors.vendor_id && touched.vendor_id ? (
                      <p className="text-danger" style={{ fontSize: "13px" }}>
                        {errors.vendor_id}
                      </p>
                    ) : null}
                  </Col>
                </Form.Group>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    onClick={() => setOpenEnqModal(false)}
                    className=""
                    variant="success"
                    type="button"
                    style={{ width: "32%" }}
                  >
                    Cancle
                  </Button>
                  <Button
                    style={{ width: "32%" }}
                    className="common-btn"
                    variant="primary"
                    type="submit"
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        ) : (
          ""
        )}
      </CounsellorModal.Provider>
    </>
  );
};

export default ControlCounsellor;
