import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";
import { Form } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import APIClient from "../../APIClient";
import { ToastContainer, toast } from "react-toastify";
import { useFormik } from "formik";
import { AddVendorGroupSchema } from "../schemas/AddVendorGroupSchema";

const EditCounsellorGroupEdit = () => {
  const navigate = useNavigate();
  const { Id } = useParams();
  const [options, setOptions] = useState([]);

  const [initialValues, setInitialValues] = useState({
    group_name: "",
    counsellor_ids: [],
    counsellorgroup_id: Id,
  });

  useEffect(() => {
    APIClient.get("sanctum/csrf-cookie");
    APIClient.get("api/single-counsellorgroup/" + Id, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        const counsellorgroup = res.data.counsellor_group[0];
        setInitialValues({
          group_name: counsellorgroup.group_name,
          counsellorgroup_id: Id,
          counsellor_ids: counsellorgroup.counsellor_ids
            .split(",")
            .map((id) => parseInt(id, 10)),
        });
      })
      .catch((err) => console.log(err));
  }, [Id]);

  useEffect(() => {
    APIClient.get("api/councellor-list", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        const counsellorOptions = res.data.councellor_list.map((counsellor) => ({
          value: counsellor.id,
          label: counsellor.councellor_name,
        }));
        setOptions(counsellorOptions);
      })
      .catch((err) => console.log(err));
  }, []);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: AddVendorGroupSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const counsellorIdsString = values.counsellor_ids.join(",");
        const payload = {
          group_name: values.group_name,
          counsellor_ids: counsellorIdsString,
          counsellorgroup_id: Id,
        };

        await APIClient.get("sanctum/csrf-cookie");
        const response = await APIClient.post(
          "api/update-counsellorgroup",
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        toast.success(response.data.msg);
        setTimeout(() => {
          navigate("/Counsellor-group-list");
        }, 2000);
      } catch (error) {
        console.error("Error adding group:", error);
        toast.error("Failed to add Counsellor group");
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <>
      <ToastContainer />
      <PageLayout>
        <Row>
          <Col xl={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Breadcrumb title={"Counsellor Group"} />
              <Link to="/Counsellor-group-list">
                <Button className="btn btn-primary common-btn">
                  View Counsellor Group
                </Button>
              </Link>
            </div>
          </Col>
          <Form onSubmit={handleSubmit}>
            <Col xl={12}>
              <Box className="mc-card">
                <Row xs={1} md={1} xl={1}>
                  <Col xl={6}>
                    <div style={{ marginTop: "0rem" }}>
                      <CardHeader title="Group Name" />
                      <TextField
                        id="outlined-required"
                        placeholder="Enter Group Name"
                        name="group_name"
                        onChange={handleChange}
                        value={values.group_name}
                        onBlur={handleBlur}
                        sx={{ width: "100%" }}
                      />
                      {errors.group_name && touched.group_name ? (
                        <p className="text-danger" style={{ fontSize: "13px" }}>
                          {errors.group_name}
                        </p>
                      ) : null}
                    </div>
                  </Col>

                  <Col xl={6}>
                    <div style={{ marginTop: "0rem" }}>
                      <CardHeader title="Select Counsellor" />
                      <Select
                        options={options}
                        value={options.filter((option) =>
                          values.counsellor_ids.includes(option.value)
                        )}
                        name="counsellor_ids"
                        onChange={(selectedOptions) =>
                          setFieldValue(
                            "counsellor_ids",
                            selectedOptions.map((option) => option.value)
                          )
                        }
                        isMulti
                      />
                      {errors.counsellor_ids && touched.counsellor_ids ? (
                        <p className="text-danger" style={{ fontSize: "13px" }}>
                          {errors.counsellor_ids}
                        </p>
                      ) : null}
                    </div>
                  </Col>

                  <Col xl={3}>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "start",
                        alignItems: "center",
                        marginTop: "1.25rem",
                      }}
                    >
                      <Button
                        type="submit"
                        className="btn btn-primary common-btn"
                      >
                        Save
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Box>
            </Col>
          </Form>
        </Row>
      </PageLayout>
    </>
  );
};

export default EditCounsellorGroupEdit;
