import React from "react";
import { EnquiryProvider } from "../../context/Enquiry";
import ManagerPageLayout from "../../layouts/ManagerPageLayout";
import ControlManagerDashbord from "./ControlManagerDashbord";

export default function ManagerDashbord() {

  return (
    <>
      <EnquiryProvider>
        <ManagerPageLayout>
          <ControlManagerDashbord />
        </ManagerPageLayout>
      </EnquiryProvider>
    </>
  );
}