import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import APIClient from "../../APIClient";
import Swal from "sweetalert2";
import { AddUniversitySchema } from "../schemas/AddUniversitySchema";
import ListLoader from "../../components/SkeletonLoaderComponent/ListLoader";

export default function AddUniversity() {
  const [Universitydata, setUniversitydata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [Universitydatadelete, setUniversitydatadelete] = useState();
  const navigate = useNavigate();
  const initialValues = {
    univercity_name: "",
  };

  const fetchUniversitydata = () => {
    setLoading(true);
    APIClient.get("sanctum/csrf-cookie");
    APIClient.get("api/univercity-list", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        setLoading(false);
        const data = res.data.univercity_list;
        setUniversitydata(data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: AddUniversitySchema,
      onSubmit: (values, { setSubmitting }) => {
        APIClient.get("sanctum/csrf-cookie")
          .then((response) => {
            return APIClient.post("api/add-univercity", values, {
              headers: {
                "Content-Type": "multipart/form-data",
                Accept: "multipart/form-data",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            });
          })
          .then((addUniversity) => {
            toast.success(addUniversity.data.msg);
            setTimeout(() => {
              fetchUniversitydata();
            }, 2000);
          })
          .catch((error) => {
            console.error("Error adding University:", error);
          })
          .finally(() => {
            setSubmitting(false);
          });
      },
    });

  const handeldelete = async (universityId) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    Swal.fire({
      title: "Are you sure?",
      text: "You won't to Delete University !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((delitem) => {
      if (delitem.isConfirmed) {
        APIClient.get("sanctum/csrf-cookie").then((response) => {
          APIClient.get(`api/delete-univercity/${universityId}`).then(
            (deluniversityResponse) => {
              setUniversitydatadelete(deluniversityResponse);
              fetchUniversitydata();
            }
          );
        });
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
      } else if (delitem.dismiss === Swal.DismissReason.cancel) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Delete Cancel :)",
          icon: "error",
        });
      }
    });
  };

  useEffect(() => {
    fetchUniversitydata();
  }, []);

  useEffect(() => {
    if (
      !localStorage.getItem("token") ||
      localStorage.getItem("token") === null
    ) {
      navigate("/login");
    }
  }, [navigate]);
  return (
    <>
      <ToastContainer autoClose={1000} />
      <PageLayout>
        <Row>
          <Col xl={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Breadcrumb title={"University"}></Breadcrumb>
            </div>
          </Col>
          <Col xl={7}>
            <Box className="mc-card">
              <Form onSubmit={handleSubmit}>
                <Row xs={1} md={1} xl={1}>
                  <Col xl={12}>
                    <div style={{ marginTop: "1rem" }}>
                      <CardHeader title="university Name" />
                      <FormControl sx={{ width: "100%" }}>
                        <TextField
                          id="outlined-required"
                          placeholder="Enter university Name"
                          name="univercity_name"
                          onChange={handleChange}
                          value={values.univercity_name}
                          onBlur={handleBlur}
                          sx={{ width: "100%" }}
                        />
                        {errors.univercity_name && touched.univercity_name ? (
                          <p
                            className="text-danger"
                            style={{ fontSize: "13px" }}
                          >
                            {errors.univercity_name}
                          </p>
                        ) : null}
                      </FormControl>
                    </div>
                  </Col>

                  <Col xl={5}>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "start",
                        alignItems: "center",
                        marginTop: "0.25rem",
                      }}
                    >
                      <Button
                        type="submit"
                        className="btn btn-primary common-btn"
                      >
                        Add University
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Box>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col xl={12}>
            <Box className="mc-card p-0">
              <Row xs={1} md={1} xl={1}>
                <Col xl={12}>
                  <Box className="mc-card">
                    <CardHeader title="University List" />
                    <div className="container-fluid">
                      <div className="row">
                        <ListLoader load={loading} layout={2}>
                          <div className="col-12">
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">SL</th>
                                  <th scope="col">University Name</th>
                                  <th scope="col">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Universitydata.map((item, index) => (
                                  <tr key={index}>
                                    <th scope="row">{index + 1}</th>
                                    <td>{item.univercity_name}</td>
                                    <td
                                      style={{
                                        display: "flex",
                                        justifyContent: "start",
                                      }}
                                    >
                                      <Link
                                        to={`/edituniversity/${item.id}`}
                                        style={{
                                          width: "2rem",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          marginRight: "1.6rem",
                                        }}
                                        className="btn btn-success"
                                      >
                                        <i className="fas fa-edit"></i>
                                      </Link>

                                      <button
                                        type="button"
                                        onClick={() => handeldelete(item.id)}
                                        style={{
                                          width: "2rem",
                                          fontSize: "0.6rem",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                        className="btn btn-danger"
                                      >
                                        <i className="fas fa-trash"></i>
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </ListLoader>
                      </div>
                    </div>
                  </Box>
                </Col>
              </Row>
            </Box>
          </Col>
        </Row>
      </PageLayout>
    </>
  );
}
