import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { Box } from "../../components/elements";
import { Column } from "../../Constant";

function ReusableFolloupMissed({ profileleaddata }) {
  const location = useLocation();
  const vendorPath = location.pathname.startsWith("/vendor");
  const managerPath = location.pathname.startsWith("/manager");
  return (
    <>
      <Row>
        <Col xl="12" className="text-center py-5">
          <h1>Follow Up Missed </h1>
        </Col>
      </Row>
      <Row>
        {profileleaddata &&
          profileleaddata.map((item, index) => (
            <Col xl={12} className="mb-3" key={item.profile_email}>
              <Box className="mc-card">
                <div className="container-fluid">
                  <div className="row d-flex align-items-center">
                    <div className={vendorPath ? "col-12" : "col-11"}>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            {Column?.map((item, index) => (
                              <th scope="col" key={item + index}>
                                {item}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{item.profile_name}</td>
                            <td>{item.councellor_name}</td>
                            <td>{item.vendor_code}</td>
                            <td>{item.last_lead_status}</td>
                            <td>{item.last_call_information}</td>
                            <td>{item.last_call_type}</td>
                            <td>{item.last_call_note}</td>
                            <td>{item.next_followup_date}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {!vendorPath && !managerPath && (
                      <div className="col-1">
                        <Link
                          type="button"
                          className="common-btn"
                          id="common-btn"
                          variant="primary"
                          to={`/counsellor/new-enquiryedit/${item.id}`}
                        >
                          Take Action
                        </Link>
                      </div>
                    )}
                    {managerPath && (
                      <div className="col-1">
                        <Link
                          type="button"
                          className="common-btn"
                          id="common-btn"
                          variant="primary"
                          to="#"
                        >
                          Journey History
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </Box>
            </Col>
          ))}
      </Row>
    </>
  );
}

export default ReusableFolloupMissed;
