import React, { useContext, useState, useRef, useEffect } from "react";
import { ProfileDropdown } from "../components/header";
import { Button, Section, Box, Input } from "../components/elements";
import { DrawerContext } from "../context/Drawer";
import { ThemeContext } from "../context/Themes";
import { Logo } from "../components";
import data from "../data/master/counsellorheader.json";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useNavigate } from "react-router-dom";
import APIClient from "../APIClient";

export default function CounsellorHeader() {
  const userid = localStorage.getItem("user_id");
  const navigate = useNavigate();
  const { drawer, toggleDrawer } = useContext(DrawerContext);
  const { theme, toggleTheme } = useContext(ThemeContext);
  const searchRef = useRef();
  const [scroll, setScroll] = useState("fixed");
  const [search, setSearch] = useState("");

  const admin = JSON.parse(localStorage.getItem("admin"));
  const [profileleaddata, setProfileLeadData] = useState([]);
  const fetchNotification = async () => {
    try {
      await APIClient.get("sanctum/csrf-cookie");
      const response = await APIClient.post(
        `api/duplicate-notification`,
        { counseller_id: userid },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response) {
        setProfileLeadData([...response?.data?.duplicate_list]);
        console.log("response", profileleaddata);
      }
      console.log("result", response.data);
    } catch (error) {
      console.error("Error fetching admin dashbord data", error);
    }
  };
  useEffect(() => {
    fetchNotification();
  }, []);
  window.addEventListener("scroll", () => {
    if (window.pageYOffset > 0) setScroll("sticky");
    else setScroll("fixed");
  });

  document.addEventListener("mousedown", (event) => {
    if (!searchRef.current?.contains(event.target)) {
      setSearch("");
    }
  });
  return (
    <>
      <Section as="header" className={`mc-header ${scroll}`}>
        <Logo
          src={data?.logo.src}
          alt={data?.logo.alt}
          name={data?.logo.name}
          href={data?.logo.path}
        />
        <Box className="mc-header-group">
          <Box className="mc-header-left">
            <Button
              icon={data?.search.icon}
              className="mc-header-icon search"
              onClick={() => setSearch("show")}
            />
            <Button
              icon={drawer ? "menu_open" : "menu"}
              className="mc-header-icon toggle"
              onClick={toggleDrawer}
            />
          </Box>
          <Box className="mc-header-right">
            <ProfileDropdown
              name={"Welcome (Counsellor)"}
              image={data.profile.image}
              dropdown={data.profile.dropdown}
            />
          </Box>
        </Box>
        <Box>
          <Button
            className={"position-relative"}
			isCount={profileleaddata?.length === 0 ? false:true}
            count={profileleaddata?.length}
            icon={<NotificationsIcon />}
            onClick={() => navigate(`/counsellor/notification/${userid}`)}
          />
        </Box>
      </Section>
    </>
  );
}
