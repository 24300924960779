import React, { useContext, useEffect, useState } from "react";
import { MultipleMenu, Logout } from "../components/sidebar";
import { DrawerContext } from "../context/Drawer";
import Section from "../components/elements/Section";
import data from "../data/master/ManagerSidebar.json";
import { Row, Col } from "react-bootstrap";
import APIClient from "../APIClient";
import { Link, useNavigate, useParams } from "react-router-dom";
import { TotalEnquiryContext } from "../context/TotalEnquiryContext";

export default function ManagerSidebar() {
  const navigate = useNavigate();
  const [show, setShow] = useState(true);
  const { drawer } = useContext(DrawerContext);
  const [leadStatus, setLeadStatus] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const { currentDate, setCurrentDate } = useContext(TotalEnquiryContext);
  const [newenquerylength, setNewenquerylength] = useState(0);
  const [followmissedlength, setFollowmissedlength] = useState(0);
  const [leadstatuscounthot, setLeadstatuscounthot] = useState(0);
  const [leadstatuscountcold, setLeadstatuscountcold] = useState(0);
  const [leadstatuscountsupercold, setLeadstatuscountsupercold] = useState(0);
  const [leadstatuscounttrash, setLeadstatuscounttrash] = useState(0);

  const memberId = localStorage.getItem("user_id");
  const id = 1;

  const fetchleadstatuscounthot = () => {
    const token = localStorage.getItem("token");

    if (token) {
      APIClient.get("sanctum/csrf-cookie")
        .then(() => {
          return APIClient.post(
            `api/lead-status-count`,
            { lead_status_id: 2, manager_id: memberId },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        })
        .then((res) => {
          const data = res.data;
          setLeadstatuscounthot(data.manager_lead_status_count);
        });
    }
  };
  const fetchleadstatuscountcold = () => {
    const token = localStorage.getItem("token");

    if (token) {
      APIClient.get("sanctum/csrf-cookie")
        .then(() => {
          return APIClient.post(
            `api/lead-status-count`,
            { lead_status_id: 3, manager_id: memberId },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        })
        .then((res) => {
          const data = res.data;
          setLeadstatuscountcold(data.manager_lead_status_count);
        });
    }
  };
  const fetchleadstatuscountsupercold = () => {
    const token = localStorage.getItem("token");

    if (token) {
      APIClient.get("sanctum/csrf-cookie")
        .then(() => {
          return APIClient.post(
            `api/lead-status-count`,
            { lead_status_id: 4, manager_id: memberId },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        })
        .then((res) => {
          const data = res.data;
          setLeadstatuscountsupercold(data.manager_lead_status_count);
        });
    }
  };
  const fetchleadstatuscounttrash = () => {
    const token = localStorage.getItem("token");

    if (token) {
      APIClient.get("sanctum/csrf-cookie")
        .then(() => {
          return APIClient.post(
            `api/lead-status-count`,
            { lead_status_id: 5, manager_id: memberId },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        })
        .then((res) => {
          const data = res.data;
          setLeadstatuscounttrash(data.manager_lead_status_count);
        });
    }
  };

  const fetchnewenqueryprofiledatalength = () => {
    const token = localStorage.getItem("token");
    if (token) {
      APIClient.get("sanctum/csrf-cookie")
        .then(() => {
          return APIClient.get(
            `api/managerwise-list-lead-profile/${memberId}/${id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        })
        .then((res) => {
          const data = res.data;
          setNewenquerylength(data.profile_list.length);
        })
        .catch((err) => {
          console.error(err);
          if (err.response && err.response.status === 401) {
            console.log("Token might be invalid or expired");
          }
        });
    } else {
      console.log("No token found");
    }
  };
  const fetchfollowmissedprofiledatalength = () => {
    const token = localStorage.getItem("token");
    if (token) {
      APIClient.get("sanctum/csrf-cookie")
        .then(() => {
          return APIClient.post(
            `api/follow-up-missed`,
            { manager_id: memberId },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        })
        .then((res) => {
          const data = res.data;
          setFollowmissedlength(data.no_of_miss_followup);
        })
        .catch((err) => {
          console.error(err);
          if (err.response && err.response.status === 401) {
            console.log("Token might be invalid or expired");
          }
        });
    } else {
      console.log("No token found");
    }
  };

  const fetchLeadStatus = () => {
    const token = localStorage.getItem("token");
    if (token) {
      APIClient.get("sanctum/csrf-cookie")
        .then(() => {
          return APIClient.get("api/leadstatus-list", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        })
        .then((res) => {
          const data = res.data.leadstatus_list;
          setLeadStatus(data);
        })
        .catch((err) => {
          console.error(err);
          if (err.response && err.response.status === 401) {
            console.log("Token might be invalid or expired");
          }
        });
    } else {
      console.log("No token found");
    }
  };

  const fetchProfilesByDate = () => {
    const token = localStorage.getItem("token");
    if (token && currentDate) {
      APIClient.post(
        `api/date-wise-profile?date=${currentDate}`,
        { manager_id:memberId},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          setProfiles(res.data.profile_list);
        })
        .catch((err) => {
          console.error(err);
          if (err.response && err.response.status === 401) {
            console.log("Token might be invalid or expired");
          }
        });
    }
  };

  const getLeadStatusName = (id) => {
    const status = leadStatus.find((status) => status.id === id);
    return status ? status.lead_status_name : "Unknown";
  };

  useEffect(() => {
    fetchleadstatuscounthot();
    fetchleadstatuscountcold();
    fetchleadstatuscountsupercold();
    fetchleadstatuscounttrash();
  }, []);

  useEffect(() => {
    fetchLeadStatus();
    fetchnewenqueryprofiledatalength();
    fetchfollowmissedprofiledatalength();
  }, []);

  useEffect(() => {
    if (currentDate) {
      fetchProfilesByDate();
    }
  }, [currentDate]);

  return (
    <>
      <Section
        as="aside"
        className={`mc-sidebar thin-scrolling ${drawer ? "active" : ""}`}
      >
        <MultipleMenu data={data?.navs} />
        <div className={show ? "d-block" : "d-none"}>
          <Row
            className="d-flex gray-200 row mx-1 mt-0 rounded"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/manager/lead-status/1")}
          >
            <Col className="m-0 p-2" md="8">
              New Enquiries
            </Col>
            <Col md="4" className="m-0 p-2 text-end">
              <span className="bg-secondary p-1 text-white">
                {newenquerylength}
              </span>
            </Col>
          </Row>
          <div className="row m-0 my-3">
            {leadStatus.map(
              (item) =>
                item.id !== 1 && (
                  <div className="col-6 m-0 p-1" key={item.id}>
                    <Link
                      to={`/manager/lead-status/${item.id}`}
                      className="btn border w-100 text-dark"
                    >
                      {item.lead_status_name}
                      {item.id === 2 ? ` (${leadstatuscounthot})` : ""}
                      {item.id === 3 ? ` (${leadstatuscountcold})` : ""}
                      {item.id === 4 ? ` (${leadstatuscountsupercold})` : ""}
                      {item.id === 5 ? ` (${leadstatuscounttrash})` : ""}
                    </Link>
                  </div>
                )
            )}
          </div>

          <Row
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/manager/followupmissed")}
            className="d-flex gray-200 row mx-1 mt-3 rounded"
          >
            <Col className="m-0 p-2" md="9">
              Follow UP Missed
              <br />
              <small>(Only Hot & Cold Data)</small>
            </Col>
            <Col
              md="3"
              className="m-0 p-2 text-end d-flex justify-content-end align-items-center"
            >
              <span className="bg-secondary p-1 text-white">
                {followmissedlength}
              </span>
            </Col>
          </Row>

          <Row
            style={{ cursor: "pointer" }}
            className="d-flex gray-200 row mx-1 mt-3 mb-2 rounded"
          >
            <Col className="m-0 p-2" md="12">
              Follow UP Date
              <br />
            </Col>
            <Col
              md="12"
              className="m-0 p-2 pt-0 text-end d-flex justify-align-center align-items-center"
            >
              <input
                className="form-control"
                name="followupdate"
                type="date"
                onChange={(e) => setCurrentDate(e.target.value)}
              />
            </Col>
          </Row>
          {currentDate !== "" && (
            <div>
              <span>Selected Date : {currentDate}</span>
            </div>
          )}

          {profiles.length > 0 ? (
            profiles.map((item) => (
              <Link
                key={item.id}
                className="w-100"
                to={`/manager/journeyhistory/${item.id}`}
              >
                <Col
                  md="12"
                  className="d-flex align-items-center py-1"
                  id="latest-lead-profile-item"
                >
                  <span className="title">{item.profile_name}</span>
                  <span
                    className="badge badge-dark mx-2"
                    style={{ fontSize: "8px" }}
                  >
                    {getLeadStatusName(item.lead_status_id)}
                  </span>
                  <span className="title">{currentDate}</span>
                </Col>
              </Link>
            ))
          ) : (
            <Col
              md="12"
              className="d-flex justify-content-center py-1"
              id="no-records-found"
            >
              <span className="title text-center">No records found</span>
            </Col>
          )}
        </div>
        <Logout data={data?.button} />
      </Section>
    </>
  );
}
