import React, { useEffect, useState } from "react";
import CounsellorPageLayout from "../../layouts/CousellorPageLayout";
import ReusableNewenqiry from "./fourEnq/ReusableNewenqiry";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import APIClient from "../../APIClient";
import VendorpageLayout from "../../layouts/VendorpageLayout";
import ManagerPageLayout from "../../layouts/ManagerPageLayout";
import PageLayout from "../../layouts/PageLayout";

function NewEnquiry() {
  const location = useLocation();
  const counsellorPath = location.pathname.startsWith("/counsellor");
  const vendorPath = location.pathname.startsWith("/vendor");
  const managerPath = location.pathname.startsWith("/manager");
  const adminPath = !vendorPath && !managerPath && !counsellorPath;

  const [profileleaddata, setProfileleaddata] = useState([]);
  const memberId = localStorage.getItem("user_id");
  const navigate = useNavigate();
  const { id } = useParams();

  const fetchcounsellorleadprofile = async () => {
    if (adminPath) {
      APIClient.get("sanctum/csrf-cookie").then((response) => {
        APIClient.get(`api/adminwise-list-lead-profile/${id}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }).then((getleadadminprofilelist) => {
          setProfileleaddata([...getleadadminprofilelist.data.profile_list]);
        });
      });
    }
    if (counsellorPath) {
      APIClient.get("sanctum/csrf-cookie").then((response) => {
        APIClient.get(
          `api/counsellorwise-list-lead-profile/${memberId}/${id}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        ).then((res) => {
          // setProfileleaddata(
          //   getleadcounsellorprofilelist?.data?.profile_list &&
          //     getleadcounsellorprofilelist.data?.profile_list
          // );
          setProfileleaddata(res.data.profile_list)
          console.log(profileleaddata);
        });
      });
    }
    if (vendorPath) {
      APIClient.get("sanctum/csrf-cookie").then((response) => {
        APIClient.get(`api/vendorwise-list-lead-profile/${memberId}/${id}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }).then((response) => {
          setProfileleaddata([...response.data.profile_list]);
        });
      });
    }
    if (managerPath) {
      APIClient.get("sanctum/csrf-cookie").then((response) => {
        APIClient.get(`api/managerwise-list-lead-profile/${memberId}/${id}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }).then((response) => {
          setProfileleaddata([...response.data.profile_list]);
        });
      });
    }
  };

  useEffect(() => {
    fetchcounsellorleadprofile();
  }, [id]);

  useEffect(() => {
    if (adminPath) {
      if (
        !localStorage.getItem("token") ||
        localStorage.getItem("token") === null
      ) {
        navigate("/");
      }
    }
    if (counsellorPath) {
      if (
        !localStorage.getItem("token") ||
        localStorage.getItem("token") === null
      ) {
        navigate("/counsellor");
      }
    }
    if (vendorPath) {
      if (
        !localStorage.getItem("token") ||
        localStorage.getItem("token") === null
      ) {
        navigate("/vendor");
      }
    }
    if (managerPath) {
      if (
        !localStorage.getItem("token") ||
        localStorage.getItem("token") === null
      ) {
        navigate("/manager");
      }
    }
  }, []);
  return (
    <>
      {adminPath ? (
        <PageLayout>
          <ReusableNewenqiry
            profileleaddata={profileleaddata && profileleaddata}
          />
        </PageLayout>
      ) : counsellorPath ? (
        <CounsellorPageLayout>
          <ReusableNewenqiry
            profileleaddata={profileleaddata && profileleaddata}
          />
        </CounsellorPageLayout>
      ) : vendorPath ? (
        <VendorpageLayout>
          <ReusableNewenqiry
            profileleaddata={profileleaddata && profileleaddata}
          />
        </VendorpageLayout>
      ) : (
        managerPath && (
          <ManagerPageLayout>
            <ReusableNewenqiry
              profileleaddata={profileleaddata && profileleaddata}
            />
          </ManagerPageLayout>
        )
      )}
    </>
  );
}

export default NewEnquiry;
