import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Item, Anchor, Text } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";
import { Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import APIClient from "../../APIClient";
import Swal from "sweetalert2";
import ListLoader from "../../components/SkeletonLoaderComponent/ListLoader";

export default function CounsellorList() {
  const navigate = useNavigate();
  const { Id } = useParams();
  const [counsellordata, setCounsellordata] = useState();
  const [counsellordatadelete, setcounsellordatadelete] = useState();
  const [loading, setLoading] = useState(false);

  const fetchcounsellordata = async () => {
    setLoading(true);
    APIClient.get("sanctum/csrf-cookie").then((response) => {
      APIClient.get("api/councellor-list", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((getcouncellorResponse) => {
          setLoading(false);
          setCounsellordata(getcouncellorResponse);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    });
  };

  const handleStatus = async (id, status) => {
    setLoading(true);
    try {
      const csrfResponse = await APIClient.get("sanctum/csrf-cookie");
      if (csrfResponse.status === 204) {
        await APIClient.post(
          "api/status-update-councellor",
          { id, status: status === 1 ? 0 : 1 },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        setLoading(false);
        fetchcounsellordata();
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching group data:", error);
    }
  };

  const handeldelete = async (counsellorId) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    Swal.fire({
      title: "Are you sure?",
      text: "You won't to Delete Counsellor !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((delitem) => {
      if (delitem.isConfirmed) {
        APIClient.get("sanctum/csrf-cookie").then((response) => {
          APIClient.get(`api/delete-councellor/${counsellorId}`).then(
            (delcouncellorResponse) => {
              setcounsellordatadelete(delcouncellorResponse);
              fetchcounsellordata();
            }
          );
        });
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
      } else if (delitem.dismiss === Swal.DismissReason.cancel) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Delete Cancel :)",
          icon: "error",
        });
      }
    });
    // APIClient.get("sanctum/csrf-cookie").then((response) => {
    //   APIClient.get(`api/delete-councellor/${counsellorId}`).then(
    //     (delcouncellorResponse) => {
    //       setcounsellordatadelete(delcouncellorResponse);

    //       toast.success(delcouncellorResponse.data.msg);
    //       setTimeout(() => {
    //         fetchcounsellordata();
    //     }, 2000);
    //     }
    //   );
    // });
  };

  useEffect(() => {
    fetchcounsellordata();
  }, []);

  useEffect(() => {
    if (
      !localStorage.getItem("token") ||
      localStorage.getItem("token") === null
    ) {
      navigate("/login");
    }
  }, []);
  return (
    <>
      <ToastContainer autoClose={1000} />
      <PageLayout>
        <Row>
          <Col xl={12}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Breadcrumb title={"Counsellor List"}></Breadcrumb>
              <Link to="/add-counsellor">
                <Button className="common-btn">ADD Counsellor</Button>
              </Link>
            </div>
          </Col>
          <Col xl={12}>
            <Box className="mc-card">
              <Row xs={1} md={1} xl={1}>
                <Col xl={12}>
                  <Box className="mc-card border-0 p-0">
                    <CardHeader title="Counsellor List" />
                    <div className="container-fluid">
                      <div className="row">
                        <ListLoader load={loading}>
                          <div className="col-12 overflow-auto">
                            <table className="table table-bordered ">
                              <thead>
                                <tr>
                                  <th scope="col">S.no</th>
                                  <th scope="col">Counsellor Id</th>
                                  <th scope="col"> Name</th>
                                  <th scope="col"> Email</th>
                                  <th scope="col"> Phone</th>
                                  <th scope="col"> Photo</th>
                                  <th scope="col"> Address</th>
                                  <th scope="col"> Username</th>
                                  <th scope="col">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {counsellordata &&
                                  counsellordata.data.councellor_list.map(
                                    (item, index) => (
                                      <tr key={item.id}>
                                        <td scope="row">{index + 1}</td>
                                        <td>{item.id}</td>
                                        <td>{item.councellor_name}</td>
                                        <td>{item.councellor_email}</td>
                                        <td>{item.councellor_phone}</td>
                                        <td>
                                          <img
                                            src={item.councellor_photo}
                                            width="100"
                                            height="100"
                                            alt=""
                                          />
                                        </td>
                                        <td>{item.councellor_address}</td>
                                        <td>{item.councellor_username}</td>

                                        <td style={{ verticalAlign: "middle" }}>
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "start",
                                              alignItems: "center",
                                            }}
                                          >
                                            <div className="form-check form-switch">
                                              <input
                                                checked={
                                                  item.status === 1
                                                    ? true
                                                    : false
                                                }

                                                onClick={() =>
                                                  handleStatus(
                                                    item.id,
                                                    item.status
                                                  )
                                                }
                                                className="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                id="flexSwitchCheckDefault"
                                              />
                                            </div>

                                            <Link
                                              to={`/editCounsellor/${item.id}`}
                                              style={{
                                                width: "2rem",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                marginRight: "1.6rem",
                                              }}
                                              className="btn btn-success"
                                            >
                                              <i className="fas fa-edit"></i>
                                            </Link>

                                            <button
                                              type="button"
                                              onClick={() =>
                                                handeldelete(item.id)
                                              }
                                              style={{
                                                width: "2rem",
                                                fontSize: "0.6rem",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                              }}
                                              className="btn btn-danger"
                                            >
                                              <i className="fas fa-trash"></i>
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  )}
                              </tbody>
                            </table>
                          </div>
                        </ListLoader>
                      </div>
                    </div>
                  </Box>
                </Col>
              </Row>
            </Box>
          </Col>
        </Row>
      </PageLayout>
    </>
  );
}
