import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb, DivideTitle } from "../../components";
import { CardHeader } from "../../components/cards";
import { LabelField, IconField, LabelTextarea } from "../../components/fields";
import { Box, Item, Anchor, Text, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";
import { Link, useNavigate } from "react-router-dom";
import APIClient from "../../APIClient";
import { toast, ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
import ListLoader from "../../components/SkeletonLoaderComponent/ListLoader";

export default function VendorList() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [vendordata, setVendordata] = useState();
  const [vendordatadelete, setVendordatadelete] = useState();

  const fetchvendordata = async () => {
    setLoading(true);
    APIClient.get("sanctum/csrf-cookie").then((response) => {
      APIClient.get("api/leadvendor-list", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((getvendorResponse) => {
          setLoading(false);
          setVendordata(getvendorResponse);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    });
  };

  const handleStatus = (id, stat) => {
    setLoading(true);
    APIClient.get("sanctum/csrf-cookie").then((response) => {
      APIClient.post(
        "api/status-update-leadvendor",
        { id, status: stat === "Y" ? "N" : "Y" },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          setLoading(false);
          fetchvendordata();
          toast.success(res.msg);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    });
  };

  const handeldelete = async (vendorId) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    Swal.fire({
      title: "Are you sure?",
      text: "You won't to Delete Lead Vendor !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((delitem) => {
      if (delitem.isConfirmed) {
        APIClient.get("sanctum/csrf-cookie").then((response) => {
          APIClient.get(`api/delete-leadvendor/${vendorId}`).then(
            (delvendorResponse) => {
              setVendordatadelete(delvendorResponse);
              fetchvendordata();
            }
          );
        });
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
      } else if (delitem.dismiss === Swal.DismissReason.cancel) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Delete Cancel :)",
          icon: "error",
        });
      }
    });
  };

  useEffect(() => {
    fetchvendordata();
  }, []);

  useEffect(() => {
    if (
      !localStorage.getItem("token") ||
      localStorage.getItem("token") === null
    ) {
      navigate("/login");
    }
  }, []);

  return (
    <>
      <ToastContainer autoClose={1000} />
      <PageLayout>
        <Row>
          <Col xl={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Breadcrumb title={"Lead Vendor"}></Breadcrumb>
              <Link to="/add-vendor">
                <Button className="btn btn-primary common-btn">
                  Add Vendor
                </Button>
              </Link>
            </div>
          </Col>
          <Col xl={12}>
            <Box className="mc-card p-0">
              <Row xs={1} md={1} xl={1}>
                <Col xl={12}>
                  <Box className="mc-card">
                    <CardHeader title="Vendor List" />
                    <div className="container-fluid">
                      <div className="row">
                        <ListLoader load={loading}>
                          <div className="col-12" style={{overflow:"auto"}}>
                            <table
                              className="table table-responsive table-bordered w-100"
                              
                            >
                              <thead>
                                <tr>
                                  <th scope="col">SL</th>
                                  <th scope="col">Vendor Id</th>
                                  <th scope="col">Vendor Name</th>
                                  <th scope="col"> Email</th>
                                  <th scope="col"> Phone</th>
                                  <th scope="col"> Username</th>
                                  <th scope="col">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {vendordata &&
                                  vendordata.data.leadvendor_list.map(
                                    (item, index) => (
                                      <tr key={item.id}>
                                        <th scope="row">{index + 1}</th>
                                        <td>{item.id}</td>
                                        <td>{item.name}</td>
                                        <td>{item.email}</td>
                                        <td>{item.phone}</td>
                                        <td>{item.username}</td>

                                        <td style={{ verticalAlign: "middle" }}>
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "start",
                                              alignItems: "center",
                                            }}
                                          >
                                            <div className="form-check form-switch">
                                              <input
                                                checked={
                                                  item.stat === "Y"
                                                    ? true
                                                    : false
                                                }
                                                onClick={() =>
                                                  handleStatus(
                                                    item.id,
                                                    item.stat
                                                  )
                                                }
                                                className="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                id="flexSwitchCheckDefault"
                                              />
                                            </div>
                                            <Link
                                              to={`/edit-vendor/${item.id}`}
                                              style={{
                                                width: "2rem",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                marginRight: "1.6rem",
                                              }}
                                              className="btn btn-success"
                                            >
                                              <i className="fas fa-edit"></i>
                                            </Link>

                                            <button
                                              type="button"
                                              onClick={() =>
                                                handeldelete(item.id)
                                              }
                                              style={{
                                                width: "2rem",
                                                fontSize: "0.6rem",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                              }}
                                              className="btn btn-danger"
                                            >
                                              <i className="fas fa-trash"></i>
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  )}
                              </tbody>
                            </table>
                          </div>
                        </ListLoader>
                      </div>
                    </div>
                  </Box>
                </Col>
              </Row>
            </Box>
          </Col>
        </Row>
      </PageLayout>
    </>
  );
}
