import React from "react";
import { Row, Col } from "react-bootstrap";
import { Box } from "../../../components/elements";
import { Link, useLocation, useParams } from "react-router-dom";
import { Column } from "../../../Constant";
import { transform } from "../../../utills";

function ReusableNewenqiry({ profileleaddata }) {
  const { id } = useParams();

  const location = useLocation();

  const vendorPath = location.pathname.startsWith("/vendor");
  const managerPath = location.pathname.startsWith("/manager");
  const counsellorPath = location.pathname.startsWith("/counsellor");
  const managerLeadStatus1 = location.pathname === "/manager/lead-status/1";
  const showLeadHistoryButton =
    ["2", "3", "4", "5"].includes(id) && !managerLeadStatus1;
  const adminPath =
    !vendorPath && !managerPath && !counsellorPath && !managerLeadStatus1;
  return (
    <>
      <Row>
        <Col xl="12" className="text-center py-5">
          <h1>
            {id === "1"
              ? "New Enquiry"
              : id === "2"
              ? "Hot 24"
              : id === "3"
              ? "Cold 24"
              : id === "4"
              ? "Super Cold 24"
              : id === "5" && "Thrash 24"}
          </h1>
        </Col>
      </Row>
      <Row>
        {profileleaddata &&
          profileleaddata.map((item, index) => (
            <Col xl={12} className="mb-3" key={index + 1}>
              <Box className="mc-card">
                <div className="container-fluid">
                  <div className="row d-flex align-items-center">
                    <div
                      className={
                        managerLeadStatus1 ||
                        vendorPath ||
                        (!managerPath && !counsellorPath && !adminPath)
                          ? "col-12"
                          : "col-11"
                      }
                    >
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            {Column?.map((item, index) => (
                              <th key={item + index} scope="col">
                                {item}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{item.profile_name}</td>
                            <td>{item.councellor_name}</td>
                            <td>{item.vendor_code}</td>
                            <td>{item.last_lead_status}</td>
                            <td>{item.last_call_information}</td>
                            <td>{item.last_call_type}</td>
                            <td>{item.last_call_note}</td>
                            <td>{item.next_followup_date}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {counsellorPath && (
                      <div className="col-1">
                        <Link
                          type="button"
                          className="common-btn"
                          id="common-btn"
                          variant="primary"
                          to={`/counsellor/new-enquiryedit/${item.id}`}
                        >
                          Take Action
                        </Link>
                      </div>
                    )}
                    {adminPath && !counsellorPath && (
                      <div className="col-1">
                        <Link
                          type="button"
                          className="common-btn"
                          id="common-btn"
                          variant="primary"
                          to={`/new-enquiryedit/${item.id}`}
                        >
                          Take Action
                        </Link>
                      </div>
                    )}

                    {managerPath && showLeadHistoryButton && (
                      <div className="col-1">
                        <Link
                          type="button"
                          className="common-btn"
                          id="common-btn"
                          variant="primary"
                          to={`/manager/journeyhistory/${item.id}`}
                        >
                          Journey History
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </Box>
            </Col>
          ))}
      </Row>
    </>
  );
}

export default ReusableNewenqiry;
