import React, { useEffect, useState } from 'react'
import CounsellorPageLayout from '../../layouts/CousellorPageLayout'
import ReusableNewenqiry from './fourEnq/ReusableNewenqiry'
import { useLocation, useNavigate, useParams } from "react-router-dom";
import APIClient from '../../APIClient';
import VendorpageLayout from '../../layouts/VendorpageLayout';
import ReusableFolloupMissed from './ReusableFolloupMissed';
import ManagerPageLayout from '../../layouts/ManagerPageLayout';



function FollowUpMissed() {
    const location = useLocation();
    const counsellorPath = location.pathname.startsWith("/counsellor");
    const vendorPath = location.pathname.startsWith("/vendor");
    const managerPath = location.pathname.startsWith("/manager");

    const [profileleaddata, setProfileleaddata] = useState([]);
    const memberId = localStorage.getItem('user_id');
    const navigate = useNavigate();
    const {id}=useParams();

    const fetchcounsellorleadprofile = async () => {
        if (counsellorPath) {
            APIClient.get("sanctum/csrf-cookie").then((response) => {
                APIClient.post(`api/miss-followup-list`,{counsellor_id:memberId}, {
                    headers: {
                        "Authorization": "Bearer " + localStorage.getItem('token')
                    },
                }).then((getleadcounsellormissedfollowupprofilelist) => {
                    setProfileleaddata([...getleadcounsellormissedfollowupprofilelist.data.miss_followup_list]);
                    console.log(profileleaddata);
                    
                });
            });

        }
        if (vendorPath) {
            APIClient.get("sanctum/csrf-cookie").then((response) => {
                APIClient.post(`api/miss-followup-list`,{vendor_id:memberId}, {
                    headers: {
                        "Authorization": "Bearer " + localStorage.getItem('token')
                    },
                }).then((response) => {
                    setProfileleaddata([...response.data.miss_followup_list]);

                });
            });
        }
        if (managerPath) {
            APIClient.get("sanctum/csrf-cookie").then((response) => {
                APIClient.post(`api/miss-followup-list`,{manager_id:memberId}, {
                    headers: {
                        "Authorization": "Bearer " + localStorage.getItem('token')
                    },
                }).then((getleadmanagermissedfollowupprofilelist) => {
                    setProfileleaddata([...getleadmanagermissedfollowupprofilelist.data.miss_followup_list]);
                    

                });
            });
        }
    };

    useEffect(() => {
        fetchcounsellorleadprofile();
    }, [id])

    useEffect(() => {
        if (counsellorPath) {

            if (!localStorage.getItem('token') || localStorage.getItem('token') === null) {
                navigate('/counsellor')
            }
        }
        if (vendorPath) {
            if (!localStorage.getItem('token') || localStorage.getItem('token') === null) {
                navigate('/vendor')
            }
        }
        if (managerPath) {
            if (!localStorage.getItem('token') || localStorage.getItem('token') === null) {
                navigate('/manager')
            }
        }
    }, [])
       
   
    return (
        <>
            {
                counsellorPath && <CounsellorPageLayout>
                    <ReusableFolloupMissed profileleaddata={profileleaddata && profileleaddata} />
                </CounsellorPageLayout>
            }
            {
                vendorPath && <VendorpageLayout>
                    <ReusableNewenqiry profileleaddata={profileleaddata && profileleaddata} />
                </VendorpageLayout>
            }
            {
                managerPath && <ManagerPageLayout>
                    <ReusableFolloupMissed profileleaddata={profileleaddata && profileleaddata} />
                </ManagerPageLayout>
            }



        </>
    )
}

export default FollowUpMissed
