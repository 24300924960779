import React, { createContext, useState } from "react";
import { useParams } from "react-router-dom";

export const TotalEnquiryContext = createContext();

export const TotalEnquiryProvider = ({ children }) => {
  const { lead_status_id } = useParams();
  const [counsellorId, setCounsellorId] = useState(null);
  const [vendorId, setVendorId] = useState(null);
  const [followUpList, setFollowUpList] = useState();
  const [currentDate, setCurrentDate] = useState("");
  const [followupTerm, setFollowupTerm] = useState("");
  const [profileIdList,setProfileIdList]=useState([]);
  const [topHeading,setTopHeading]=useState("");
  const [refresh,setRefresh]=useState(false);

  let payload = {
    lead_status_id: lead_status_id,
    counsellor_id: counsellorId,
    vendor_id: vendorId,
  };

  return (
    <TotalEnquiryContext.Provider
      value={{
        counsellorId,
        setCounsellorId,
        vendorId,
        setVendorId,
        payload,
        followUpList,
        setFollowUpList,
        currentDate,
        setCurrentDate,
        followupTerm,
        setFollowupTerm,
        setProfileIdList,
        profileIdList,
        topHeading,
        setTopHeading,
        setRefresh,
        refresh
      }}
    >
      {children}
    </TotalEnquiryContext.Provider>
  );
};
