import React, { useEffect, useState } from 'react'
import PageLayout from '../../../layouts/PageLayout'
import { Link, useLocation, useParams } from 'react-router-dom';
import { Row, Col } from "react-bootstrap";
import APIClient from '../../../APIClient';
import { Box } from '../../../components/elements';

function CallInformation() {
    const location = useLocation()
    const { call_information_name } = useParams();
    const [callinformation, setCallinformation] = useState();
    const adminPath = location.pathname.startsWith("/");
    const vendorPath = location.pathname.startsWith("/vendor");
    const managerPath = location.pathname.startsWith("/manager");
    const counsellorPath = location.pathname.startsWith("/counsellor");
    const managerLeadStatus1 = location.pathname === "/manager/lead-status/1";
    const memberId = localStorage.getItem('user_id');

    // fetch Lead Potential
    const fetchcall_information = async () => {
        APIClient.get("sanctum/csrf-cookie").then((response) => {
            APIClient.post(`api/call-information-wise-profile`,{
                call_information_name:call_information_name,
                vendor_id :memberId,
                counsellor_id : memberId,
                manager_id: memberId
            }, {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem('token')
                },
            }).then((res) => {
                setCallinformation(res);
                console.log(callinformation);
            }
            );
        });
    };
    useEffect(() => {
        fetchcall_information();
    }, [call_information_name])
    return (
        <>
            <PageLayout>
                <Row>
                    <Col xl="12" className="text-center py-5">
                        <h1>Profile List</h1>
                    </Col>
                </Row>
                {callinformation && callinformation?.data?.profile_list.map((item, index) => (
                    <Col xl={12} className="mb-3">
                        <Box className="mc-card">
                            <div className="container-fluid">
                                <div className="row">
                                <div className={managerLeadStatus1 || vendorPath || !managerPath && !counsellorPath && !adminPath ? "col-12" : "col-11"}>
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                <th scope="col">Sr. No</th>
                                                    <th scope="col"> Name</th>
                                                    <th scope="col"> Email</th>
                                                    <th scope="col"> Phone</th>
                                                    <th scope="col"> Experience</th>
                                                    <th scope="col"> City</th>
                                                    <th scope="col"> State</th>
                                                    <th scope="col"> Comment</th>
                                                </tr>
                                            </thead>
                                            <tbody>


                                                <tr key={index}>
                                                <th scope="row">{index + 1}</th>
                                                    <td>{item.profile_name}</td>
                                                    <td>{item.profile_email}</td>
                                                    <td>{item.profile_mobile}</td>
                                                    <td>{item.profile_experience}</td>
                                                    <td>{item.profile_city}</td>
                                                    <td>{item.profile_state}</td>
                                                    <td>{item.profile_messge}</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                    {adminPath && !counsellorPath && (
                                        <div className="col-1">
                                            <Link
                                                type="button"
                                                className="common-btn"
                                                id="common-btn"
                                                variant="primary"
                                                to={`/new-enquiryedit/${item.id}`}>
                                                Take Action
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Box>
                    </Col>
                ))}
            </PageLayout>
        </>
    )
}

export default CallInformation