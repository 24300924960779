import { Row, Col, Form } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import APIClient from "../../APIClient";
import { useEffect, useState } from "react";

export default function EditEnquiryStatus() {
    const { Id } = useParams();
    const navigate = useNavigate();
    const [initialValues, setInitialValues] = useState({
      enquerystatus_id: Id,
      enquery_status_name: ""   
    });

    useEffect(()=>{
        APIClient.get('sanctum/csrf-cookie')
        APIClient.get('api/single-enquerytatus/'+ Id,{
            headers: {
                "Authorization": "Bearer "+ localStorage.getItem('token')
            }
        }).then(res =>{
            setInitialValues({...initialValues, 
                enquery_status_name:res.data.enquerystatus_list[0].enquery_status_name
            })
        })
        .catch(err => console.log(err))
    },[])

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        enableReinitialize:true,
        onSubmit: (values, { setSubmitting }) => {
            APIClient.get('sanctum/csrf-cookie')
                .then(response => {
                    return APIClient.post('api/update-enquerytatus', values, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            "Accept": "multipart/form-data",
                            "Authorization": "Bearer " + localStorage.getItem('token')
                        }
                    });
                })
                .then(editLeadstatus => {
                    toast.success(editLeadstatus.data.msg);
                    setTimeout(() => {
                        navigate('/add-enquiry-status');
                    }, 2000);
                })
                .catch(error => {
                    console.error("Error updateing Enquery Status:", error);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        },
    })

    
    
      useEffect(() => {
        if (!localStorage.getItem('token') || localStorage.getItem('token') === null) {
          navigate('/login')
        }
      }, [])

      return (
        <>
      <ToastContainer autoClose={1000} />
      <PageLayout>
        <Row>
          <Col xl={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Breadcrumb title={"Update Enquiry Status"}></Breadcrumb>
            </div>
          </Col>
          <Col xl={7}>
            <Box className="mc-card">
              <Form onSubmit={handleSubmit}>
                <Row xs={1} md={1} xl={1}>
                  <Col xl={12}>
                    <div style={{ marginTop: "1rem" }}>
                      <CardHeader title="Enquiry Status Name" />
                      <FormControl sx={{ width: "100%" }}>
                      <TextField
                      id="outlined-required"
                      placeholder="Enter Enquery Status Name"
                      name="enquery_status_name"
                      onChange={handleChange}
                      value={values.enquery_status_name}
                      onBlur={handleBlur}
                      sx={{ width: "100%" }}
                    />
                     {errors.enquery_status_name && touched.enquery_status_name ?
                          <p className="text-danger" style={{ fontSize: "13px" }}>{errors.enquery_status_name}</p>
                          : null}
                      </FormControl>
                    </div>
                  </Col>

                  <Col xl={5}>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "start",
                        alignItems: "center",
                        marginTop: "0.25rem",
                      }}
                    >
                      <Button type="submit" className="btn btn-primary common-btn">
                        Update Enquiry Status
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Box>
          </Col>
        </Row>
        </PageLayout>
    </>
  );
}