import React, { useContext, useEffect, useState } from "react";
import PageLayout from "../../../layouts/PageLayout";
import { Link, useLocation, useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import APIClient from "../../../APIClient";
import { Box } from "../../../components/elements";
import CounsellorPageLayout from "../../../layouts/CousellorPageLayout";
import VendorpageLayout from "../../../layouts/VendorpageLayout";
import ManagerPageLayout from "../../../layouts/ManagerPageLayout";
import { TotalEnquiryContext } from "../../../context/TotalEnquiryContext";
import { Column } from "../../../Constant";
import { transform } from "../../../utills";

function Totalenquiry() {
  //   const [vendorId, setVendorId] = useState();
  const { topHeading, profileIdList } = useContext(TotalEnquiryContext);

  const location = useLocation();
  const { lead_status_id } = useParams();
  const [leadprofile, setLeadprofile] = useState();
  const adminPath = location.pathname === "/view-profiles";
  const vendorPath = location.pathname.startsWith("/vendor");
  const managerPath = location.pathname.startsWith("/manager");
  const counsellorPath = location.pathname.startsWith("/counsellor");
  const managerLeadStatus1 = location.pathname === "/manager/lead-status/1";

  // console.table({ adminPath, vendorPath, managerPath, counsellorPath });
  //   const [payload, setPayload] = useState({ lead_status_id: lead_status_id });
  const payload = {
    coma_sep_profile_ids: profileIdList.join(","),
  };
  // const payload={coma_sep_profile_ids:}
  //   useEffect(() => {
  //     setPayload({
  //       ...payload,
  //       counsellor_id: counsellorId,
  //       vendor_id: vendorId,
  //     });
  //   }, [payload]);

  // if (vendorPath) {
  //     payload.vendor_id = memberId;
  // } else if (counsellorPath) {
  //     payload.counsellor_id = memberId;
  // } else if (managerPath) {
  //     payload.manager_id = memberId;
  // }

  // fetch Lead Potential
  const fetchTotalenquiry = async () => {
    APIClient.get("sanctum/csrf-cookie").then((response) => {
      APIClient.post(`api/profileid-wise-profile`, payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((res) => {
        setLeadprofile(res);
      });
    });
  };
  useEffect(() => {
    if (profileIdList) {
      fetchTotalenquiry();
    }
  }, [profileIdList]);
  return (
    <>
      {adminPath && (
        <PageLayout>
          <Row>
            <Col xl="12" className="text-center py-5">
              <h1>{topHeading}</h1>
            </Col>
          </Row>
          {leadprofile &&
            leadprofile?.data?.profile_list.map((item, index) => (
              <Col xl={12} className="mb-3">
                <Box className="mc-card">
                  <div className="container-fluid">
                    <div className="row">
                      <div
                        className={
                          managerLeadStatus1 ||
                          vendorPath ||
                          (!managerPath && !counsellorPath && !adminPath)
                            ? "col-12"
                            : "col-11"
                        }
                      >
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              {Column?.map((item, index) => (
                                <th key={item + index} scope="col">
                                  {item}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            <tr key={index}>
                              <th scope="row">{index + 1}</th>
                              <td>{item.profile_name}</td>
                              <td>{item.councellor_name}</td>
                              <td>{item.vendor_code}</td>
                              <td>{item.last_lead_status}</td>
                              <td>{item.last_call_information}</td>
                              <td>{item.last_call_type}</td>
                              <td>{item.last_call_note}</td>
                              <td>{item.next_followup_date}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="col-1">
                        <Link
                          type="button"
                          className="common-btn"
                          id="common-btn"
                          variant="primary"
                          to={`/new-enquiryedit/${item.id}`}
                        >
                          Take Action
                        </Link>
                      </div>
                    </div>
                  </div>
                </Box>
              </Col>
            ))}
        </PageLayout>
      )}
      {counsellorPath && (
        <CounsellorPageLayout>
          <Row>
            <Col xl="12" className="text-center py-5">
              <h1> {topHeading}</h1>
            </Col>
          </Row>
          {leadprofile &&
            leadprofile?.data?.profile_list.map((item, index) => (
              <Col xl={12} className="mb-3">
                <Box className="mc-card">
                  <div className="container-fluid">
                    <div className="row">
                      <div
                        className={
                          managerLeadStatus1 ||
                          vendorPath ||
                          (!managerPath && !counsellorPath && !adminPath)
                            ? "col-12"
                            : "col-11"
                        }
                      >
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              {Column?.map((item, index) => (
                                <th key={item + index} scope="col">
                                  {item}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                          <tr key={index}>
                              <th scope="row">{index + 1}</th>
                              <td>{item.profile_name}</td>
                              <td>{item.councellor_name}</td>
                              <td>{item.vendor_code}</td>
                              <td>{item.last_lead_status}</td>
                              <td>{item.last_call_information}</td>
                              <td>{item.last_call_type}</td>
                              <td>{item.last_call_note}</td>
                              <td>{item.next_followup_date}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      {counsellorPath && (
                        <div className="col-1">
                          <Link
                            type="button"
                            className="common-btn"
                            id="common-btn"
                            variant="primary"
                            to={`/counsellor/new-enquiryedit/${item.id}`}
                          >
                            Take Action
                          </Link>
                        </div>
                      )}
                      {adminPath && !counsellorPath && (
                        <div className="col-1">
                          <Link
                            type="button"
                            className="common-btn"
                            id="common-btn"
                            variant="primary"
                            to={`/new-enquiryedit/${item.id}`}
                          >
                            Take Action
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </Box>
              </Col>
            ))}
        </CounsellorPageLayout>
      )}

      {vendorPath && (
        <VendorpageLayout>
          <Row>
            <Col xl="12" className="text-center py-5">
              <h1> {topHeading}</h1>
            </Col>
          </Row>
          {leadprofile &&
            leadprofile?.data?.profile_list.map((item, index) => (
              <Col xl={12} className="mb-3">
                <Box className="mc-card">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12">
                      <table className="table table-bordered">
                          <thead>
                            <tr>
                              {Column?.map((item, index) => (
                                <th key={item + index} scope="col">
                                  {item}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                          <tr key={index}>
                              <th scope="row">{index + 1}</th>
                              <td>{item.profile_name}</td>
                              <td>{item.councellor_name}</td>
                              <td>{item.vendor_code}</td>
                              <td>{item.last_lead_status}</td>
                              <td>{item.last_call_information}</td>
                              <td>{item.last_call_type}</td>
                              <td>{item.last_call_note}</td>
                              <td>{item.next_followup_date}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </Box>
              </Col>
            ))}
        </VendorpageLayout>
      )}
      {managerPath && (
        <ManagerPageLayout>
          <Row>
            <Col xl="12" className="text-center py-5">
              <h1> {topHeading}</h1>
            </Col>
          </Row>
          {leadprofile &&
            leadprofile?.data?.profile_list.map((item, index) => (
              <Col xl={12} className="mb-3">
                <Box className="mc-card">
                  <div className="container-fluid">
                    <div className="row">
                      <div
                        className={
                          managerLeadStatus1 ||
                          vendorPath ||
                          (!managerPath && !counsellorPath && !adminPath)
                            ? "col-12"
                            : "col-11"
                        }
                      >
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              {Column?.map((item, index) => (
                                <th key={item + index} scope="col">
                                  {item}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                          <tr key={index}>
                              <th scope="row">{index + 1}</th>
                              <td>{item.profile_name}</td>
                              <td>{item.councellor_name}</td>
                              <td>{item.vendor_code}</td>
                              <td>{item.last_lead_status}</td>
                              <td>{item.last_call_information}</td>
                              <td>{item.last_call_type}</td>
                              <td>{item.last_call_note}</td>
                              <td>{item.next_followup_date}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      {adminPath && !counsellorPath && (
                        <div className="col-1">
                          <Link
                            type="button"
                            className="common-btn"
                            id="common-btn"
                            variant="primary"
                            to={`/new-enquiryedit/${item.id}`}
                          >
                            Take Action
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </Box>
              </Col>
            ))}
        </ManagerPageLayout>
      )}
    </>
  );
}

export default Totalenquiry;
