import React, { useEffect, useState } from "react";
import ManagerPageLayout from "../../layouts/ManagerPageLayout";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { Link, useNavigate } from "react-router-dom";
import { Box } from "../../components/elements";
import { CardHeader } from "../../components/cards";
import { TextField } from "@mui/material";
import APIClient from "../../APIClient";
import { ToastContainer, toast } from "react-toastify";

function AddLead() {
  const navigate = useNavigate();
  const Id = localStorage.getItem("user_id");
  const [form, setForm] = useState({ manager_id: Id });
  function handleChange(e) {
    setForm({ ...form, file: e });
  }
  function uploadExlFile() {
    APIClient.get("sanctum/csrf-cookie")
      .then((response) => {
        return APIClient.post("api/profile-import", form, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
      })
      .then((excelexecute) => {
        toast.success(excelexecute.data.msg);
        setTimeout(() => {
          navigate("/manager/lead-List");
        }, 2000);
      })
      .catch((error) => {
        console.error("Error adding course:", error);
      });
  }
  return (
    <>
      <ToastContainer />
      <ManagerPageLayout>
        <Row>
          <Col xl={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Breadcrumb title={"Import Lead"}></Breadcrumb>
              <a
                download
                target="_blank"
                rel="noopener noreferrer"
                href="https://docs.google.com/spreadsheets/d/14x2hrnjEJvHhAv6Ln-X5rjq_4A03i6b_/edit?usp=sharing&ouid=112992718579372673425&rtpof=true&sd=true"
              >
                <Button className="btn btn-primary common-btn">
                  Download Excel Sample
                </Button>
              </a>
            </div>
          </Col>
          <Col xl={12}>
            <Box className="mc-card">
              <Form>
                <Row xs={1} md={1} xl={1}>
                  <Col xl={6}>
                    <div style={{ marginTop: "1rem" }}>
                      <CardHeader title="Upload Excel File" />
                      <TextField
                        type="file"
                        name="file"
                        id="outlined-required"
                        onChange={(event) => {
                          handleChange(event.target.files[0]);
                        }}
                        sx={{ width: "100%" }}
                      />
                    </div>
                  </Col>

                  <Col xl={3} className="d-flex align-items-end">
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "start",
                        alignItems: "center",
                        marginTop: "1.25rem",
                      }}
                    >
                      <Button
                        type="button"
                        onClick={uploadExlFile}
                        className="btn btn-primary common-btn"
                      >
                        Import Excel File
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Box>
          </Col>
        </Row>
      </ManagerPageLayout>
    </>
  );
}

export default AddLead;
