import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";
import TextField from "@mui/material/TextField";
import { Link, useNavigate } from "react-router-dom";
import APIClient from "../../APIClient";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { addCounsellorSchema } from "../schemas/addCounsellorSchema";




export default function EditCounsellor() {
    const { Id } = useParams();
    const navigate = useNavigate();
    const [initialValues, setInitialValues] = useState({
        councellor_id: Id,
        name:"",
        email:"",
        phone:"",
        address:"",
        username:"",
        password:"",

    });
    
    

    useEffect(()=>{
        APIClient.get('sanctum/csrf-cookie')
        APIClient.get('api/single-councellor/'+ Id,{
            headers: {
                "Authorization": "Bearer "+ localStorage.getItem('token')
            }
        }).then(res =>{
            setInitialValues({...initialValues, 
                name:res.data.councellor_list[0].councellor_name,
                email:res.data.councellor_list[0].councellor_email,
                phone:res.data.councellor_list[0].councellor_phone,
                address:res.data.councellor_list[0].councellor_address,
                username:res.data.councellor_list[0].councellor_username,
                password:res.data.councellor_list[0].councellorpassword
            })
        })
        .catch(err => console.log(err))
    },[])

    // update Form
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        enableReinitialize:true,
        validationSchema: addCounsellorSchema,
        onSubmit: (values, { setSubmitting }) => {
            APIClient.get('sanctum/csrf-cookie')
                .then(response => {
                    return APIClient.post('api/update-councellor', values, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            "Accept": "multipart/form-data",
                            "Authorization": "Bearer " + localStorage.getItem('token')
                        }
                    });
                })
                .then(editcouncellorResponse => {
                    toast.success(editcouncellorResponse.data.msg);
                    setTimeout(() => {
                        navigate('/Counsellor-list');
                    }, 2000);
                })
                .catch(error => {
                    console.error("Error adding counsellor:", error);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        },
    })


    // const handleFileChange = (e) => {
    //   const file = e.target.files[0];
    //   const fileName = file.name;
    //   setFormdata({ ...formdata, photo: file });
    // // }

    

    useEffect(() => {
        if (!localStorage.getItem('token') || localStorage.getItem('token') === null) {
            navigate('/login')
        }
    }, [])
    return (
        <>
            <ToastContainer autoClose={1000} />
            <PageLayout>
                <Row>
                    <Col xl={12}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                            }}
                        >
                            <Breadcrumb title={"Update Counsellor"}></Breadcrumb>
                            <Link to="/Counsellor-list">
                                <Button className="btn btn-primary common-btn">
                                    View Counsellor
                                </Button>
                            </Link>
                        </div>
                    </Col>
                    <Col xl={12}>
                        <Box className="mc-card">
                            <Form onSubmit={handleSubmit}>
                                <Row xs={1} md={1} xl={1}>
                                    <Col xl={6}>
                                        <div style={{ marginTop: "1rem" }}>
                                            <CardHeader title="Name" />
                                            <TextField
                                                // name="counsellorName"
                                                name="name"
                                                id="outlined-required"
                                                onChange={handleChange}
                                                value={values.name}
                                                onBlur={handleBlur}
                                                placeholder="Enter Name"
                                                sx={{ width: "100%" }}
                                            />
                                            {errors.name && touched.name ?
                                                <p className="text-danger" style={{ fontSize: "13px" }}>{errors.name}</p>
                                                : null}

                                        </div>
                                    </Col>

                                    <Col xl={6}>
                                        <div style={{ marginTop: "1rem" }}>
                                            <CardHeader title="Email" />
                                            <TextField
                                                id="outlined-required"
                                                // name="counsellorEmail"
                                                name="email"
                                                placeholder="Enter Email"
                                                type="email"
                                                onChange={handleChange}
                                                value={values.email}
                                                onBlur={handleBlur}
                                                sx={{ width: "100%" }}
                                            />
                                            {errors.email && touched.email ?
                                                <p className="text-danger" style={{ fontSize: "13px" }}>{errors.email}</p>
                                                : null}
                                        </div>
                                    </Col>

                                    <Col xl={6}>
                                        <div style={{ marginTop: "0rem" }}>
                                            <CardHeader title="Phone" />
                                            <TextField
                                                id="outlined-required"
                                                // name="counsellorPhone"
                                                name="phone"
                                                onChange={handleChange}
                                                value={values.phone}
                                                onBlur={handleBlur}
                                                placeholder="Enter Phone Number"
                                                type="number"
                                                sx={{ width: "100%" }}
                                            />

                                            {errors.phone && touched.phone ?
                                                <p className="text-danger" style={{ fontSize: "13px" }}>{errors.phone}</p>
                                                : null}
                                        </div>
                                    </Col>

                                    <Col xl={6}>
                                        <div style={{ marginTop: "0rem" }}>
                                            <CardHeader title="Photo" />
                                            <TextField
                                                id="outlined-required"
                                                name="photo"
                                                onChange={handleChange}
                                                value={values.photo}
                                                onBlur={handleBlur}
                                                type="file"
                                                sx={{ width: "100%" }}
                                            />
                                            {errors.photo && touched.photo ?
                                                <p className="text-danger" style={{ fontSize: "13px" }}>{errors.photo}</p>
                                                : null}
                                        </div>
                                    </Col>

                                    <Col xl={12}>
                                        <div style={{ marginTop: "0rem" }}>
                                            <CardHeader title="Address" />
                                            <TextField
                                                onChange={handleChange}
                                                value={values.address}
                                                onBlur={handleBlur}
                                                // name="counsellorAddress"
                                                name="address"
                                                multiline
                                                rows={4}
                                                sx={{ width: "100%" }}
                                            />
                                            {errors.address && touched.address ?
                                                <p className="text-danger" style={{ fontSize: "13px" }}>{errors.address}</p>
                                                : null}
                                        </div>
                                    </Col>
                                    {/* <Col xl={6}>
                                        <div style={{ marginTop: "0rem" }}>
                                            <CardHeader title="Username" />
                                            <TextField
                                                label="Username Can't Modified"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                name="username"
                                                onChange={handleChange}
                                                value={values.username}
                                                onBlur={handleBlur}
                                                type="text"
                                                sx={{ width: "100%" }}
                                                placeholder="Enter Username"
                                                readonly
                                            />
                                            {errors.username && touched.username ?
                                                <p className="text-danger" style={{ fontSize: "13px" }}>{errors.username}</p>
                                                : null}
                                        </div>
                                    </Col>
                                    <Col xl={6}>
                                        <div style={{ marginTop: "0rem" }}>
                                            <CardHeader title="Password" />
                                            <TextField
                                                // name="counsellorPassword"
                                                name="password"
                                                onChange={handleChange}
                                                value={values.password}
                                                onBlur={handleBlur}
                                                type="password"
                                                sx={{ width: "100%" }}
                                                placeholder="Enter Password"
                                            />
                                            {errors.password && touched.password ?
                                                <p className="text-danger" style={{ fontSize: "13px" }}>{errors.password}</p>
                                                : null}
                                        </div>
                                    </Col> */}
                                     <Col xl={12}>
                                        <div style={{ marginTop: "0rem" }}>
                                            <CardHeader title="Password" />
                                            <TextField
                                                id="outlined-required"
                                                name="password"
                                                onChange={handleChange}
                                                value={values.password}
                                                // onBlur={handleBlur}
                                                placeholder="Enter Password"
                                                type="text"
                                                sx={{ width: "100%" }}
                                            />
                                            {/* {errors.username && touched.username ?
                                                <p className="text-danger" style={{ fontSize: "13px" }}>{errors.username}</p>
                                                : null} */}
                                        </div>
                                    </Col>

                                    <Col xl={12}>
                                        <div
                                            style={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "start",
                                                alignItems: "center",
                                                marginTop: "1.25rem",
                                            }}
                                        >
                                            <Button type="submit" className="btn btn-primary common-btn">
                                                Update Counsellor
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Box>
                    </Col>
                </Row>
            </PageLayout>
        </>

    );
}
