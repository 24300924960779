import React, { useContext, useEffect } from "react";
import { MultipleMenu, Logout } from "../components/sidebar";
import { DrawerContext } from "../context/Drawer";
import { EnquiryContext } from "../context/Enquiry";
import Section from "../components/elements/Section";
import data from "../data/master/vendorsidebar.json";
import { Button } from "../components/elements";
import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import SIdebartabdata from "../data/master/vendersidebartabdata.json";
import { Link, useNavigate } from "react-router-dom";
import APIClient from "../APIClient";

export default function VendorSidebar() {
  const navigate = useNavigate();
  const { drawer } = useContext(DrawerContext);
  const [show, setShow] = useState(true);
  const handelcomponent = () => {setShow(!show);};
  const [leadStatus, setleadStatus] = useState([]);
  const [newenquerylength, setNewenquerylength] = useState(0);
  const [followmissedlength, setFollowmissedlength] = useState(0);
  const [leadstatuscounthot, setLeadstatuscounthot] = useState(0);
  const [leadstatuscountcold, setLeadstatuscountcold] = useState(0);
  const [leadstatuscountsupercold, setLeadstatuscountsupercold] = useState(0);
  const [leadstatuscounttrash, setLeadstatuscounttrash] = useState(0);
  

  
  const id = 1;
  const memberId = localStorage.getItem("user_id");

  const fetchleadstatuscounthot = () => {
    const token = localStorage.getItem("token");

    if (token) {
      APIClient.get("sanctum/csrf-cookie")
        .then(() => {
          return APIClient.post(
            `api/lead-status-count`,{lead_status_id:2, vendor_id:memberId},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        })
        .then((res) => {
          const data = res.data;
          setLeadstatuscounthot(data.vendor_lead_status_count);
          
        })
    } 
  };
  const fetchleadstatuscountcold = () => {
    const token = localStorage.getItem("token");

    if (token) {
      APIClient.get("sanctum/csrf-cookie")
        .then(() => {
          return APIClient.post(
            `api/lead-status-count`,{lead_status_id:3, vendor_id:memberId},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        })
        .then((res) => {
          const data = res.data;
          setLeadstatuscountcold(data.vendor_lead_status_count);
          
        })
    } 
  };
  const fetchleadstatuscountsupercold = () => {
    const token = localStorage.getItem("token");

    if (token) {
      APIClient.get("sanctum/csrf-cookie")
        .then(() => {
          return APIClient.post(
            `api/lead-status-count`,{lead_status_id:4, vendor_id:memberId},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        })
        .then((res) => {
          const data = res.data;
          setLeadstatuscountsupercold(data.vendor_lead_status_count);
          
        })
    } 
  };
  const fetchleadstatuscounttrash = () => {
    const token = localStorage.getItem("token");

    if (token) {
      APIClient.get("sanctum/csrf-cookie")
        .then(() => {
          return APIClient.post(
            `api/lead-status-count`,{lead_status_id:5, vendor_id:memberId},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        })
        .then((res) => {
          const data = res.data;
          setLeadstatuscounttrash(data.vendor_lead_status_count);
          
        })
    } 
  };
 

  const fetchnewenqueryprofiledatalength = () => {
    const token = localStorage.getItem("token");
    if (token) {
      APIClient.get("sanctum/csrf-cookie")
        .then(() => {
          return APIClient.get(
            `api/vendorwise-list-lead-profile/${memberId}/${id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        })
        .then((res) => {
          const data = res.data;
          setNewenquerylength(data.profile_list.length);
        })
        .catch((err) => {
          console.error(err);
          if (err.response && err.response.status === 401) {
            console.log("Token might be invalid or expired");
          }
        });
    } else {
      console.log("No token found");
    }
  };

  const fetchfollowmissedprofiledatalength = () => {
    const token = localStorage.getItem("token");
    if (token) {
      APIClient.get("sanctum/csrf-cookie")
        .then(() => {
          return APIClient.post(
            `api/follow-up-missed`,{vendor_id:memberId},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        })
        .then((res) => {
          const data = res.data;
          setFollowmissedlength(data.no_of_miss_followup);
        })
        .catch((err) => {
          console.error(err);
          if (err.response && err.response.status === 401) {
            console.log("Token might be invalid or expired");
          }
        });
    } else {
      console.log("No token found");
    }
  };

  // fetch lead status
  const fetchLeadStatus = () => {
    APIClient.get("sanctum/csrf-cookie");
    APIClient.get("api/leadstatus-list", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then((res) => {
      const data = res.data.leadstatus_list;
      setleadStatus(data);
    })
    .catch((err) => console.log(err));
  };
  useEffect(()=>{
    fetchleadstatuscounthot();
    fetchleadstatuscountcold();
    fetchleadstatuscountsupercold();
    fetchleadstatuscounttrash();
  },[]);

  useEffect(() => {
    fetchnewenqueryprofiledatalength();
    fetchfollowmissedprofiledatalength();
  }, []);

  useEffect(() => {
    fetchLeadStatus();
  }, []);
  const Navigate = useNavigate();
  return (
    <>
      <Section
        as="aside"
        className={`mc-sidebar thin-scrolling ${drawer ? "active" : ""}`}
      >
        <MultipleMenu data={data?.navs} />
       

        <div className={show === true ? "d-block" : "d-none"}>
          <Row
            className="d-flex gray-200 row mx-1 mt-0 rounded"
            style={{ cursor: "pointer" }}
            onClick={() => Navigate("/vendor/lead-status/1")}
          >
            <Col className="m-0 p-2" md="8">
              New Enquiries
            </Col>
            <Col md="4" className="m-0 p-2 text-end">
              <span className="bg-secondary p-1 text-white">
                {newenquerylength}
              </span>
            </Col>
          </Row>
          {/* tag list */}
          <div className="row m-0 my-3">
            {leadStatus.map(
              (item, index) =>
                item.id !== 1 && (
                  <div className=" col-6 m-0 p-1" key={item.id}>
                    <Link
                      to={`/vendor/lead-status/${item.id}`}
                      className="btn border w-100 text-dark"
                    >
                      {item.lead_status_name}
                      {item.id === 2 ? ` (${leadstatuscounthot})` : ''}
                      {item.id === 3 ? ` (${leadstatuscountcold})` : ''}
                      {item.id === 4 ? ` (${leadstatuscountsupercold})` : ''}
                      {item.id === 5 ? ` (${leadstatuscounttrash})` : ''}
                    </Link>
                  </div>
                )
            )}
          </div>

          <Row
            className="d-flex gray-200 row mx-1 mt-3 rounded"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/vendor/followupmissed")}
          >
            <Col className="m-0 p-2" md="9">
              Follow UP Missed
              <br />
              <small>(Only Hot & Cold Data)</small>
            </Col>
            <Col
              md="3"
              className="m-0 p-2 text-end d-flex justify-content-end align-items-center"
            >
              <span className="bg-secondary p-1 text-white">{followmissedlength}</span>
            </Col>
          </Row>
        </div>

        <Logout data={data?.button} />
      </Section>
    </>
  );
}
