import React, { useEffect, useState } from "react";
import APIClient from "../../APIClient";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Col, Row } from "react-bootstrap";
import { Box } from "../../components/elements";
import { CardHeader } from "../../components/cards";
import ManagerPageLayout from "../../layouts/ManagerPageLayout";
import { Link } from "react-router-dom";

function LeadList() {
  const [counsellordata, setCounsellordata] = useState();
  const [managerbaseexceldata, setManagerbaseexceldata] = useState();
  const [groupdata, setGroupdata] = useState([]);
  const managerId = localStorage.getItem("user_id");
  const [counsellorId, setCounsellorId] = useState();
  const [counsellorGroupId, setCounsellorGroupId] = useState();

  const managerexcelgetdata = async () => {
    APIClient.get("sanctum/csrf-cookie").then((response) => {
      APIClient.post(
        `api/manager-wise-lead-callstatus/` + managerId,
        { counsellor_group_id: counsellorGroupId, counsellor_id: counsellorId },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      ).then((getmanagerexeldataResponse) => {
        setManagerbaseexceldata(getmanagerexeldataResponse);
      });
    });
  };

  const fetchcounsellordata = async () => {
    APIClient.get("sanctum/csrf-cookie").then((response) => {
      APIClient.get("api/councellor-list", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((getcouncellorResponse) => {
        setCounsellordata(getcouncellorResponse);
      });
    });
  };
  const fetchgroupdata = async () => {
    try {
      const csrfResponse = await APIClient.get("sanctum/csrf-cookie");
      if (csrfResponse.status === 204) {
        const getgroupResponse = await APIClient.get(
          "api/counsellorgroup-list",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        setGroupdata(getgroupResponse.data);
      }
    } catch (error) {
      console.error("Error fetching group data:", error);
    }
  };
  useEffect(() => {
    if (managerId || counsellorId || counsellorGroupId) {
      managerexcelgetdata();
    }
  }, [managerId, counsellorGroupId, counsellorId]);

  useEffect(() => {
    managerexcelgetdata();
    fetchcounsellordata();
    fetchgroupdata();
  }, []);
  return (
    <>
      <ManagerPageLayout>
        <Col xl={12} className="text-center mb-4">
          <Form>
            <Form.Group as={Row}>
              <Form.Label column sm="2" className="d-flex align-items-center">
                Select Counsellor Group
              </Form.Label>
              <Col sm="3">
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => setCounsellorGroupId(e.target.value)}
                >
                  <option>{"<-- Select Group -->"}</option>
                  {groupdata &&
                    groupdata.counsellor_group &&
                    groupdata.counsellor_group.map((item, index) => (
                      <option key={item.id} value={item.id}>
                        {item.group_name}
                      </option>
                    ))}
                </Form.Select>
              </Col>

              <Form.Label column sm="2" className="d-flex align-items-center">
                Select Counsellor
              </Form.Label>
              <Col sm="3">
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => setCounsellorId(e.target.value)}
                >
                  <option>{"<-- Select Counsellor -->"}</option>
                  {counsellordata &&
                    counsellordata.data.councellor_list.map((item, index) => (
                      <option key={item.id} value={item.id}>
                        {item.councellor_name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
              <Col sm="2">
                <Button className="common-btn" variant="primary">
                  Search
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </Col>
        <Col xl={12}>
          <Box className="mc-card  p-0">
            <Row xs={1} md={1} xl={1}>
              <Col xl={12}>
                <Box className="mc-card border-0 ">
                  <CardHeader title="Lead List" />
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 overflow-auto">
                        <table className="table table-bordered ">
                          <thead>
                            <tr>
                              <th scope="col"> S.no</th>
                              <th scope="col"> Name</th>
                              <th scope="col"> Email</th>
                              <th scope="col"> Phone</th>
                              <th scope="col"> Next Follow Up Date</th>
                              <th scope="col"> Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {managerbaseexceldata &&
                              managerbaseexceldata.data.map((item, index) => (
                                <tr key={item.id}>
                                  <td scope="row">{index + 1}</td>
                                  <td>{item.profile_name}</td>
                                  <td>{item.profile_email}</td>
                                  <td>{item.profile_mobile}</td>
                                  <td>{item.next_followup_date}</td>
                                  <td>
                                    <Link
                                      to={`/manager/journeyhistory/${item.profile_id}`}
                                      className="btn btn-primary common-btn btn btn-primary"
                                    >
                                      Journey History
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </Box>
              </Col>
            </Row>
          </Box>
        </Col>
      </ManagerPageLayout>
    </>
  );
}

export default LeadList;
