import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Breadcrumb, DivideTitle } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";
import TextField from "@mui/material/TextField";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import APIClient from "../../APIClient";
import { ToastContainer, toast } from "react-toastify";
import { AddVendorSchema } from "../schemas/AddVendorSchema";

export default function EditVendor() {
    const { Id } = useParams();
    const navigate = useNavigate();
    const [initialValues, setInitialValues] = useState({
    leadvendor_id:Id,
    name:"",
    email:"",
    phone:"",
    username:"",
    password:""
    })

    useEffect(() => {
        APIClient.get('sanctum/csrf-cookie')
        APIClient.get('api/single-leadvendor/' + Id, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token')
            }
        }).then(res => {
            setInitialValues({
                ...initialValues,
                name: res.data.leadvendor_list[0].name,
                email: res.data.leadvendor_list[0].email,
                phone: res.data.leadvendor_list[0].phone,
                username: res.data.leadvendor_list[0].username,
                password: res.data.leadvendor_list[0].password
            })
            console.log(initialValues);
        })
            .catch(err => console.log(err))
    }, [])

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        enableReinitialize:true,
        onSubmit: (values, { setSubmitting }) => {
            APIClient.get('sanctum/csrf-cookie')
                .then(response => {
                    return APIClient.post('api/update-leadvendor', values, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            "Accept": "multipart/form-data",
                            "Authorization": "Bearer " + localStorage.getItem('token')
                        }
                    });
                })
                .then(editLeadvendor => {
                    toast.success(editLeadvendor.data.msg);
                    setTimeout(() => {
                        navigate('/vendor-list');
                    }, 2000);
                })
                .catch(error => {
                    console.error("Error updateing Vendor:", error);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        },
    })

    useEffect(() => {
        if (!localStorage.getItem('token') || localStorage.getItem('token') === null) {
            navigate('/login')
        }
    }, [])

    return (
        <>
            <ToastContainer autoClose={1000} />
            <PageLayout>
                <Row>
                    <Col xl={12}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                            }}
                        >
                            <Breadcrumb title={"Update Vendor"}></Breadcrumb>
                            <Link to="/vendor-list">
                                <Button className="btn btn-primary common-btn">
                                    View Vendor
                                </Button>
                            </Link>
                        </div>
                    </Col>
                    <Col xl={12}>
                        <Box className="mc-card">
                            <Form onSubmit={handleSubmit}>
                                <Row xs={1} md={1} xl={1}>
                                    <Col xl={6}>
                                        <div style={{ marginTop: "1rem" }}>
                                            <CardHeader title="Name" />
                                            <TextField
                                                id="outlined-required"
                                                name="name"
                                                onChange={handleChange}
                                                value={values.name}
                                                onBlur={handleBlur}
                                                placeholder="Enter Name"
                                                sx={{ width: "100%" }}
                                            />
                                            {errors.name && touched.name ?
                                                <p className="text-danger" style={{ fontSize: "13px" }}>{errors.name}</p>
                                                : null}
                                        </div>
                                    </Col>

                                    <Col xl={6}>
                                        <div style={{ marginTop: "1rem" }}>
                                            <CardHeader title="Email" />
                                            <TextField
                                                id="outlined-required"
                                                name="email"
                                                onChange={handleChange}
                                                value={values.email}
                                                onBlur={handleBlur}
                                                placeholder="Enter Email"
                                                type="email"
                                                sx={{ width: "100%" }}
                                            />
                                            {errors.email && touched.email ?
                                                <p className="text-danger" style={{ fontSize: "13px" }}>{errors.email}</p>
                                                : null}
                                        </div>
                                    </Col>

                                    <Col xl={6}>
                                        <div style={{ marginTop: "0rem" }}>
                                            <CardHeader title="Phone" />
                                            <TextField
                                                id="outlined-required"
                                                name="phone"
                                                onChange={handleChange}
                                                value={values.phone}
                                                onBlur={handleBlur}
                                                placeholder="Enter Phone Number"
                                                type="number"
                                                sx={{ width: "100%" }}
                                            />
                                            {errors.phone && touched.phone ?
                                                <p className="text-danger" style={{ fontSize: "13px" }}>{errors.phone}</p>
                                                : null}
                                        </div>
                                    </Col>
                                    <Col xl={6}>
                                        <div style={{ marginTop: "0rem" }}>
                                            <CardHeader title="Username" />
                                            <TextField
                                                id="outlined-required"
                                                name="username"
                                                onChange={handleChange}
                                                value={values.username}
                                                onBlur={handleBlur}
                                                placeholder="Enter Username"
                                                type="text"
                                                sx={{ width: "100%" }}
                                            />
                                            {errors.username && touched.username ?
                                                <p className="text-danger" style={{ fontSize: "13px" }}>{errors.username}</p>
                                                : null}
                                        </div>
                                    </Col>

                                    <Col xl={12}>
                                        <div style={{ marginTop: "0rem" }}>
                                            <CardHeader title="Password" />
                                            <TextField
                                                id="outlined-required"
                                                name="password"
                                                onChange={handleChange}
                                                value={values.password}
                                                // onBlur={handleBlur}
                                                placeholder="Enter Password"
                                                type="text"
                                                sx={{ width: "100%" }}
                                            />
                                            {/* {errors.username && touched.username ?
                                                <p className="text-danger" style={{ fontSize: "13px" }}>{errors.username}</p>
                                                : null} */}
                                        </div>
                                    </Col>


                                    <Col xl={12}>
                                        <div
                                            style={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "start",
                                                alignItems: "center",
                                                marginTop: "1.25rem",
                                            }}
                                        >
                                            <Button type="submit" className="btn btn-primary common-btn">
                                                Update Vendor
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Box>
                    </Col>
                </Row>
            </PageLayout>
        </>
    );
}
