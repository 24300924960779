import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";
import { Link, useNavigate } from "react-router-dom";
import APIClient from "../../APIClient";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import ListLoader from "../../components/SkeletonLoaderComponent/ListLoader";

export default function CourseList() {
  const navigate = useNavigate();
  const [coursedata, setCoursedata] = useState();
  const [coursedatadelete, setCoursedatadelete] = useState();
  const [loading, setLoading] = useState(false);

  const fetchCoursedata = async () => {
    setLoading(true);
    APIClient.get("sanctum/csrf-cookie")
      .then((response) => {
        APIClient.get("api/course-list", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }).then((getCourseResponse) => {
          setLoading(false);
          setCoursedata(getCourseResponse);
        });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const handeldelete = async (courseId) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    Swal.fire({
      title: "Are you sure?",
      text: "You won't to Delete This Course !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((delcourseResponse) => {
      if (delcourseResponse.isConfirmed) {
        APIClient.get("sanctum/csrf-cookie").then((response) => {
          APIClient.get(`api/delete-course/${courseId}`).then(
            (delcourseResponse) => {
              setCoursedatadelete(delcourseResponse);
              fetchCoursedata();
            }
          );
        });
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
      } else if (delcourseResponse.dismiss === Swal.DismissReason.cancel) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Delete Cancel :)",
          icon: "error",
        });
      }
    });
  };

  useEffect(() => {
    fetchCoursedata();
  }, []);

  useEffect(() => {
    if (
      !localStorage.getItem("token") ||
      localStorage.getItem("token") === null
    ) {
      navigate("/login");
    }
  }, []);

  return (
    <>
      <ToastContainer autoClose={1000} />
      <PageLayout>
        <Row>
          <Col xl={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Breadcrumb title={"Course List"}></Breadcrumb>
              <Link to="/add-course">
                <Button className="btn btn-primary common-btn">
                  Add Course
                </Button>
              </Link>
            </div>
          </Col>
          <Col xl={12}>
            <Box className="mc-card p-0">
              <Row xs={1} md={1} xl={1}>
                <Col xl={12}>
                  <Box className="mc-card">
                    <CardHeader title="course List" />
                    <div className="container-fluid">
                      <div className="row">
                        <ListLoader load={loading} layout={2}>
                          <div className="col-12 overflow-auto">
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">SL</th>
                                  <th scope="col">Course Name</th>
                                  <th scope="col">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {coursedata &&
                                  coursedata.data.course_list.map(
                                    (item, index) => (
                                      <tr key={item.id}>
                                        <th scope="row">{index + 1}</th>
                                        <td>{item.course_name}</td>

                                        <td
                                          style={{
                                            display: "flex",
                                            justifyContent: "start",
                                          }}
                                        >
                                          <Link
                                            to={`/editCourse/${item.id}`}
                                            style={{
                                              width: "2rem",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              marginRight: "1.6rem",
                                            }}
                                            className="btn btn-success"
                                          >
                                            <i className="fas fa-edit"></i>
                                          </Link>

                                          <button
                                            type="button"
                                            onClick={() =>
                                              handeldelete(item.id)
                                            }
                                            style={{
                                              width: "2rem",
                                              fontSize: "0.6rem",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                            className="btn btn-danger"
                                          >
                                            <i className="fas fa-trash"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    )
                                  )}
                              </tbody>
                            </table>
                          </div>
                        </ListLoader>
                      </div>
                    </div>
                  </Box>
                </Col>
                {/*
                            <Col>
                                <DivideTitle title="large size" className="mb-4 mt-3" />
                                <LabelField label="text field" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" />
                                <LabelField label="select option" fieldSize="w-100 h-lg" labelDir="label-col mb-4" option={["admin", "member", "client"]} />
                                <LabelTextarea label="long textarea" fieldSize="w-100 h-text-lg" labelDir="label-col"  />
                            </Col> */}
              </Row>
            </Box>
          </Col>
        </Row>
      </PageLayout>
    </>
  );
}
