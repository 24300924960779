import * as Yup from "yup";

export const addCounsellorSchema = Yup.object({
    name: Yup.string().required("Please Enter Your Name"),
    email: Yup.string().email("Invalid email address").required("Please Enter Your Email"),
    phone: Yup.string().min(10, "number Must Be 10 Number").required("Please Enter Your Phone"),
    // photo: Yup.mixed().required("Please upload a photo"),
    address: Yup.string().required("Please Enter Your Address"),
    username: Yup.string().required("Please Enter Your Username"),
    password: Yup.string().min(5, "Password must be less then 5 characters").required("Please Enter Your Password"),

});