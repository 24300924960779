import React, { useEffect, useState } from "react";
import { Box, Form, Heading, Button, Image, } from "../../components/elements";
import IconField from "../../components/fields/IconField";
import Logo from "../../components/Logo";
import data from "../../data/master/vendorlogin.json";
import APIClient from "../../APIClient";
import { FaExclamationCircle } from 'react-icons/fa';
import { CheckCircleOutline } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";



export default function VendorLogin() {

    const navigate = useNavigate();

    const [loginCreds, SetLoginCreds] = useState({
        username: "",
        password: ""
    })
    const [errors, setErrors] = useState({
        username: "",
        password: ""
    });

    const [validInputs, setValidInputs] = useState({
        username: false,
        password: false
    });

    const [passwordVisible, setPasswordVisible] = useState(false);

    let name, value;

    const inputsHandler = (e, index) => {
        name = e.target.name;
        value = e.target.value;
        SetLoginCreds({ ...loginCreds, [name]: value });

        // set validation
        let isValid = true;

        if (!value.trim()) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [name]: `Please enter your ${name === 'username' ? 'username' : 'password'}.`
            }));
            isValid = false;
        } else {
            setErrors(prevErrors => ({
                ...prevErrors,
                [name]: ""
            }));
        }

        setValidInputs(prevValidInputs => ({
            ...prevValidInputs,
            [name]: isValid
        }));
    }
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const submitHandler = async () => {
        // Login API Fetch

        setErrors({
            username: "",
            password: ""
        });

        let isValid = true;

        if (!loginCreds.username.trim()) {
            setErrors(prevErrors => ({
                ...prevErrors,
                username: "Please enter your Username."
            }));
            isValid = false;
        }

        if (!loginCreds.password.trim()) {
            setErrors(prevErrors => ({
                ...prevErrors,
                password: "Please enter your password."
            }));
            isValid = false;
        }



        if (isValid) {
            APIClient.get('sanctum/csrf-cookie').then(response => {
                APIClient.post('api/vendor-login', loginCreds).then(vendorLoginResponse => {
                    localStorage.setItem('token', vendorLoginResponse.data.token);
                    localStorage.setItem('email', vendorLoginResponse.data.user_details.email);
                    localStorage.setItem('role_type', vendorLoginResponse.data.user_details.role_id);
                    localStorage.setItem('user_id', vendorLoginResponse.data.user_details.id);

                    if (vendorLoginResponse.data.user_details.role_id && vendorLoginResponse.data.user_details.role_id === 4) {
                        toast.success("Login Successfully");
                        setTimeout(() => {
                            navigate('/vendor/dashboard');
                          }, 2000);
                    }else {
                        toast.error("Unauthorized access. Please check your credentials.");
                    }
                }).catch(error => {
                    if (error.response && error.response.status === 401) {
                        toast.error("email or password wrong !");
                    } else {
                        toast.error(error.message);
                    }
                });
            }).catch(error => {
                toast.error("Failed to fetch CSRF token.");
            });
        }
    }
   

    useEffect(() => {
        if (localStorage.getItem('role_type') && localStorage.getItem('role_type') == 4) {
            navigate('/vendor/dashboard')
        }
    }, [])


    return (
        <>
        <ToastContainer autoClose={1000} />
            <Box className="mc-auth">
                <Image
                    src={data?.pattern.src}
                    alt={data?.pattern.alt}
                    className="mc-auth-pattern "
                />
                <Box className="mc-auth-group">
                    <Logo
                        src={data?.logo.src}
                        alt={data?.logo.alt}
                        href={data?.logo.path}
                        className="mc-auth-logo mb-5"

                    />
                    <Heading as="h4" className="mc-auth-title">VENDOR LOGIN</Heading>
                    <Form className="mc-auth-form">
                        {data?.input.map((item, index) => (
                            <div className="input-container" style={{ position: 'relative' }} key={index} >
                                <IconField
                                    key={index}
                                    icon={item.icon}
                                    type={item.type}
                                    option={item.option}
                                    classes={item.fieldSize}
                                    placeholder={item.placeholder}
                                    name={item.name}
                                    value={item.name === "username" ? loginCreds.username : loginCreds.password}
                                    passwordVisible={item.passwordVisible}
                                    onChange={(e) => inputsHandler(e, index)}
                                />
                                {errors[item.name] && (
                                    <FaExclamationCircle style={{ color: 'red', position: 'absolute', right: '-27px', top: '50%', transform: 'translateY(-50%)' }} />
                                )}
                                {validInputs[item.name] && (
                                    <CheckCircleOutline style={{ color: 'green', position: 'absolute', right: '-27px', top: '50%', transform: 'translateY(-50%)' }} />
                                )}
                                {errors[item.name] && (
                                    <div style={{ color: 'red', fontSize: '0.8em', marginTop: '5px', position: 'absolute', left: 0, bottom: '-20px' }}>
                                        {errors[item.name]}
                                    </div>
                                )}
                            </div>
                        ))}

                        <Button onClick={submitHandler} className={`mc-auth-btn mt-2 ${data?.button.fieldSize}`} type={data?.button.type}>{data?.button.text}</Button>

                    </Form>

                </Box>
            </Box>
        </>
    );
}