import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { Link, useNavigate } from "react-router-dom";
import APIClient from "../../APIClient";
import { ToastContainer, toast } from "react-toastify";
import { useFormik } from "formik";
import { AddLeadStatusSchema } from "../schemas/AddLeadStatusSchema";
import Swal from "sweetalert2";
import ListLoader from "../../components/SkeletonLoaderComponent/ListLoader";

export default function AddLeadStatus() {
  const [loading, setLoading] = useState(false);
  const Permissions = JSON.parse(localStorage.getItem("Permission"));
  const [leadStatus, setleadStatus] = useState([]);
  const [LeadStatusdelete, setLeadStatusdelete] = useState();
  const navigate = useNavigate();

  const initialValues = {
    leadstatus_name: "",
  };

  const fetchLeadStatus = () => {
    setLoading(true);
    APIClient.get("sanctum/csrf-cookie");
    APIClient.get("api/leadstatus-list", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        setLoading(false);
        const data = res.data.leadstatus_list;
        setleadStatus(data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: AddLeadStatusSchema,
      onSubmit: (values, { setSubmitting }) => {
        APIClient.get("sanctum/csrf-cookie")
          .then((response) => {
            return APIClient.post("api/add-leadstatus", values, {
              headers: {
                "Content-Type": "multipart/form-data",
                Accept: "multipart/form-data",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            });
          })
          .then((leadstatusres) => {
            toast.success(leadstatusres.data.msg);
            setTimeout(() => {
              fetchLeadStatus();
            }, 2000);
          })
          .catch((error) => {
            console.error("Error adding Lead Status:", error);
          })
          .finally(() => {
            setSubmitting(false);
          });
      },
    });

  const handeldelete = async (LeadStatusId) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    Swal.fire({
      title: "Are you sure?",
      text: "You won't to Delete Lead Status !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((delitem) => {
      if (delitem.isConfirmed) {
        APIClient.get("sanctum/csrf-cookie").then((response) => {
          APIClient.get(`api/delete-leadstatus/${LeadStatusId}`).then(
            (delLeadStatusIdResponse) => {
              setLeadStatusdelete(delLeadStatusIdResponse);
              fetchLeadStatus();
            }
          );
        });
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
      } else if (delitem.dismiss === Swal.DismissReason.cancel) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Delete Cancel :)",
          icon: "error",
        });
      }
    });
  };

  useEffect(() => {
    fetchLeadStatus();
  }, []);

  useEffect(() => {
    if (
      !localStorage.getItem("token") ||
      localStorage.getItem("token") === null
    ) {
      navigate("/login");
    }
  }, []);

  return (
    <>
      <ToastContainer autoClose={1000} />
      <PageLayout>
        <Row>
          <Col xl={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Breadcrumb title={"Lead Status"}></Breadcrumb>
            </div>
          </Col>
          <Col xl={7}>
            <Box className="mc-card">
              <Row xs={1} md={1} xl={1}>
                {/* {Permissions.filter(
                  (item) =>
                    item.menu_title === "Lead Status" &&
                    item.add_permission === 1
                ) ? (
                  <Form onSubmit={handleSubmit}>
                    <Col xl={12}>
                      <div style={{ marginTop: "1rem" }}>
                        <CardHeader title="Lead Status Name" />
                        <FormControl sx={{ width: "100%" }}>
                          <TextField
                            id="outlined-required"
                            placeholder="Enter Lead Status Name"
                            name="leadstatus_name"
                            onChange={handleChange}
                            value={values.leadstatus_name}
                            onBlur={handleBlur}
                            sx={{ width: "100%" }}
                          />
                          {errors.leadstatus_name && touched.leadstatus_name ? (
                            <p
                              className="text-danger"
                              style={{ fontSize: "13px" }}
                            >
                              {errors.leadstatus_name}
                            </p>
                          ) : null}
                        </FormControl>
                      </div>
                    </Col>

                    <Col xl={5}>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "start",
                          alignItems: "center",
                          marginTop: "1.25rem",
                        }}
                      >
                        <Button
                          type="submit"
                          className="btn btn-primary common-btn"
                        >
                          Add Lead Status
                        </Button>
                      </div>
                    </Col>
                  </Form>
                ) : (
                  " "
                )} */}

                <Form onSubmit={handleSubmit}>
                  <Col xl={12}>
                    <div style={{ marginTop: "1rem" }}>
                      <CardHeader title="Lead Status Name" />
                      <FormControl sx={{ width: "100%" }}>
                        <TextField
                          id="outlined-required"
                          placeholder="Enter Lead Status Name"
                          name="leadstatus_name"
                          onChange={handleChange}
                          value={values.leadstatus_name}
                          onBlur={handleBlur}
                          sx={{ width: "100%" }}
                        />
                        {errors.leadstatus_name && touched.leadstatus_name ? (
                          <p
                            className="text-danger"
                            style={{ fontSize: "13px" }}
                          >
                            {errors.leadstatus_name}
                          </p>
                        ) : null}
                      </FormControl>
                    </div>
                  </Col>

                  <Col xl={5}>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "start",
                        alignItems: "center",
                        marginTop: "1.25rem",
                      }}
                    >
                      <Button
                        type="submit"
                        className="btn btn-primary common-btn"
                      >
                        Add Lead Status
                      </Button>
                    </div>
                  </Col>
                </Form>
              </Row>
            </Box>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col xl={12}>
            <Box className="mc-card p-0">
              <Row xs={1} md={1} xl={1}>
                <Col xl={12}>
                  <Box className="mc-card">
                    <CardHeader title="Lead Status List" />
                    <div className="container-fluid">
                      <div className="row">
                        <ListLoader load={loading} layout={2}>
                          <div className="col-12">
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">SL</th>
                                  <th scope="col">Lead Status Name</th>
                                  <th scope="col">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {leadStatus.map((item, index) => (
                                  <tr key={index}>
                                    <th scope="row">{index + 1}</th>
                                    <td>{item.lead_status_name}</td>
                                    <td
                                      style={{
                                        display: "flex",
                                        justifyContent: "start",
                                      }}
                                    >
                                      <Link
                                        to={`/edit-lead-status/${item.id}`}
                                        style={{
                                          width: "2rem",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          marginRight: "1.6rem",
                                        }}
                                        className="btn btn-success"
                                      >
                                        <i className="fas fa-edit"></i>
                                      </Link>

                                      {/* <button
                                      type="button"
                                      onClick={() => handeldelete(item.id)}
                                      style={{
                                        width: "2rem",
                                        fontSize: "0.6rem",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                      className="btn btn-danger"
                                    >
                                      <i className="fas fa-trash"></i>
                                    </button> */}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </ListLoader>
                      </div>
                    </div>
                  </Box>
                </Col>
              </Row>
            </Box>
          </Col>
        </Row>
      </PageLayout>
    </>
  );
}
