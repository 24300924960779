import React, { useEffect, useState, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { Box } from "../../../components/elements";
import APIClient from "../../../APIClient";
import { useParams } from "react-router-dom";
import ManagerPageLayout from "../../../layouts/ManagerPageLayout";

function ManagerjourneyList() {
    const { Id } = useParams();
    const [journeylist, setJourneylist] = useState();
    const [leadstatusdatawithId, setLeadstatusdatawithId] = useState();

    // fetch lead Status
    const fetchLeadStatusId = () => {
        APIClient.get('sanctum/csrf-cookie')
        APIClient.get('api/leadstatus-list', {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token')
            }
        }).then(res => {
            const data = res.data.leadstatus_list;
            setLeadstatusdatawithId(data);
        })
            .catch(err => console.log(err))
    }

    // fetch Journeydata
    const fetchjourneydata = async () => {
        APIClient.get("sanctum/csrf-cookie").then((response) => {
            APIClient.get(`api/journey-list/` + Id, {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem('token')
                },
            }).then((journeyresdata) => {
                setJourneylist(journeyresdata);
            }
            );
        });
    };
    useEffect(() => {
        fetchLeadStatusId();
    }, [])
    useEffect(() => {
        fetchjourneydata();
    }, [Id])
    return (
        <>
            <ManagerPageLayout>
                <Row>
                    <Col xl="12" className="text-center py-5">
                        <h1>Journey History </h1>
                    </Col>
                </Row>
                
                {journeylist && journeylist.data.journey_list.length === 0 ? 
                <Row>
                     <Box className="mc-card">
                     <p className="text-center py-5"> No Record Found!</p>
                     </Box>
                    
                </Row>
                : 
                journeylist && journeylist.data.journey_list.map((item, index) => (
                    <Col xl={12} className="mb-3">
                        <Box className="mc-card">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Call Date</th>
                                                    <th scope="col"> Call Information</th>
                                                    <th scope="col"> Source</th>
                                                    <th scope="col"> Enquires Status</th>
                                                    <th scope="col"> Lead Status</th>
                                                    <th scope="col"> Potential</th>
                                                    <th scope="col"> Next Follow Up</th>
                                                </tr>
                                            </thead>
                                            <tbody>


                                                <tr key={index}>
                                                    <th scope="row">{item.call_date}</th>
                                                    <td>{item.information}</td>
                                                    <td>NILL</td>
                                                    <td>{item.enquiry_status}</td>
                                                    <td>
                                                        {leadstatusdatawithId && leadstatusdatawithId.map((item2) => (
                                                            item.lead_status == item2.id && (
                                                                item2.lead_status_name
                                                            )
                                                        ))}

                                                    </td>
                                                    <td>{item.lead_potential}</td>
                                                    <td>{item.next_followup_date}</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </Col>
                ))}
            </ManagerPageLayout>

        </>
    )
}

export default ManagerjourneyList