import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";
import { Button, Modal, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import APIClient from "../../APIClient";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import ListLoader from "../../components/SkeletonLoaderComponent/ListLoader";

export default function ManagerList() {
  const navigate = useNavigate();
  const [managerdata, setManagerdata] = useState({ manager_list: [] });
  const [managerdatadelete, setManagerdatadelete] = useState();
  const [showModal, setShowModal] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedManagerId, setSelectedManagerId] = useState(null);
  const [preselectedOptions, setPreselectedOptions] = useState([]);

  const [loading, setLoading] = useState(false);
  const fetchCounsellorGroupList = async () => {
    try {
      await APIClient.get("sanctum/csrf-cookie");
      const response = await APIClient.get("api/counsellorgroup-list", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      const formattedOptions = response.data.counsellor_group.map(
        (counsellorgroup) => ({
          value: counsellorgroup.id,
          label: counsellorgroup.group_name,
        })
      );
      setOptions(formattedOptions);
    } catch (error) {
      console.error("Error fetching counsellor Group list:", error);
    }
  };

  const fetchmanagerdata = async () => {
    setLoading(true);
    try {
      const csrfResponse = await APIClient.get("sanctum/csrf-cookie");
      if (csrfResponse.status === 204) {
        const getgroupResponse = await APIClient.get("api/manager-list", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        setLoading(false);
        setManagerdata(getgroupResponse.data);
        console.log(managerdata);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching group data:", error);
    }
  };

  const handleStatus = async (id, status) => {
    setLoading(true);
    try {
      const csrfResponse = await APIClient.get("sanctum/csrf-cookie");
      if (csrfResponse.status === 204) {
        await APIClient.post(
          "api/status-update-manager",
          { id, status: status === 1 ? 0 : 1 },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        setLoading(false);
        fetchmanagerdata();
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching group data:", error);
    }
  };

  const handeldelete = async (managerId) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    Swal.fire({
      title: "Are you sure?",
      text: "You won't to Delete Manager !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((delitem) => {
      if (delitem.isConfirmed) {
        APIClient.get("sanctum/csrf-cookie").then((response) => {
          APIClient.get(`api/delete-manager/${managerId}`).then(
            (delmanagerResponse) => {
              setManagerdatadelete(delmanagerResponse);
              fetchmanagerdata();
            }
          );
        });
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
      } else if (delitem.dismiss === Swal.DismissReason.cancel) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Delete Cancel :)",
          icon: "error",
        });
      }
    });
  };

  // assign group

  useEffect(() => {
    fetchmanagerdata();
    fetchCounsellorGroupList();
  }, []);

  useEffect(() => {
    if (
      !localStorage.getItem("token") ||
      localStorage.getItem("token") === null
    ) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <>
      <ToastContainer autoClose={1000} />
      <PageLayout>
        <Row>
          <Col xl={12}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Breadcrumb title={"Manager "}></Breadcrumb>
              <Link to="/add-manager">
                <Button className="common-btn">Add Manager</Button>
              </Link>
            </div>
          </Col>
          <Col xl={12}>
            <Box className="mc-card p-0">
              <Row xs={1} md={1} xl={1}>
                <Col xl={12}>
                  <Box className="mc-card">
                    <CardHeader title="Manager List" />
                    <div className="container-fluid">
                      <div className="row">
                        <ListLoader load={loading}>
                          <div className="col-12 overflow-auto">
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">S.no</th>
                                  <th scope="col">Manager Id</th>
                                  <th scope="col"> Name</th>
                                  {/* <th scope="col"> Email</th> */}
                                  <th scope="col"> Phone</th>
                                  <th scope="col"> Photo</th>
                                  <th scope="col"> address</th>
                                  <th scope="col"> username</th>
                                  <th scope="col"> Assign Groups</th>
                                  <th scope="col">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {managerdata.manager_list.length > 0 ? (
                                  managerdata.manager_list.map(
                                    (item, index) => (
                                      <tr key={item.manager_id}>
                                        <th scope="row">{index + 1}</th>
                                        <td>{item.manager_id}</td>
                                        <td>{item.manager_name}</td>
                                        {/* <td>{item.manager_email}</td>  */}
                                        <td>{item.manager_phone}</td>
                                        <td>
                                          <img
                                            src={item.manager_photo}
                                            width="100"
                                            height="100"
                                            alt="manger_avater"
                                            className="border p-3"
                                          />
                                        </td>
                                        <td>{item.manager_address}</td>
                                        <td>{item.manager_username}</td>
                                        <td>
                                          <div
                                            className="text-wrap"
                                            style={{ width: "10rem" }}
                                          >
                                            {Array.isArray(
                                              item.manager_group_name
                                            )
                                              ? item.manager_group_name.join(
                                                  " , "
                                                )
                                              : item.manager_group_name ||
                                                "N/A"}
                                          </div>
                                        </td>
                                        <td style={{ verticalAlign: "middle" }}>
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "start",
                                              alignItems: "center",
                                            }}
                                          >
                                            {item.manager_group_name ? (
                                              <Link
                                                to={`/editcounsellorgroupassign/${item.manager_id}`}
                                                style={{
                                                  width: "2rem",
                                                  fontSize: "0.9rem",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                  marginRight: "0.6rem",
                                                }}
                                                className="btn btn-warning"
                                              >
                                                <i className="fas fa-tasks"></i>
                                              </Link>
                                            ) : (
                                              <Link
                                                to={`/counsellorgroupassign/${item.manager_id}`}
                                                style={{
                                                  width: "2rem",
                                                  fontSize: "0.9rem",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                  marginRight: "0.6rem",
                                                }}
                                                className="btn btn-primary"
                                              >
                                                <i className="fas fa-tasks"></i>
                                              </Link>
                                            )}

                                            <Link
                                              to={`/manager-edit/${item.manager_id}`}
                                              style={{
                                                width: "2rem",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                marginRight: "0.6rem",
                                              }}
                                              className="btn btn-success"
                                            >
                                              <i className="fas fa-edit"></i>
                                            </Link>
                                            <div className="form-check form-switch">
                                              <input
                                                checked={
                                                  item.status === 1
                                                    ? true
                                                    : false
                                                }
                                                onClick={() =>
                                                  handleStatus(
                                                    item.manager_id,
                                                    item.status
                                                  )
                                                }
                                                className="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                id="flexSwitchCheckDefault"
                                              />
                                            </div>

                                            <button
                                              type="button"
                                              onClick={() =>
                                                handeldelete(item.manager_id)
                                              }
                                              style={{
                                                width: "2rem",
                                                fontSize: "0.6rem",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                              }}
                                              className="btn btn-danger"
                                            >
                                              <i className="fas fa-trash"></i>
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  )
                                ) : (
                                  <tr>
                                    <td colSpan="8" className="text-center">
                                      No managers found.
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </ListLoader>
                      </div>
                    </div>
                  </Box>
                </Col>
              </Row>
            </Box>
          </Col>
        </Row>
      </PageLayout>
    </>
  );
}
