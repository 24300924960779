import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";
import { Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import APIClient from "../../APIClient";
import { ToastContainer, toast } from "react-toastify";
import { useFormik } from "formik";
import { AddVendorGroupSchema } from "../schemas/AddVendorGroupSchema";

const AddVendorGroup = () => {
  const [options, setOptions] = useState([]);
  const navigate = useNavigate();

  const fetchVendorList = async () => {
    try {
      await APIClient.get("sanctum/csrf-cookie");
      const response = await APIClient.get("api/leadvendor-list", { 
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      const formattedOptions = response.data.leadvendor_list.map((vendor) => ({
        value: vendor.id,
        label: vendor.name,
      }));
      setOptions(formattedOptions);
    } catch (error) {
      console.error("Error fetching vendor list:", error);
    }
  };

  const initialValues = {
    group_name: "",
    vendor_ids: '',
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: AddVendorGroupSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const vendorIdsString = values.vendor_ids.join(",");
        const payload = {
          group_name: values.group_name,
          vendor_ids: vendorIdsString, 
        };
        
        await APIClient.get("sanctum/csrf-cookie");
        const response = await APIClient.post("api/add-vendorgroup", payload, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        toast.success(response.data.msg);
        setTimeout(() => {
          navigate("/vendor-group-list");
        }, 2000);
      } catch (error) {
        console.error("Error adding group:", error);
        toast.error("Failed to add vendor group");
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    fetchVendorList();
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <>
      <ToastContainer />
      <PageLayout>
        <Row>
          <Col xl={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Breadcrumb title={"Vendor Group"} />
              <Link to="/vendor-group-list">
                <Button className="btn btn-primary common-btn">
                  View Vendor Group
                </Button>
              </Link>
            </div>
          </Col>
          <Form onSubmit={handleSubmit}>
            <Col xl={12}>
              <Box className="mc-card">
                <Row xs={1} md={1} xl={1}>
                  <Col xl={6}>
                    <div style={{ marginTop: "0rem" }}>
                      <CardHeader title="Group Name" />
                      <TextField
                        id="outlined-required"
                        placeholder="Enter Group Name"
                        name="group_name"
                        onChange={handleChange}
                        value={values.group_name}
                        onBlur={handleBlur}
                        sx={{ width: "100%" }}
                      />
                      {errors.group_name && touched.group_name ? (
                        <p className="text-danger" style={{ fontSize: "13px" }}>
                          {errors.group_name}
                        </p>
                      ) : null}
                    </div>
                  </Col>

                  <Col xl={6}>
                    <div style={{ marginTop: "0rem" }}>
                      <CardHeader title="Select Vendors" />
                      <Select
                        options={options}
                        name="vendor_ids"
                        onChange={(selectedOptions) =>
                          setFieldValue(
                            "vendor_ids",
                            selectedOptions.map((option) => option.value)
                          )
                        }
                        isMulti
                      />
                      {errors.vendor_ids && touched.vendor_ids ? (
                        <p className="text-danger" style={{ fontSize: "13px" }}>
                          {errors.vendor_ids}
                        </p>
                      ) : null}
                    </div>
                  </Col>

                  <Col xl={3}>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "start",
                        alignItems: "center",
                        marginTop: "1.25rem",
                      }}
                    >
                      <Button
                        type="submit"
                        className="btn btn-primary common-btn"
                      >
                        Save
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Box>
            </Col>
          </Form>
        </Row>
      </PageLayout>
    </>
  );
};

export default AddVendorGroup;
