import React, { useContext, useEffect, useState } from "react";
import PageLayout from "../../../layouts/PageLayout";
import APIClient from "../../../APIClient";
import { useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { Box } from "../../../components/elements";
import { TotalEnquiryContext } from "../../../context/TotalEnquiryContext";

function ViewFollowup() {
  const { followUpList, followupTerm } = useContext(TotalEnquiryContext);
  console.log("followList",followUpList)
  // const { follow_up } = useParams();
  const [viewfollowup, setViewfollowup] = useState();
  // const payload = {comasep_call_status_ids:
  //   followUpList.followup_details?[()=>followupTerm === "follow_up" ? "followup_call_status_ids":"missfollowup_call_status_ids"].join(","),counsellor_id:5}
  function payloadData() {
    if (followupTerm === "follow_up") {
      return {
        comasep_call_status_ids:
          followUpList.followup_details?.followup_call_status_ids.join(","),
        // counsellor_id: 5,
      };
    }else{
      return {
        comasep_call_status_ids:
          followUpList.followup_details?.missfollowup_call_status_ids.join(","),
        // counsellor_id: 5,
      };
    }
  }

  // fetch view followup
  const fetchviewfollowup = async () => {
    APIClient.get("sanctum/csrf-cookie").then((response) => {
      APIClient.post(`api/followup-id-wise-profile`, payloadData(), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((res) => {
        setViewfollowup(res);
      });
    });
  };

  useEffect(() => {
    fetchviewfollowup();
  }, []);
  return (
    <>
      <PageLayout>
        <Row>
          <Col xl="12" className="text-center py-5">
            <h1>View Follow-Up</h1>
          </Col>
        </Row>
        {viewfollowup &&
          viewfollowup.profile_list?.map((item, index) => (
            <Col xl={12} className="mb-3">
              <Box className="mc-card">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">Call Date</th>
                            <th scope="col"> Call Information</th>
                            <th scope="col"> Source</th>
                            <th scope="col"> Enquires Status</th>
                            <th scope="col"> Lead Status</th>
                            <th scope="col"> Potential</th>
                            <th scope="col"> Next Follow Up</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr key={index}>
                            <th scope="row">{item.call_date}</th>
                            <td>{item.information}</td>
                            <td>NILL</td>
                            <td>{item.enquiry_status}</td>
                            <td></td>
                            <td>{item.lead_potential}</td>
                            <td>{item.next_followup_date}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </Box>
            </Col>
          ))}
      </PageLayout>
    </>
  );
}

export default ViewFollowup;
