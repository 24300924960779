import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb, DivideTitle } from "../../components";
import { CardHeader } from "../../components/cards";
import { LabelField,IconField,LabelTextarea, } from "../../components/fields";
import { Box, Item, Anchor, Text,Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";
import { Table, Thead, Tbody, Th, Tr, Td } from "../../components/elements/Table";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Link } from "react-router-dom";


export default function AddCounselling() {

    const [value, setValue] = React.useState(dayjs(new Date()));

    const handleChange = (newValue) => {
      setValue(newValue);
    };
    
    return (
        <PageLayout>
            <Row>
                <Col xl={12}>
                <div style={{display:"flex",justifyContent:"space-between",width:"100%"}}>
                    <Breadcrumb title={ "Add Counselling" }>
                    </Breadcrumb>
                    <Link to="/counselling-list"><Button className="btn btn-primary">View Counselling List</Button></Link>
                    </div>
                </Col>
                <Col xl={12}>
                    <Box className="mc-card">
                        <Row xs={1} md={1} xl={1}>
                            <Col xl={3}>

                                <div style={{marginTop:"2rem"}}>
                                <CardHeader title="Counselling Name" />
                                <TextField
                                  id="outlined-required"
                                  defaultValue=""
                                  placeholder="Enter Counselling Name"
                                  sx={{width:"100%"}}
                                />
                                </div>

                                </Col>


                                <Col xl={3}>

                                <div style={{marginTop:"2rem"}}>
                                <CardHeader title="Price" />
                                <TextField
                                  id="outlined-required"
                                  defaultValue=""
                                  placeholder="Enter Price of Counselling"
                                  sx={{width:"100%"}}
                                />
                                </div>

                                </Col>

                                <Col xl={3}>

                                <div style={{marginTop:"2rem"}}>
                                <CardHeader title="Choose Image" />
                                <TextField
                                  id="required"
                                  type="file"
                                  defaultValue=""
                                  placeholder="Enter Price of Package"
                                  sx={{width:"100%"}}
                                />
                                </div>

                                </Col>

                                <Col xl={3}>
                                <div style={{display:"flex",width:"100%", justifyContent:"start", alignItems:"center" ,marginTop:"5.25rem"}}>
                                <Link to="/counselling-list"><Button className="btn btn-primary">Add</Button></Link>
                              </div>
                                </Col>

                                
                        
                            {/*
                            <Col>
                                <DivideTitle title="large size" className="mb-4 mt-3" />
                                <LabelField label="text field" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" />
                                <LabelField label="select option" fieldSize="w-100 h-lg" labelDir="label-col mb-4" option={["admin", "member", "client"]} />
                                <LabelTextarea label="long textarea" fieldSize="w-100 h-text-lg" labelDir="label-col"  />
                            </Col> */}
                        </Row>
                    </Box>
                </Col>
            </Row>
        </PageLayout>
    )
}