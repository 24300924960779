import React from "react";
import { Box, Icon, Text } from "../elements";
import { Table, Thead, Tbody, Th, Tr, Td } from "../elements/Table";

export default function TrafficsTable({ thead, tbody }) {
    return (
        <Box className="mc-table-responsive">
            <Table className="mc-table">
                <Thead className="mc-table-head primary">
                    <Tr>
                        
            
                            <Th>{ "Board Name" }</Th>
                            
                            <Th>{ "Actions" }</Th>
                
                    </Tr>
                </Thead>
                <Tbody className="mc-table-body">
              
                        <Tr> 
                            <Td>
                                <Box className="mc-table-icon">
                                 
                                    <Text>{ "Board Name" }</Text>
                                </Box>
                            </Td>
                            {/* <Td>{ "item.users" }</Td>
                            <Td>{ "item.sessions" }</Td>
                            <Td>{ "item.bounce" }</Td>
                            <Td>{ "item.duration" }</Td> */}
                        </Tr>
              
                </Tbody>
            </Table>
        </Box>
    )
}