import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Profiletabcounsellor from "../formik_from/Profiletabcounsellor";
import CounsellorPageLayout from "../../../layouts/CousellorPageLayout";
import CallstatusCounsellor from "../formik_from/CallstatusCounsellor";
import Journeycounsellor from "../formik_from/Journeycounsellor";
import PageLayout from "../../../layouts/PageLayout";
import APIClient from "../../../APIClient";

function NewEnquiryedit() {
  const { Id } = useParams();
  const [coursedata, setCoursedata] = useState();
  const [Specializationdata, setSpecializationdata] = useState([]);
  const [calltypedata, setCalltypedata] = useState([]);
  const [universityList, setUniversityList] = useState([]);
  const [leadStatus, setleadStatus] = useState([]);
  const location = useLocation();
  const [key, setKey] = useState("profile");
  const adminPath = location.pathname.startsWith("/");
  const counsellorPath = location.pathname.startsWith("/counsellor");
  const navigate = useNavigate();
  const handleSelect = (k) => {
    setKey(k);
  };

  const handelactivekey = (currentkey) => {
    setKey(currentkey);
  };
  useEffect(() => {
    if (
      !localStorage.getItem("token") ||
      localStorage.getItem("token") === null
    ) {
      navigate("/counsellor");
    }
  }, []);

  const [initialValues, setInitialValues] = useState({
    profile_id: Id,
    name: "",
    age: "",
    dob: "",
    country: "",
    state: "",
    city: "",
    course_id: "",
    specialization_id: "",
    univercity_name: "",
    call_type_id: "",
    company: "",
    designation: "",
    experience: "",
    nationality: "",
    mobile: "",
    alt_mobile: "",
    email: "",
    alt_email: "",
    message: "",
  });

  const updatesinglevalu = () => {
    APIClient.get("sanctum/csrf-cookie");
    APIClient.get("api/single-lead-profile/" + Id, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        setInitialValues({
          name: res.data.profile_list[0].profile_name,
          age: res.data.profile_list[0].profile_age,
          dob: res.data.profile_list[0].profile_dob,
          country: res.data.profile_list[0].profile_country,
          state: res.data.profile_list[0].profile_state,
          city: res.data.profile_list[0].profile_city,
          course_id: res.data.profile_list[0].course_id,
          specialization_id: res.data.profile_list[0].specialization_id,
          univercity_name: res.data.profile_list[0].univercity_name,
          call_type_id: res.data.profile_list[0].call_type_id,
          company: res.data.profile_list[0].profile_company,
          designation: res.data.profile_list[0].profile_designation,
          experience: res.data.profile_list[0].profile_experience,
          nationality: res.data.profile_list[0].profile_nationality,
          mobile: res.data.profile_list[0].profile_mobile,
          alt_mobile: res.data.profile_list[0].profile_alt_mobile,
          email: res.data.profile_list[0].profile_email,
          alt_email: res.data.profile_list[0].profile_alt_email,
          message: res.data.profile_list[0].profile_messge,
        });
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    updatesinglevalu();
  }, [Id]);
  // fetch course list
  const fetchCoursedata = async () => {
    APIClient.get("sanctum/csrf-cookie").then((response) => {
      APIClient.get("api/course-list", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((getCourseResponse) => {
        setCoursedata(getCourseResponse);
      });
    });
  };
  useEffect(() => {
    fetchCoursedata();
  }, []);
  // fetch Specialization List
  const fetchSpecializationdata = async () => {
    APIClient.get("sanctum/csrf-cookie");
    APIClient.get("api/specialization-list", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        const data = res.data.specialization_list;
        setSpecializationdata(data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    fetchSpecializationdata();
  }, []);
  // fetch call type list
  const fetchCallTypedata = async () => {
    APIClient.get("sanctum/csrf-cookie");
    APIClient.get("api/calltype-list", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        const data = res.data.calltype_list;
        setCalltypedata(data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    fetchCallTypedata();
  }, []);
  // fetch university list
  const fetchuniversitydata = async () => {
    APIClient.get("sanctum/csrf-cookie");
    APIClient.get("api/univercity-list", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        const data = res.data.univercity_list;
        setUniversityList(data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchuniversitydata();
  }, []);

  // fetch lead Status
  const fetchLeadStatus = async () => {
    APIClient.get("sanctum/csrf-cookie");
    APIClient.get("api/leadstatus-list", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        const data = res.data.leadstatus_list;
        setleadStatus(data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    fetchLeadStatus();
  }, []);

  return (
    <>
      <ToastContainer />
      {adminPath && !counsellorPath && (
        <PageLayout>
          <Row>
            <Col>
              <Tabs activeKey={key} onSelect={handleSelect} className="mb-3">
                <Tab eventKey="profile" title="Profile">
                  <Profiletabcounsellor
                    activekey={handelactivekey}
                    universityList={universityList}
                    Specializationdata={Specializationdata}
                    coursedata={coursedata}
                    initialValues={initialValues}
                  />
                </Tab>
                <Tab eventKey="Call Status" title="Call Status">
                  <CallstatusCounsellor
                    activekey={handelactivekey}
                    leadStatus={leadStatus}
                  />
                </Tab>
                <Tab eventKey="Journey" title="Journey">
                  <Journeycounsellor count={key} leadstatusdatawithId={leadStatus}/>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </PageLayout>
      )}
      {counsellorPath && (
        <CounsellorPageLayout>
          <Row>
            <Col>
              <Tabs activeKey={key} onSelect={handleSelect} className="mb-3">
                <Tab eventKey="profile" title="Profile">
                  <Profiletabcounsellor
                    activekey={handelactivekey}
                    universityList={universityList}
                    Specializationdata={Specializationdata}
                    coursedata={coursedata}
                    initialValues={initialValues}
                    leadStatus={leadStatus}
                  />
                </Tab>
                <Tab eventKey="Call Status" title="Call Status">
                  <CallstatusCounsellor activekey={handelactivekey} leadStatus={leadStatus}/>
                </Tab>
                <Tab eventKey="Journey" title="Journey">
                  <Journeycounsellor count={key} leadstatusdatawithId={leadStatus}/>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </CounsellorPageLayout>
      )}
    </>
  );
}

export default NewEnquiryedit;
