export const Column = [
  "Sr. No",
  "Name Of Student",
  "Counsellor Name",
  "Vendor Code",
  "Last Lead Status",
  "Last Call Information",
  "Last Call Type",
  "Last Call Note In Journey",
  "Next Follow Up Date",
];
