import React from "react";
import Skeleton from "react-loading-skeleton";

const PieChartLoader = ({ children, load }) => {
  return (
    <>
      { load === true ? (
        <div style={{height:"400px",width:"300px"}}>
          <Skeleton circle height={"300px"} width={"100%"} />
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default PieChartLoader;
