import React, { useContext, useEffect, useState } from "react";
import Main from "./Mian";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";
import { DrawerProvider } from "../context/Drawer";
import CounsellorHeader from "./CounsellorHeader";
import CounsellorSidebar from "./CounsellorSidebar";
import APIClient from "../APIClient";
import { TotalEnquiryContext } from "../context/TotalEnquiryContext";

export default function CounsellorPageLayout({ children }) {
  const location = useLocation();

  return (
    <>
      <DrawerProvider>
        <CounsellorHeader />
        <CounsellorSidebar />
        <Main>
          <>
            {/* <VendorMainContent /> */}
            {children}
            {location.pathname !== "/message" ? <Footer /> : ""}
          </>
        </Main>
      </DrawerProvider>
    </>
  );
}
