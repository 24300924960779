import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Item, Anchor, Text } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";
import { Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import APIClient from "../../APIClient";
import Swal from "sweetalert2";

export default function GroupList() {
  const navigate = useNavigate();
  const {Id}=useParams();
  const [groupdata, setgroupdata] = useState();
  const [groupdatadelete, setgroupdatadelete] = useState();


  const fetchgroupdata = async () => {
    APIClient.get("sanctum/csrf-cookie").then((response) => {
      APIClient.get("api/group-list",{
        headers: {
          "Authorization": "Bearer "+ localStorage.getItem('token')
      },
      }).then((getgroupResponse) => {
        setgroupdata(getgroupResponse);
      });
    });
  };

  const handeldelete = async (groupId) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
    });
    Swal.fire({
      title: "Are you sure?",
      text: "You won't to Delete Group !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((delitem) => {
      if (delitem.isConfirmed) {
        APIClient.get("sanctum/csrf-cookie").then((response) => {
          APIClient.get(`api/delete-group/${groupId}`).then(
            (delgroupResponse) => {
              setgroupdatadelete(delgroupResponse);
              fetchgroupdata();
            }
          );
        });
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success"
        });
      } else if (
        delitem.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Delete Cancel :)",
          icon: "error"
        });
      }
    });
  };

  
  useEffect(() => {
    fetchgroupdata();

  }, []);

  useEffect(() => {
    if (!localStorage.getItem('token') || localStorage.getItem('token') === null) {
        navigate('/login')
    }
}, [])
  return (
    <>
    <ToastContainer autoClose={1000} />
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Breadcrumb title={"Group List"}></Breadcrumb>
            <Link to="/add-group">
              <Button className="common-btn">ADD GROUP</Button>
            </Link>
          </div>
        </Col>
        <Col xl={12}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
              <Col xl={12}>
                <Box className="mc-card border-0 p-0">
                  <CardHeader title="Group List" />
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 overflow-auto">
                        <table className="table table-bordered ">
                          <thead>
                            <tr>
                              <th scope="col">S.no</th>
                              <th scope="col"> Group Name</th>
                              <th scope="col">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {groupdata &&
                              groupdata.data.group_list.map(
                                (item, index) => (
                                  <tr key={item.id}>
                                    <td scope="row">{index + 1}</td>
                                    <td>{item.group_name}</td>
                                    <td
                                      style={{
                                        display: "flex",
                                        justifyContent: "start",
                                      }}
                                    >
                                      <Link
                                        to={`/editgroupitem/${item.id}`}
                                        style={{
                                          width: "2rem",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          marginRight: "1.6rem",
                                        }}
                                        className="btn btn-success"
                                      >
                                        <i className="fas fa-edit"></i>
                                      </Link>

                                      <button
                                        type="button"
                                        onClick={() => handeldelete(item.id)}
                                        style={{
                                          width: "2rem",
                                          fontSize: "0.6rem",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                        className="btn btn-danger"
                                      >
                                        <i className="fas fa-trash"></i>
                                      </button>
                                    </td>
                                  </tr>
                                )
                              )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </Box>
              </Col>
            </Row>
          </Box>
        </Col>
      </Row>
    </PageLayout>
    </>
  );
}
