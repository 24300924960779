import React, { useEffect, useState } from "react";
import { Box, Anchor } from "../elements";
import APIClient from "../../APIClient";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function Logout({ data }) {
    const [redirectTo, setRedirectTo] = useState(null);
    const navigate = useNavigate();
    const RoleTypes = {
        USER: "1",
        Councellor: "2",
        Manager: "3",
        VENDOR: "4",
    };
    const CMLogout =  () => {

        APIClient.get('sanctum/csrf-cookie').then(response => {
            APIClient.post('api/logout',{},{
                headers: {
                    "Authorization": "Bearer "+ localStorage.getItem('token') 
                }
            }).then(logoutresponse => {
                toast.success(logoutresponse.data.data);
                let newRoleTypes = localStorage.getItem('role_type');
                localStorage.clear();
                setTimeout(() => {
                    if (newRoleTypes == RoleTypes.USER) {
                        setRedirectTo('/login');
                    } 
                    
                    if (newRoleTypes == RoleTypes.VENDOR) {
                        setRedirectTo('/vendor/');
                    }
    
                    if (newRoleTypes == RoleTypes.Councellor) {
                        setRedirectTo('/counsellor/');
                    }
                    if (newRoleTypes == RoleTypes.Manager) {
                        setRedirectTo('/manager/');
                    }
                }, 2000);
            });
        });
    }
    useEffect(() => {
        if (redirectTo) {
            navigate(redirectTo);
        }
      }, [redirectTo, navigate]);
    return (
        <Box className="mc-sidebar-logout mt-3 text-center ">
            <Anchor 
                onClick={CMLogout}
                // href = { data?.path } 
                icon = { data?.icon } 
                text = { data?.text } 
                className = "mc-btn primary common-btn sm"
            />
        </Box>
    )
}