import React from "react";

export default function Button({
  isCount = false,
  count,
  type,
  onClick,
  className,
  icon,
  iconClass,
  text,
  badge,
  arrow,
  children,
}) {
  return (
    <button type={type || "button"} onClick={onClick} className={className}>
      {icon || iconClass ? (
        <i className={iconClass || "material-icons"}>{icon}</i>
      ) : (
        <></>
      )}
      {text && <span>{text}</span>}
      {badge && <sup className={badge.variant}>{badge.text}</sup>}
      {arrow && <small className="material-icons">{arrow}</small>}
      {isCount && (
        <span
          className="notificationCount"
          style={{
            position: "absolute",
            fontSize: "12px",
            color: "#fff",
            padding: "8px 5px",
            left: "11px",
            borderRadius: "50%",
            backgroundColor: "#00f",
          }}
        >
          {count}
        </span>
      )}
      {children}
    </button>
  );
}
