import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Breadcrumb} from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { Link, useNavigate } from "react-router-dom";
import { addCounsellorSchema } from "../schemas/addCounsellorSchema";
import APIClient from "../../APIClient";
import { useFormik } from "formik";
import { AddCourseSchema } from "../schemas/AddCourseSchema";
import { ToastContainer, toast } from "react-toastify";

export default function AddCourse() {
  const initialValues = {
    course_name:""
  }
  const navigate = useNavigate();

  const {values, errors, touched, handleBlur, handleChange, handleSubmit} = useFormik({
    initialValues: initialValues,
    validationSchema:AddCourseSchema,
    onSubmit: (values, { setSubmitting }) => {
      APIClient.get('sanctum/csrf-cookie')
        .then(response => {
          return APIClient.post('api/add-course', values, {
            headers: {
              "Content-Type": "multipart/form-data",
              "Accept": "multipart/form-data",
              "Authorization": "Bearer " + localStorage.getItem('token')
            }
          });
        })
        .then(addcourseResponse => {
          toast.success(addcourseResponse.data.msg);
          setTimeout(() => {
            navigate('/course-list');
          }, 2000);
        })
        .catch(error => {
          console.error("Error adding course:", error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  })
  
  useEffect(() => {
    if (!localStorage.getItem('token') || localStorage.getItem('token') === null) {
        navigate('/login')
    }
}, [])
  return (
    <>
    <ToastContainer autoClose={1000} />
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Breadcrumb title={"Add Course"}></Breadcrumb>
            <Link to="/course-list">
              <Button className="btn btn-primary common-btn">View Course</Button>
            </Link>
          </div>
        </Col>
        <Col xl={7}>
          <Box className="mc-card">
            <Form onSubmit={handleSubmit}>
            <Row xs={1} md={1} xl={1}>
              <Col xl={12}>
                <div style={{ marginTop: "1rem" }}>
                  <CardHeader title="Course Name" />
                  <FormControl sx={{ width: "100%" }}>
                    <TextField
                      id="outlined-required"
                      placeholder="Enter Course Name"
                      name="course_name"
                      onChange={handleChange}
                      value={values.course_name}
                      onBlur={handleBlur}
                      sx={{ width: "100%" }}
                    />
                    {errors.course_name && touched.course_name ? 
                    <p className="text-danger" style={{fontSize:"13px"}}>{errors.course_name}</p>
                   : null}
                  </FormControl>
                </div>
              </Col>

              <Col xl={3}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "start",
                    alignItems: "center",
                    marginTop: "1.25rem",
                  }}
                >
                  
                    <Button type="submit" className="btn btn-primary common-btn">Add Course</Button>
                  
                </div>
              </Col>
            </Row>
            </Form>
          </Box>
        </Col>
      </Row>
    </PageLayout>
    </>
  );
}
