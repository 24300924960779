import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";
import TextField from "@mui/material/TextField";
import { Link, useNavigate } from "react-router-dom";
import APIClient from "../../APIClient";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import { addCounsellorSchema } from "../schemas/addCounsellorSchema";




export default function AddCounsellor() {

  const navigate = useNavigate();
  const initialValues = {
    name:"",
    email:"",
    phone:"",
    address:"",
    username:"",
    password:"",
  }


  // srt Validation 
 const {values, errors, touched, handleBlur, handleChange, handleSubmit} = useFormik({
    initialValues: initialValues,
    validationSchema:addCounsellorSchema,
    onSubmit: (values, { setSubmitting }) => {
      console.log(values);
      APIClient.get('sanctum/csrf-cookie')
        .then(response => {
          return APIClient.post('api/add-councellor', values, {
            headers: {
              "Content-Type": "multipart/form-data",
              "Accept": "multipart/form-data",
              "Authorization": "Bearer " + localStorage.getItem('token')
            }
          });
        })
        .then(addcouncellorResponse => {
          toast.success(addcouncellorResponse.data.msg);
          setTimeout(() => {
            navigate('/Counsellor-list');
        }, 2000);
        })
        .catch(error => {
          console.error("Error adding counsellor:", error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  })
 

  


  useEffect(() => {
    if (!localStorage.getItem('token') || localStorage.getItem('token') === null) {
      navigate('/login')
    }else{

    }
  }, [])
  return (
    <>
    <ToastContainer  />
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Breadcrumb title={"New Counsellor"}></Breadcrumb>
            <Link to="/Counsellor-list">
              <Button className="btn btn-primary common-btn">
                View Counsellor
              </Button>
            </Link>
          </div>
        </Col>
        <Col xl={12}>
          <Box className="mc-card">
            <Form onSubmit={handleSubmit} encType="multipart/form-data">
            <Row xs={1} md={1} xl={1}>
              <Col xl={6}>
                <div style={{ marginTop: "1rem" }}>
                  <CardHeader title="Name" />
                  <TextField
                    // name="counsellorName"
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    onBlur={handleBlur}
                    placeholder="Enter Name"
                    sx={{ width: "100%" }}
                  />
                  {errors.name && touched.name ? 
                    <p className="text-danger" style={{fontSize:"13px"}}>{errors.name}</p>
                   : null}
                  
                </div>
              </Col>

              <Col xl={6}>
                <div style={{ marginTop: "1rem" }}>
                  <CardHeader title="Email" />
                  <TextField
                    // name="counsellorEmail"
                    name="email"
                    placeholder="Enter Email"
                    type="email"
                    onChange={handleChange}
                    value={values.email}
                    onBlur={handleBlur}
                    sx={{ width: "100%" }}
                  />
                   {errors.email && touched.email ? 
                   <p className="text-danger" style={{fontSize:"13px"}}>{errors.email}</p>
                  : null}
                </div>
              </Col>

              <Col xl={6}>
                <div style={{ marginTop: "0rem" }}>
                  <CardHeader title="Phone" />
                  <TextField
                    // name="counsellorPhone"
                    name="phone"
                    onChange={handleChange}
                    value={values.phone}
                    onBlur={handleBlur}
                    placeholder="Enter Phone Number"
                    type="number"
                    sx={{ width: "100%" }}
                  />
                  
                  {errors.phone && touched.phone ? 
                    <p className="text-danger" style={{fontSize:"13px"}}>{errors.phone}</p>
                   : null}
                </div>
              </Col>

              <Col xl={6}>
                <div style={{ marginTop: "0rem" }}>
                  <CardHeader title="Photo" />
                  <TextField
                    // name="photo"
                    onChange={handleChange}
                    value={values.photo}
                    onBlur={handleBlur}
                    type="file"
                    sx={{ width: "100%" }}
                  />
                  {errors.photo && touched.photo ? 
                    <p className="text-danger" style={{fontSize:"13px"}}>{errors.photo}</p>
                   : null}
                </div>
              </Col>

              <Col xl={12}>
                <div style={{ marginTop: "0rem" }}>
                  <CardHeader title="Address" />
                  <TextField
                    onChange={handleChange}
                    value={values.address}
                    onBlur={handleBlur}
                    // name="counsellorAddress"
                    name="address"
                    multiline
                    rows={4}
                    sx={{ width: "100%" }}
                  />
                  {errors.address && touched.address ? 
                    <p className="text-danger" style={{fontSize:"13px"}}>{errors.address}</p>
                   : null}
                </div>
              </Col>
              <Col xl={6}>
                <div style={{ marginTop: "0rem" }}>
                  <CardHeader title="Username" />
                  <TextField
                    // name="counsellorUsername"
                    name="username"
                    onChange={handleChange}
                    value={values.username}
                    onBlur={handleBlur}
                    type="text"
                    sx={{ width: "100%" }}
                    placeholder="Enter Username"
                  />
                 {errors.username && touched.username ? 
                    <p className="text-danger" style={{fontSize:"13px"}}>{errors.username}</p>
                   : null}
                </div>
              </Col>
              <Col xl={6}>
                <div style={{ marginTop: "0rem" }}>
                  <CardHeader title="Password" />
                  <TextField
                    // name="counsellorPassword"
                    name="password"
                    onChange={handleChange}
                    value={values.password}
                    onBlur={handleBlur}
                    type="password"
                    sx={{ width: "100%" }}
                    placeholder="Enter Password"
                  />
                  {errors.password && touched.password ? 
                    <p className="text-danger" style={{fontSize:"13px"}}>{errors.password}</p>
                   : null}
                </div>
              </Col>

              <Col xl={3}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "start",
                    alignItems: "center",
                    marginTop: "1.25rem",
                  }}
                >
                  <Button type="submit" className="btn btn-primary common-btn">
                    Add Counsellor
                  </Button>
                </div>
              </Col>
            </Row>
            </Form>
          </Box>
        </Col>
      </Row>
    </PageLayout>
    </>
   
  );
}
