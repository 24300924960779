import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb, DivideTitle } from "../../components";
import { CardHeader } from "../../components/cards";
import { LabelField,IconField,LabelTextarea, } from "../../components/fields";
import { Box, Item, Anchor, Text,Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";
import { Table, Thead, Tbody, Th, Tr, Td } from "../../components/elements/Table";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';

import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';




import Stack from '@mui/joy/Stack';

import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import { FormControlLabel } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import { Link } from "react-router-dom";


export default function CounsellingList() {

    const [value, setValue] = React.useState(dayjs(new Date()));

    const [openAssignPackage, setOpenAssignPackage] = useState(false);

    const handleChange = (newValue) => {
      setValue(newValue);
    };
    
    return (
        <PageLayout>
            <Row>
                <Col xl={12}>
                <div style={{display:"flex",justifyContent:"space-between",width:"100%"}}>
                    <Breadcrumb title={ "Counselling List" }>
                    </Breadcrumb>
                    <Link to="/add-counselling"><Button className="btn btn-primary">Add Counselling</Button></Link>
                    </div>
                </Col>
                <Col xl={12}>
                    <Box className="mc-card">
                        <Row xs={1} md={1} xl={1}>

                                
                             <Col xl={12}>

                             <Box className="mc-card">
                             <CardHeader title="Counselling List" />
                             <div className="container-fluid">
                              <div className="row">
                                <div className="col-12">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th scope="col">S.no</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Image</th>
                                        <th scope="col">Price</th>
                                        <th scope="col">Package Assigned</th>
                                        <th scope="col">Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <th scope="row">&nbsp; 1</th>
                                        <td>
                                        Free Career Talk
                                        </td>
                                        <td><img style={{maxWidth:"4.5rem",maxHeight:"4.5rem", objectFit:"cover"}} src="https://images.hindustantimes.com/rf/image_size_800x600/HT/p2/2015/12/01/Pictures/_c34102da-9849-11e5-b4f4-1b7a09ed2cea.jpg"></img></td>
                                        <td>200</td>
                                        <td>
                                        <div style={{display:"flex",width:"100%", justifyContent:"space-evenly", alignItems:"center"}}>
                                        <Button className="btn btn-primary" size="small" onClick={() => setOpenAssignPackage(true)}>Assign</Button>
                                            {/* Modal For Package Assign */}

                                        
                                          <Modal
                                         aria-labelledby="modal-title"
                                         aria-describedby="modal-desc"
                                         open={openAssignPackage}
    
                                         onClose={() => setOpenAssignPackage(false)}
                                         sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex:"1000" }}
                                       >
                                         <Sheet
                                           variant="outlined"
                                           sx={{
                                             width: '50%',
                                             borderRadius: 'md',
                                             p: 3,
                                             boxShadow: 'lg',
                                             bgcolor:"#fff"
                                           }}
                                         >
                                           <ModalClose
                                             variant="outlined"
                                             sx={{
                                               top: 'calc(-1/4 * var(--IconButton-size))',
                                               right: 'calc(-1/4 * var(--IconButton-size))',
                                               boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                                               borderRadius: '50%',
                                               bgcolor: '#eee',
                                             }}
                                           />
                                            <Typography
                                             id="basic-modal-dialog-title"
                                             component="h2"
                                             level="inherit"
                                             fontSize="1.25em"
                                             mb={1}
                                           >
                                             Assign Package
                                           </Typography>

                                           <Stack spacing={2}>

                                           <div style={{marginTop:"2rem"}}>
                                          <CardHeader title="Choose Package" />
                                          <Stack>
                                          <FormControlLabel
                                           value="360"
                                           control={<Checkbox />}
                                           label="360 Package"
                                           labelPlacement="end"
                                         />
                                         <FormControlLabel
                                           value="Test"
                                           control={<Checkbox />}
                                           label="Test Package"
                                           labelPlacement="end"
                                         />
                                         </Stack>
                                          </div>


                                          <div style={{width:"100%",display:"flex", justifyContent:"center",marginTop:"2rem"}}>
                                          <Button className="btn btn-primary" sx={{width:"8rem"}} type="submit">Submit</Button>
                                          </div>
                                          </Stack>


                                         </Sheet>
                                        </Modal>
                                        </div>
                                        </td>
                                        <td style={{display:"flex",justifyContent:"start"}}>
                                          <button type="button" style={{width:"2rem",display:"flex",justifyContent:"center",alignItems:"center",marginRight:"1.6rem"}} className="btn btn-success"><i className="fas fa-edit"></i></button>
                                          <button type="button" style={{width:"4rem",fontSize:"0.6rem",display:"flex",justifyContent:"center",alignItems:"center",marginRight:"1.6rem"}} className="btn btn-danger">Inactive</button>
                                          <button type="button" style={{width:"2rem",fontSize:"0.6rem",display:"flex",justifyContent:"center",alignItems:"center"}} className="btn btn-danger"><i className="fas fa-trash"></i></button>
                                        </td>
                                      </tr>
                                      <tr>
                                        <th scope="row">&nbsp; 2</th>
                                        <td>
                                        Group Counselling
                                        </td>
                                        <td><img style={{maxWidth:"4.5rem",maxHeight:"4.5rem", objectFit:"cover"}} src="https://images.hindustantimes.com/rf/image_size_800x600/HT/p2/2015/12/01/Pictures/_c34102da-9849-11e5-b4f4-1b7a09ed2cea.jpg"></img></td>
                                        <td>350</td>
                                        <td>
                                        <div style={{display:"flex",width:"100%", justifyContent:"space-evenly", alignItems:"center"}}>
                                        <Button className="btn btn-primary"size="small" onClick={() => setOpenAssignPackage(true)}>Assign</Button>
                                            {/* Modal For Package Assign */}

                                        
                                          <Modal
                                         aria-labelledby="modal-title"
                                         aria-describedby="modal-desc"
                                         open={openAssignPackage}
    
                                         onClose={() => setOpenAssignPackage(false)}
                                         sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex:"1000" }}
                                       >
                                         <Sheet
                                           variant="outlined"
                                           sx={{
                                             width: '50%',
                                             borderRadius: 'md',
                                             p: 3,
                                             boxShadow: 'lg',
                                             bgcolor:"#fff"
                                           }}
                                         >
                                           <ModalClose
                                             variant="outlined"
                                             sx={{
                                               top: 'calc(-1/4 * var(--IconButton-size))',
                                               right: 'calc(-1/4 * var(--IconButton-size))',
                                               boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                                               borderRadius: '50%',
                                               bgcolor: '#eee',
                                             }}
                                           />
                                            <Typography
                                             id="basic-modal-dialog-title"
                                             component="h2"
                                             level="inherit"
                                             fontSize="1.25em"
                                             mb={1}
                                           >
                                             Assign Package
                                           </Typography>

                                           <Stack spacing={2}>

                                           <div style={{marginTop:"2rem"}}>
                                          <CardHeader title="Choose Package" />
                                          <Stack>
                                          <FormControlLabel
                                           value="360"
                                           control={<Checkbox />}
                                           label="360 Package"
                                           labelPlacement="end"
                                         />
                                         <FormControlLabel
                                           value="Test"
                                           control={<Checkbox />}
                                           label="Test Package"
                                           labelPlacement="end"
                                         />
                                         </Stack>
                                          </div>


                                          <div style={{width:"100%",display:"flex", justifyContent:"center",marginTop:"2rem"}}>
                                          <Button className="btn btn-primary" sx={{width:"8rem"}} type="submit">Submit</Button>
                                          </div>
                                          </Stack>


                                         </Sheet>
                                        </Modal>
                                        </div>
                                        </td>
                                        <td style={{display:"flex",justifyContent:"start"}}>
                                          <button type="button" style={{width:"2rem",display:"flex",justifyContent:"center",alignItems:"center",marginRight:"1.6rem"}} className="btn btn-success"><i className="fas fa-edit"></i></button>
                                          <button type="button" style={{width:"4rem",fontSize:"0.6rem",display:"flex",justifyContent:"center",alignItems:"center",marginRight:"1.6rem"}} className="btn btn-danger">Inactive</button>
                                          <button type="button" style={{width:"2rem",fontSize:"0.6rem",display:"flex",justifyContent:"center",alignItems:"center"}} className="btn btn-danger"><i className="fas fa-trash"></i></button>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                                                        
                            </Box>
                            </Col>
                            {/*
                            <Col>
                                <DivideTitle title="large size" className="mb-4 mt-3" />
                                <LabelField label="text field" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" />
                                <LabelField label="select option" fieldSize="w-100 h-lg" labelDir="label-col mb-4" option={["admin", "member", "client"]} />
                                <LabelTextarea label="long textarea" fieldSize="w-100 h-text-lg" labelDir="label-col"  />
                            </Col> */}
                        </Row>
                    </Box>
                </Col>
            </Row>
        </PageLayout>
    )
}