import * as Yup from 'yup';

export const AddCounsellorGroupSchema = Yup.object().shape({
    group_name: Yup.string()
        .required('Group name is required')
        .min(3, 'Group name must be at least 3 characters long')
        .max(50, 'Group name must not exceed 50 characters'),
    counsellor_ids: Yup.array()
        .min(1, 'At least one vendor must be selected')
        .of(Yup.number().required('Counsellor ID is required'))
});