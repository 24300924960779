import Axios from "axios";

const APIClient = Axios.create({
    baseURL: process.env.REACT_APP_BACKEND_BASE_PATH,
    // withCredentials: true,
    'Access-Control-Allow-Credentials':true,
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": "Bearer "+ localStorage.getItem('token')
    },
});
export default APIClient;