import React from "react";
import { EnquiryProvider } from "../../context/Enquiry";
import VendorpageLayout from "../../layouts/VendorpageLayout";
import VendorDashboard from "../../components/vendor/VendorDashboard";

export default function VendorDashbord() {

  return (
    <>
      <EnquiryProvider>
        <VendorpageLayout>
          <VendorDashboard />
        </VendorpageLayout>
      </EnquiryProvider>
    </>
  );
}
