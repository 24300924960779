import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb, DivideTitle } from "../../components";
import { CardHeader } from "../../components/cards";
import { LabelField,IconField,LabelTextarea, } from "../../components/fields";
import { Box, Item, Anchor, Text,Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";
import { Table, Thead, Tbody, Th, Tr, Td } from "../../components/elements/Table";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Link } from "react-router-dom";


export default function SchoolsList() {

    const [value, setValue] = React.useState(dayjs(new Date()));

    const handleChange = (newValue) => {
      setValue(newValue);
    };
    
    return (
        <PageLayout>
            <Row>
                <Col xl={12}>
                <div style={{width:"100%",display:"flex",justifyContent:"space-between"}}>
                    <Breadcrumb title={ "Schools List" }>
                    </Breadcrumb>
                    <Link to="/add-school"><Button className="btn btn-primary">ADD SCHOOL</Button></Link>
                    
                </div>
                </Col>
                <Col xl={12}>
                    <Box className="mc-card">
                        <Row xs={1} md={1} xl={1}>


                                
                             <Col xl={12}>

                             <Box className="mc-card">
                             <CardHeader title="Schools List" />
                             <div className="container">
                              <div className="row">
                                <div className="col-12">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th scope="col">S.no</th>
                                        <th scope="col">Board Name</th>
                                        <th scope="col">School Name</th>
                                        <th scope="col">Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <th scope="row">&nbsp; 1</th>
                                        <td>
                                        ICSE
                                        </td>
                                        <td>
                                        St. Stephens
                                        </td>
                                       
                                        <td style={{display:"flex",justifyContent:"start"}}>
                                          <button type="button" style={{width:"2rem",display:"flex",justifyContent:"center",alignItems:"center",marginRight:"1.6rem"}} className="btn btn-success"><i className="fas fa-edit"></i></button>
                                          <button type="button" style={{width:"4rem",fontSize:"0.6rem",display:"flex",justifyContent:"center",alignItems:"center",marginRight:"1.6rem"}} className="btn btn-danger">Inactive</button>
                                          <button type="button" style={{width:"2rem",fontSize:"0.6rem",display:"flex",justifyContent:"center",alignItems:"center"}} className="btn btn-danger"><i className="fas fa-trash"></i></button>
                                        </td>
                                      </tr>
                                      <tr>
                                        <th scope="row">&nbsp; 2</th>
                                        <td>
                                        CBSE
                                        </td>
                                        <td>
                                        Don Bosco
                                        </td>
                                       
                                        <td style={{display:"flex",justifyContent:"start"}}>
                                          <button type="button" style={{width:"2rem",display:"flex",justifyContent:"center",alignItems:"center",marginRight:"1.6rem"}} className="btn btn-success"><i className="fas fa-edit"></i></button>
                                          <button type="button" style={{width:"4rem",fontSize:"0.6rem",display:"flex",justifyContent:"center",alignItems:"center",marginRight:"1.6rem"}} className="btn btn-danger">Inactive</button>
                                          <button type="button" style={{width:"2rem",fontSize:"0.6rem",display:"flex",justifyContent:"center",alignItems:"center"}} className="btn btn-danger"><i className="fas fa-trash"></i></button>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                                                        
                            </Box>
                            </Col>
                            {/*
                            <Col>
                                <DivideTitle title="large size" className="mb-4 mt-3" />
                                <LabelField label="text field" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" />
                                <LabelField label="select option" fieldSize="w-100 h-lg" labelDir="label-col mb-4" option={["admin", "member", "client"]} />
                                <LabelTextarea label="long textarea" fieldSize="w-100 h-text-lg" labelDir="label-col"  />
                            </Col> */}
                        </Row>
                    </Box>
                </Col>
            </Row>
        </PageLayout>
    )
}