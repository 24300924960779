import React, { useContext, useEffect, useState } from "react";
import APIClient from "../../../APIClient";
import { useNavigate, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { Form } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { AddCallStatusTabSchema } from "../../schemas/AddCallStatusTabSchema";
import { TotalEnquiryContext } from "../../../context/TotalEnquiryContext";

function CallstatusCounsellor({ activekey,leadStatus }) {
  const { Id } = useParams();
  const {setRefresh,refresh}=useContext(TotalEnquiryContext)
 
  const [leadProtential, setLeadProtential] = useState([]);
  const [enquiryStatus, setEnquiryStatus] = useState([]);
  const [callinfodata, setCallinfodata] = useState([]);
  const [calltypedata, setCalltypedata] = useState([]);
  const [isAdmissionDone, setIsAdmissionDone] = useState(false);
  const [universityList, setUniversityList] = useState([]);

 
  const fetchCallTypedata = async () => {
    APIClient.get("sanctum/csrf-cookie");
    APIClient.get("api/calltype-list", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        const data = res.data.calltype_list;
        setCalltypedata(data);
      })
      .catch((err) => console.log(err));
  };
  //   fetch Lead Protential
  const fetchLeadProtential = async () => {
    APIClient.get("sanctum/csrf-cookie");
    APIClient.get("api/leadpotential-list", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        const data = res.data.lead_potential_list;
        setLeadProtential(data);
      })
      .catch((err) => console.log(err));
  };

  // fetch enquiry status
  const fetchEnquiryStatus = async () => {
    APIClient.get("sanctum/csrf-cookie");
    APIClient.get("api/enquerytatus-list", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        const data = res.data.enquerystatus_list;
        setEnquiryStatus(data);
      })
      .catch((err) => console.log(err));
  };
  //   fetch call information
  const fetchCallinfodata = async () => {
    APIClient.get("sanctum/csrf-cookie");
    APIClient.get("api/callinformation-list", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        const data = res.data.callinformation_list;
        setCallinfodata(data);
        console.log(callinfodata);
      })
      .catch((err) => console.log(err));
  };

  // fetch university list
  const fetchuniversitydata = async () => {
    APIClient.get("sanctum/csrf-cookie");
    APIClient.get("api/univercity-list", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        const data = res.data.univercity_list;
        setUniversityList(data);
      })
      .catch((err) => console.log(err));
  };

  const [initialValues, setInitialValues] = useState({
    profile_id: Id,
    information: "",
    enquiry_status: "",
    lead_potential: "",
    type: "New",
    lead_status: "",
    call_type_id:"",
    univercity: "",
    next_followup_date: "",
    next_followup_time: "",
    added_date: new Date().toISOString().split('T')[0],
    call_note: "",
  });

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: AddCallStatusTabSchema,
      onSubmit: (values, { setSubmitting, resetForm }) => {
        if (isAdmissionDone && values.univercity === "") {
          return;
        } else {
          APIClient.get("sanctum/csrf-cookie")
            .then((response) => {
              return APIClient.post("api/add-lead-callstatus", values, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Accept: "multipart/form-data",
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              });
            })
            .then((addleadcallstatus) => {
              activekey("Journey");
              setRefresh(!refresh)
              setTimeout(() => {
                toast.success(addleadcallstatus.data.msg);
              }, 2000);
            })
            .catch((error) => {
              console.error("Error adding lead Call Status:", error);
              toast.error(error.data.msg);
            })
            .finally(() => {
              setSubmitting(false);
            });
        }
      },
    });
  useEffect(() => {
    if (values.enquiry_status === "Admission done") {
      setIsAdmissionDone(true);
    } else {
      setIsAdmissionDone(false);
    }
  }, [values.enquiry_status]);
  useEffect(() => {
    fetchEnquiryStatus();
    fetchLeadProtential();
    // fetchLeadStatus();
    fetchCallinfodata();
    fetchuniversitydata();
    fetchCallTypedata();
  }, []);

  return (
    <>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Form.Group as={Row} className="mb-3 mt-3">
          <Form.Label
            column
            sm="1"
            className="d-flex align-items-center justify-content-center"
          >
            Call Date
          </Form.Label>
          <Col sm="3">
            <Form.Control
              type="date"
              placeholder="Select Date"
              name="added_date"
              onChange={handleChange}
              value={values.added_date}
              onBlur={handleBlur}
            />
            {errors.added_date && touched.added_date ? (
              <p className="text-danger" style={{ fontSize: "13px" }}>
                {errors.added_date}
              </p>
            ) : null}
          </Col>
          <Form.Label
            column
            sm="1"
            className="d-flex align-items-center justify-content-center"
          >
            Information
          </Form.Label>
          <Col sm="3">
            <Form.Select
              aria-label="Information"
              name="information"
              onChange={handleChange}
              value={values.information}
              onBlur={handleBlur}
            >
              <option>Select</option>
              {callinfodata.map((item, index) => (
                <option key={index} value={item.call_information_name}>
                  {item.call_information_name}
                </option>
              ))}
            </Form.Select>
            {errors.information && touched.information ? (
              <p className="text-danger" style={{ fontSize: "13px" }}>
                {errors.information}
              </p>
            ) : null}
          </Col>
          <Form.Label
            column
            sm="1"
            className="d-flex align-items-center justify-content-center"
          >
            Enquiry Status
          </Form.Label>
          <Col sm="3">
            <Form.Select
              aria-label="Enquiry Status"
              name="enquiry_status"
              onChange={handleChange}
              value={values.enquiry_status}
              onBlur={handleBlur}
            >
              <option>Select</option>
              {enquiryStatus.map((item, index) => (
                <option key={index} value={item.enquery_status_name}>
                  {item.enquery_status_name}
                </option>
              ))}
            </Form.Select>
            {errors.enquiry_status && touched.enquiry_status ? (
              <p className="text-danger" style={{ fontSize: "13px" }}>
                {errors.enquiry_status}
              </p>
            ) : null}
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3 mt-3">
          <Form.Label
            column
            sm="1"
            className="d-flex align-items-center justify-content-center"
          >
            Lead Potential
          </Form.Label>

          <Col sm="3">
            <Form.Select
              aria-label="Lead Potential"
              name="lead_potential"
              onChange={handleChange}
              value={values.lead_potential}
              onBlur={handleBlur}
            >
              <option>None</option>
              {leadProtential.map((item, index) => (
                <option key={index} value={item.lead_potential_name}>
                  {item.lead_potential_name}
                </option>
              ))}
            </Form.Select>
            {errors.lead_potential && touched.lead_potential ? (
              <p className="text-danger" style={{ fontSize: "13px" }}>
                {errors.lead_potential}
              </p>
            ) : null}
          </Col>
          <Form.Label
            column
            sm="1"
            className="d-flex align-items-center justify-content-center"
          >
            Lead Status
          </Form.Label>
          <Col sm="3">
            <Form.Select
              aria-label="Lead Status"
              name="lead_status"
              onChange={handleChange}
              value={values?.lead_status}
              onBlur={handleBlur}
            >
              <option>Select</option>
              {leadStatus && leadStatus.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.lead_status_name}
                </option>
              ))}
            </Form.Select>
            {errors.lead_status && touched.lead_status ? (
              <p className="text-danger" style={{ fontSize: "13px" }}>
                {errors.lead_status}
              </p>
            ) : null}
          </Col>
          <Form.Label
            column
            sm="1"
            className="d-flex align-items-center justify-content-center"
          >
            Call Type
          </Form.Label>
          <Col sm="3">
            <Form.Select
              aria-label="Default select example"
              name="call_type_id"
              onChange={handleChange}
              value={values.call_type_id}
              onBlur={handleBlur}
            >
              <option>Select Call Type</option>
              {calltypedata.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.calltype_name}
                </option>
              ))}
            </Form.Select>
            {errors.call_type_id && touched.call_type_id ? (
              <p className="text-danger" style={{ fontSize: "13px" }}>
                {errors.call_type_id}
              </p>
            ) : null}
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3 mt-3">
          {isAdmissionDone && (
            <>
              <Form.Label
                column
                sm="1"
                className="d-flex align-items-center justify-content-center"
              >
                University
              </Form.Label>
              <Col sm="6">
                <Form.Select
                  name="univercity"
                  onChange={handleChange}
                  value={values.univercity}
                  onBlur={handleBlur}
                >
                  <option value="">Select University</option>
                  {universityList &&
                    universityList.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.univercity_name}
                      </option>
                    ))}
                </Form.Select>

                {isAdmissionDone &&
                (values.univercity === "" || values.univercity === null) ? (
                  <p className="text-danger" style={{ fontSize: "13px" }}>
                    Please Enter univercity Name
                  </p>
                ) : null}
              </Col>
            </>
          )}

          <Form.Label
            column
            sm="1"
            className="d-flex align-items-center justify-content-center"
          >
            Next Follow up
          </Form.Label>
          <Col sm="2">
            <Form.Control
              type="date"
              placeholder="Select Date"
              name="next_followup_date"
              onChange={handleChange}
              value={values.next_followup_date}
              onBlur={handleBlur}
            />

            {errors.next_followup_date && touched.next_followup_date ? (
              <p className="text-danger" style={{ fontSize: "13px" }}>
                {errors.next_followup_date}
              </p>
            ) : null}
          </Col>
          <Col sm="2">
            <Form.Control
              type="time"
              placeholder="Select Time"
              name="next_followup_time"
              onChange={handleChange}
              value={values.next_followup_time}
              onBlur={handleBlur}
            />

            {errors.next_followup_time && touched.next_followup_time ? (
              <p className="text-danger" style={{ fontSize: "13px" }}>
                {errors.next_followup_time}
              </p>
            ) : null}
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3 mt-3">
          <Form.Label
            column
            sm="1"
            className="d-flex align-items-center justify-content-start"
          >
            Call Note
          </Form.Label>
          <Col sm="6">
            <Form.Control
              as="textarea"
              placeholder="Enter Note (Optional)"
              onChange={handleChange}
              name="call_note"
              value={values.call_note}
            />
          </Col>
        </Form.Group>

        <Button className="common-btn mt-2" variant="primary" type="submit">
          Save
        </Button>
      </Form>
    </>
  );
}

export default CallstatusCounsellor;
