import React, { useState, useEffect } from "react";
import PageLayout from "../../layouts/PageLayout";
import Form from "react-bootstrap/Form";
import APIClient from "../../APIClient";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const Permission = () => {
  const [roleListData, setRoleListData] = useState([]);
  const [roleWiseData, setRoleWiseData] = useState();
  const [roleWiseMenuItem, setRoleWiseMenuItem] = useState([]);
  const [permissions, setPermissions] = useState({});
  const navigate = useNavigate();

  const fetchRoleListData = () => {
    APIClient.get("sanctum/csrf-cookie").then(() => {
      APIClient.get("api/role-list", {
        headers: {
          "Authorization": "Bearer " + localStorage.getItem('token')
        },
      }).then((response) => {
        setRoleListData(response.data.role_list || []);
      });
    });
  };

  const handleRoleChange = (event) => {
    const roleId = event.target.value;
    setRoleWiseData(roleId);

    APIClient.get("sanctum/csrf-cookie").then(() => {
      APIClient.post("api/role-wise-menu", { role_id: roleId }, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Accept": "multipart/form-data",
          "Authorization": "Bearer " + localStorage.getItem('token')
        },
      }).then((response) => {
        const menuItems = response.data.menu_list || [];
        setRoleWiseMenuItem(menuItems);

        const initialPermissions = {};
        menuItems.forEach((item) => {
          initialPermissions[item.menu_id] = {
            menu_id:item.menu_id,
            add_permission: item.add_permission === 1,
            edit_permission: item.edit_permission === 1,
            list_permission: item.list_permission === 1,
            delete_permission: item.delete_permission === 1,
          };
        });
        setPermissions(initialPermissions);
      }).catch((error) => {
        console.error("Failed to fetch role-wise menu items:", error);
        setRoleWiseMenuItem([]);
      });
    });
  };

  useEffect(() => {
    fetchRoleListData();
  }, []);

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/login');
    }
  }, [navigate]);

  const handlePermissionChange = (menuId, permissionType) => (event) => {
    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      [menuId]: {
        ...prevPermissions[menuId],
        [permissionType]: event.target.checked,
      },
    }));
  };

  const handlePermissionSave = (event) => {
    event.preventDefault();

    const formattedPermissions = {
      role_id: roleWiseData,
      menu_arr: Object.keys(permissions).map((menuId) => ({
        menu_id: parseInt(menuId),
        permission_arr: [{
          add_permission: permissions[menuId].add_permission ? 1 : 0,
          edit_permission: permissions[menuId].edit_permission ? 1 : 0,
          list_permission: permissions[menuId].list_permission ? 1 : 0,
          delete_permission: permissions[menuId].delete_permission ? 1 : 0,
        }]
      }))
    };
    // return false;
    APIClient.post("https://arjunglasshouse.com/ksmapi/api/update-role-permission", formattedPermissions, {
      headers: {
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    }).then(() => {
      toast.success("Permissions updated successfully");
    }).catch((error) => {
      toast.error("Failed to update permissions");
    });
  };

  return (
    <>
      <ToastContainer autoClose={1000} />
      <PageLayout>
        <div className="main-div" style={{ margin: '10px', background: 'white', padding: '30px', borderRadius: '12px' }}>
          <div className="hero-box">
            <div className="main-container">
              <div className="sub-main-container">
                <h3 className="mb-4">Take Permission</h3>
                <div className="select-role mb-5">
                  <label className="col-lg-4" htmlFor="role" style={{ fontSize: "17px", fontWeight: "600" }}>
                    Select Your Role:
                  </label>
                  <select
                    onChange={handleRoleChange}
                    value={roleWiseData || "none"}
                    className="col-lg-4"
                    name="role"
                    id="role"
                  >
                    <option value="none" disabled>{"<--------- Select --------->"}</option>
                    {roleListData.map((item,index) => (
                      <option key={index} value={item.id}>{item.role_name}</option>
                    ))}
                  </select>
                </div>

                <hr />
                <h5 style={{ textAlign: "center" }}>Permission</h5>
                <hr />
                <form onSubmit={handlePermissionSave}>
                  <div className="row">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-1 main-title" id="mc-title">View</div>
                    <div className="col-lg-1 main-title" id="mc-title">Create</div>
                    <div className="col-lg-1 main-title" id="mc-title">Edit</div>
                    <div className="col-lg-1 main-title" id="mc-title">Delete</div>
                  </div>
                  {roleWiseMenuItem.map((item,index) => (
                    
                    <div key={index} className="row mt-1">
                      
                      <div className="col-lg-2 main-title">{item.menu_title}</div>
                      <div className="col-lg-1">
                        <Form.Check
                          type="switch"
                          id={`view-${item.menu_id}`}
                          checked={permissions[item.menu_id]?.list_permission ? true : false}
                          onChange={handlePermissionChange(item.menu_id, 'list_permission')}
                        />
                      </div>
                      <div className="col-lg-1">
                        <Form.Check
                          type="switch"
                          id={`create-${item.menu_id}`}
                          checked={permissions[item.menu_id]?.add_permission || false}
                          onChange={handlePermissionChange(item.menu_id, 'add_permission')}
                        />
                      </div>
                      <div className="col-lg-1">
                        <Form.Check
                          type="switch"
                          id={`edit-${item.menu_id}`}
                          checked={permissions[item.menu_id]?.edit_permission || false}
                          onChange={handlePermissionChange(item.menu_id, 'edit_permission')}
                        />
                      </div>
                      <div className="col-lg-1">
                        <Form.Check
                          type="switch"
                          id={`delete-${item.menu_id}`}
                          checked={permissions[item.menu_id]?.delete_permission || false}
                          onChange={handlePermissionChange(item.menu_id, 'delete_permission')}
                        />
                      </div>
                    </div>
                  ))}

                  <div className="per-btn mt-5">
                    <button className="btn btn-primary common-btn w-25 py-3" type="submit">Save Permission</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </PageLayout>
    </>
  );
};

export default Permission;
